<template>
    <div class="content-wrapper pe-3 ps-3">
        <rq-banner
            variant="error"
            :message="errorMessage"
            icon="fas fa-exclamation-triangle"
            :visible="errorMessage.length > 0"
        />
        <rq-list-selection-layout
            automation-id-append="consolidated-checks"
            :actions="detailActions"
            @action="onCheckDetailTransferAction">
            <template #left-section>
                <consolidated-check-selection-grid
                    ref="unassignedGridComponent"
                    automation_id="tbl_unassigned_checks"
                    title="Unassigned Checks"
                    :grid-data="checks"
                    popover-placement="righttop"
                    @row-double-click="onUnassignedDoubleClick"
                    @selectionChanged="onUnassignedSelectionChanged"
                />
            </template>
            <template #right-section>
                <consolidated-check-selection-grid
                    ref="assignedGridComponent"
                    automation_id="tbl_assigned_checks"
                    title="Assigned Checks"
                    :grid-data="selectedChecks"
                    popover-placement="righttop"
                    @row-double-click="onAssignedDoubleClick"
                    @selectionChanged="onAssignedSelectionChanged"
                />
            </template>
        </rq-list-selection-layout>
    </div>
</template>
<script>
    import { mapGetters } from "vuex";
    import ConsolidatedCheckSelectionGrid from "./ConsolidatedCheckSelectionGrid";

    export default {
        name: "ConsolidatedCheckAssignDialog",
        props: {
            assignedChecks: {type: Array, required: true},
            unassignedChecks: {type: Array, required: true},
            check: {type: Object, required: true},
        },
        components: { ConsolidatedCheckSelectionGrid },
        data() {
            const self = this;
            return {
                checks: _.clone(self.unassignedChecks),
                selectedCheck: _.clone(self.check),
                selectedChecks: _.clone(self.assignedChecks),
                originalChecks: _.clone(self.assignedChecks),
                unassignedSelectedKeys: [],
                assignedSelectedKeys: [],
                errorMessage: ""
            }
        },

        computed: {
            ...mapGetters([ "lookupHelpers", "lookupItems" ]),
            escrowAccounts() { return this.lookupHelpers.getLookupItems(this.lookupItems.ESCROW_ACCOUNTS); },
            detailActions() {
                return [
                    { name: "transfer-all-right", tooltip: () => "Assign All", disabled: () => _.isEmpty(this.unassignedChecks) },
                    { name: "transfer-right", tooltip: () => "Assign Selected", disabled: () => _.isEmpty(this.unassignedSelectedKeys) },
                    { name: "transfer-left", tooltip: () => "Unassign Selected", disabled: () => _.isEmpty(this.assignedSelectedKeys) },
                    { name: "transfer-all-left", tooltip: () => "Unassign All", disabled: () => _.isEmpty(this.assignedChecks) },
                    { name: "move-up", disabled: () => false },
                    { name: "move-down", disabled: () => false }
                ];
            },
        },
        methods: {

            assignChecks(keys) {
                this.updateCheckAssignment(this.checks, this.selectedChecks, keys, 1);
            },

            unassignChecks(keys) {
                this.updateCheckAssignment(this.selectedChecks, this.checks, keys, 0);
            },

            updateCheckAssignment(fromList, toList, keys, createSourceVal) {
                if(_.isEmpty(keys)) return;
                let transferItems = [];
                _.forEach(keys, checksID => {
                    let idx = _.findIndex(fromList, { checksID });
                    if(idx < 0) return;
                    let removed = fromList.splice(idx, 1);
                    transferItems.push(...removed);
                });
                _.updateAll(transferItems, "createSource", createSourceVal);
                toList.push(...transferItems);
                this.clearDetailSelection();
                this.refreshDetail();
            },

            saveSelectedCheckAssignments() {
                const self = this;

                let originalAssignedKeys = _.map(self.originalChecks, "checksID");
                let assignedKeys = _.map(self.selectedChecks, "checksID");
                if(!_.differs(originalAssignedKeys, assignedKeys)) return Promise.resolve(true);
                self.selectedCheck.checks = self.selectedChecks;
                let apiPromise = self.$api.ConsolidatedChecksApi.updateAssignedChecks(self.selectedCheck.toDataObject());
                return self.$rqBusy.wait(apiPromise);
            },

            onUnassignedSelectionChanged(e) {
                this.unassignedSelectedKeys = e.selectedRowKeys.slice();
            },

            onAssignedSelectionChanged(e) {
                this.assignedSelectedKeys = e.selectedRowKeys.slice();
            },

            onCheckDetailTransferAction(e) {
                switch(e.name) {
                    case "transfer-all-right":
                        this.assignChecks(_.map(this.checks, "checksID"));
                        break;
                    case "transfer-right":
                        this.assignChecks(this.unassignedSelectedKeys);
                        break;
                    case "transfer-left":
                        this.unassignChecks(this.assignedSelectedKeys);
                        break;
                    case "transfer-all-left":
                        this.unassignChecks(_.map(this.selectedChecks, "checksID"));
                        break;
                }
            },

            onUnassignedDoubleClick(e){ this.assignChecks([e.key]); },

            onAssignedDoubleClick(e){ this.unassignChecks([e.key]); },

            clearDetailSelection() {
                this.invokeChildComponentMethod("unassignedGridComponent", "clearSelection");
                this.invokeChildComponentMethod("assignedGridComponent", "clearSelection");
            },

            refreshDetail() {
                this.invokeChildComponentMethod("unassignedGridComponent", "refresh");
                this.invokeChildComponentMethod("assignedGridComponent", "refresh");
            },

            invokeChildComponentMethod(componentRef, method, ...params) {
                _.invoke(this, `$refs.${componentRef}.${method}`, ...params);
            },
        }
    }
</script>
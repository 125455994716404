<template>
    <div class="content-wrapper">
        <rq-scroll-container v-if="useScrollLayout" ref="rqScrollContainer">
            <router-view></router-view>
        </rq-scroll-container>
        <router-view v-else></router-view>
    </div>
</template>
<script>
    export default {
        name: "EscrowAccountingMain",

        computed: {
            scrollContainer() { return this.$refs.rqScrollContainer; },
            useScrollLayout() { return _.getBool(this, "$route.meta.scrollLayout"); },
        },
    }
</script>
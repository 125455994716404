<template>
    <div class="content-wrapper" id="e-remit-files-grid">
        <rqdx-action-data-grid
            ref="dataGrid"
            automation_id="e-remit-grid"
            :actions="selectionActions"
            :config="gridConfig"
            class="rq-tab-content-grid"
            :data-source="gridDataSource"
            @action="onGridAction"
            hide-show-column-chooser
            integrated-search
            :hide-search="items.length == 0"
            :read-only="readOnly"
            rq-filters
            force-floating-header>
        </rqdx-action-data-grid>
        <CmERemitDetailPopover
            container="#e-remit-files-grid"
            :popover="gridPopover"
            v-model:visible="gridPopover.visible"
        />
    </div>
</template>

<script>
    import { nextTick } from "vue";
    import DxGridUtils from "@/shared/utilities/DxGridUtils";
    import DateTimeHelper from "@/shared/utilities/DateTimeHelper";
    import CmERemitDetailPopover from "@/shared/components/rq/CmERemitDetailPopover";

    export default {
        name: "CmERemitFilesGrid",
        components: { CmERemitDetailPopover },
        props: {
            items: {type: Array, required: true},
            relationshipKey: {type: String, required: true}
        },
        data () {
            return {
                actions: [],
                gridPopover: {
                    visible: false,
                    target: null,
                    item: [],
                    title: null
                },
            };
        },

        watch: {
            items:{
                handler: function(newValue, oldValue) {
                    if(!_.isEqual(newValue, oldValue)) {
                        this.refresh();
                    }
                },
                deep: true,
                immediate: false
            },
        },

        created() {
            this.initNonReactiveVariables();
            this.initGridConfig();
        },

        computed: {
            gridInstance() { return _.get(this, "$refs.dataGrid.gridInstance", null); },
            localSecurity(){
                return this.securitySettings.findValues([
                    "IsAdmin",
                    "IsEscrowAdmin",
                    ]);
            },
            readOnly() { return false; },
        },

        methods: {
            clear() {
                if(!this.gridInstance) return;
                this.gridInstance.option("focusedRowIndex", -1);
                this.gridInstance.clearSelection();
            },

            async getOrderID(gfNo) {
                const self = this;
                let order = await self.$api.OrdersApi.getOrderEntity(gfNo);
                return _.getNumber(order, "ordersID", 0) || 0;
            },

            initNonReactiveVariables() {
                const self = this;
                self.itemTypeName = "File";
                self.itemTypeNamePlural = "Files";
                self.itemKey = "gfNo";
                self.selectionActions = [
                    { name: "submit", text: "Submit", allowMultiSelection: true, requireSelection: true, tooltip: `eRemit Selected Files(s).`, disabled: function(e) { return this.readOnly;} },
                    { name: "goto-file", text: "Go To File", requireSelection: true, tooltip: `Go to File Main.`, disabled: function(e) { return this.readOnly;} },
                    { name: "goto-policy", text: "Go To Policy", requireSelection: true, tooltip: `Go to Policy List.`, disabled: function(e) { return this.readOnly;} },
                ];
            },

            initGridConfig(){
                const self = this;
                self.gridConfig = {
                    columnHidingEnabled: false,
                    focusedRowEnabled: false,
                    wordWrapEnabled: true,
                    paging: { enabled: true },
                    pager: { showPageSizeSelector: true, allowedPageSizes: [50,100,500], showInfo: true},
                    remoteOperations: { sorting: false, paging: false },
                    height: "100%",
                    columns: [
                        {
                            dataField: self.itemKey,
                            dataType: "string",
                            caption: "File Number",
                        },
                        {
                            caption: "Policy Number",
                            dataType: "string",
                            calculateCellValue: function(data) {
                                if (_.size(data.policies) == 0) return "";
                                return _.join(_.map(data.policies, "policyNumber"), ",");
                            },
                            // cellTemplate: DxGridUtils.infoIconCellTemplate({
                            //     handlers:{
                            //         click(cellElement, cellInfo, e) {
                            //             self.updateGridPopover(cellInfo.data, e.target.id);
                            //             e.stopPropagation();
                            //         },
                            //     }
                            // })
                            cellTemplate: function(cellElement, cellInfo) {
                                let displayName = _.replaceAll(cellInfo.value, ",", "<br/>");
                                $("<div />")
                                    .append(displayName)
                                    .appendTo(cellElement);
                            },
                        },
                        {
                            caption: "Policy Date",
                            dataType: "string",
                            calculateCellValue: function(data) {
                                if (_.size(data.policies) == 0) return "";
                                let dates = _.map(data.policies, d => { return DateTimeHelper.toFormat(d.policyDate); });
                                return _.join(dates, ",");
                            },
                            cellTemplate: function(cellElement, cellInfo) {
                                let displayName = _.replaceAll(cellInfo.value, ",", "<br/>");
                                $("<div />")
                                    .append(displayName)
                                    .appendTo(cellElement);
                            },
                        },
                        {
                            caption: "Report Date",
                            dataType: "string",
                            calculateCellValue: function(data) {
                                if (_.size(data.policies) == 0) return "";
                                let dates = _.map(data.policies, d => { return DateTimeHelper.toFormat(d.reportDate); });
                                return _.join(dates, ",");
                            },
                            cellTemplate: function(cellElement, cellInfo) {
                                let displayName = _.replaceAll(cellInfo.value, ",", "<br/>");
                                $("<div />")
                                    .append(displayName)
                                    .appendTo(cellElement);
                            },
                        },
                        {
                            dataField: "grossAmount",
                            dataType: "number",
                            caption: "Gross Premium",
                            format: {
                                type: "currency",
                                precision: 2
                            },
                        },
                        {
                            dataField: "grossSplit",
                            dataType: "number",
                            caption: "Gross Split",
                            format: {
                                type: "currency",
                                precision: 2
                            },
                        },
                    ],
                    // masterDetail: {
                    //     autoExpandAll: false,
                    //     enabled: true,
                    //     template(container, options) {
                    //         $("<div>").dxDataGrid({
                    //             columns: [
                    //                 {
                    //                     dataField: "policyType",
                    //                     dataType: "string",
                    //                 },
                    //                 {
                    //                     dataField: "policyNumber",
                    //                     dataType: "string",
                    //                 },
                    //                 DxGridUtils.dateColumn({
                    //                     dataField: "policyDate",
                    //                 }),
                    //                 DxGridUtils.dateColumn({
                    //                     dataField: "reportDate",
                    //                 }),
                    //             ],
                    //             showColumnHeaders: true,
                    //             dataSource: new DataSource({
                    //                 store: new ArrayStore({ data: options.data.policies, key: "policyNumber" })
                    //             })
                    //         }).appendTo(container);
                    //     }
                    // },
                    summary: {
                        totalItems: [
                            {
                                name: "AmountTotal",
                                column: "grossAmount",
                                alignment: "right",
                                valueFormat: {
                                    type: "currency",
                                    precision: 2
                                },
                                displayFormat: "{0}",
                                summaryType: "sum"
                            },
                            {
                                name: "SplitTotal",
                                column: "grossSplit",
                                alignment: "right",
                                valueFormat: {
                                    type: "currency",
                                    precision: 2
                                },
                                displayFormat: "{0}",
                                summaryType: "sum"
                            },
                            {
                                name: "AmountTotalLabel",
                                column: self.itemKey,
                                alignment: "left",
                                displayFormat: "TOTAL",
                                cssClass: "rq-summary-label",
                                summaryType: "sum"
                            },
                        ],
                    },
                };

                self.gridDataSource = {
                    key: self.itemKey,
                    load (loadOptions) {
                        return Promise.resolve(self.items);
                    },
                };
            },

            onGridAction(e) {
                if(!e || !e.data || this.readOnly) return;
                switch(e.action.name) {
                    case "goto-file":
                        this.gotoRoute(e.data.gfNo, "oe:main");
                        break;
                    case "goto-policy":
                        this.gotoRoute(e.data.gfNo, "policy:list");
                        break;
                    case "submit":
                        this.submitFiles(e.data);
                        break;
                    default: {
                        break;
                    }
                }
            },

            async gotoRoute(gfNo, route) {
                let orderId = await this.getOrderID(gfNo);
                if (orderId > 0) {
                    this.$router.push({ name: route, params: { orderId } });
                } else {
                    this.clear();
                    this.$toast.error({ message: `Error finding Order ID from File#.` });
                }
            },

            refresh() {
                if(!this.gridInstance) return;
                this.clear();
                this.gridInstance.refresh();
            },

            submitFiles(items) {
                const self = this;
                self.errorMessage = "";
                const fileList = _.map(items, "gfNo");
                let apiPromise = self.$api.CmERemitApi.submitFiles(self.relationshipKey, fileList);
                self.$rqBusy.wait(apiPromise)
                    .then(result => {
                        self.$emit("refresh");
                        self.refresh();
                        self.$toast.success({ message: `Successfully submitted ${_.size(fileList)} File${_.size(fileList) > 1 ? "s" : ""}.` });
                    })
                    .catch(error => {
                        console.error(error);
                        self.$toast.error({ message: `Error submitting Files.` });
                    });
            },

            async updateGridPopover(item, target=null) {
                const self = this;

                let newID = target;
                let lastID = _.get(self.gridPopover, "target") || null;
                let isNewItem = !_.isEqual(newID, lastID);
                let gridPopover = _.clone(self.gridPopover);
                if (isNewItem) {
                    if (!_.isNil(lastID)) {
                        gridPopover.visible = false;
                    }
                    gridPopover.target = newID;
                    gridPopover.item = item;
                    gridPopover.title = `${item.gfNo} Details`;
                    gridPopover.visible = true;
                } else {
                    gridPopover.visible = !gridPopover.visible;
                }

                await nextTick();

                self.gridPopover = gridPopover;
            },
        }
    }
</script>
<template>
    <div class="content-wrapper escrow-browser">
        <rq-banner
            variant="error"
            icon="fas fa-exclamation-triangle"
            :message="errorMessage"
            :visible="errorMessage.length > 0"
            @dismiss="errorMessage=''"
            dismissable
        />
        <rq-page-section title="Search Deposits" headerSize="lg" class="browser-filter-section" v-model:expanded="filtersExpanded" @keyup.enter="onSearch" collapsible>
            <template #header-actions>
                <transition name="simple-fade">
                    <ul v-if="!filtersExpanded" class="nav browser-filter-display">
                        <li class="nav-item" v-if="bankFilterDisplay != 'All'">
                            <div class="filter-name">Escrow Account:</div>
                            <div class="filter-value">{{bankFilterDisplay}}</div>
                        </li>
                        <li class="nav-item" v-if="hasActiveFilter">
                            <b-btn
                                variant="link"
                                class="btn-theme"
                                @click="onClearSearch">Clear All Filters
                            </b-btn>
                        </li>
                    </ul>
                </transition>
                <transition name="simple-fade">
                    <ul v-if="filtersExpanded" class="nav ms-auto">
                        <li class="nav-item">
                            <b-btn
                                automation_id="btn_search_clear"
                                variant="theme"
                                @click="onClearSearch">Clear</b-btn>
                        </li>
                        <li class="nav-item">
                            <b-btn
                                automation_id="btn_search_checks"
                                variant="theme"
                                :disabled="errorMessage.length > 0"
                                @click="onSearch">Search</b-btn>
                        </li>
                    </ul>
                </transition>
            </template>
            <div class="row">
                <div class="col col-4 form-group">
                    <label for="dtp_escrow_bank">Escrow Account</label>
                    <dx-select-box
                        :input-attr="{ automation_id: 'dtp_escrow_bank', id: 'dtp_escrow_bank' }"
                        :items="escrowBanks"
                        value-expr="id"
                        display-expr="name"
                        v-model="request.bankCompanyID"
                        placeholder="All Escrow Accounts..."
                        :search-enabled="true"
                        :show-clear-button="true"
                    />
                </div>
            </div>
        </rq-page-section>
        <rqdx-action-data-grid
            ref="dataGrid"
            automation_id="tbl_unverified_deposits"
            :actions="selectionActions"
            :config="gridConfig"
            class="grid-container"
            title="Deposits"
            title-size="sm"
            :data-source="gridDataSource"
            @verify="onVerifyItem"
            @rowDoubleClick="onVerifyItem"
            export-file-name="unverified_deposits_data"
            integrated-search
            rq-filters>
        </rqdx-action-data-grid>
    </div>
</template>

<script>
    import { mapState, mapGetters } from "vuex";
    import { UnverifiedDepositDto, EscrowDepositSearchRequest }  from "../models";
    import { UserSecuritySetting } from "@/shared/models/models";
    import DxGridUtils from "@/shared/utilities/DxGridUtils";

    export default {
        name:"UnverifiedDepositsList",
        data () {
            return {
                request: new EscrowDepositSearchRequest(),
                activeRequest: new EscrowDepositSearchRequest(),
                selectedBankCompanyId: null,
                activeBankCompanyId: null,
                items: [],
                errorMessage: "",
                searchText: "",
                selectionActions: [],
                filtersExpanded: true
            };
        },

        created() {
            this.initNonReactiveVariables();
            this.initGridConfig();
        },

        computed: {
            ...mapGetters([
                "lookupHelpers",
                "lookupItems"
            ]),
            ...mapState({
                user: state => state.authentication.session.user
            }),
            bankFilterDisplay(){
                return _.isNil(this.activeBankCompanyId) ? "All" : this.lookupHelpers.getLookupItemName(this.lookupItems.ESCROW_ACCOUNTS, this.activeBankCompanyId);
            },
            gridInstance() { return _.get(this, "$refs.dataGrid.gridInstance", null); },
            hasFilter(){
                return !_.isNil(this.selectedBankCompanyId);
            },
            hasActiveFilter(){
                return !_.isNil(this.activeBankCompanyId);
            },
            userBankCompanyID(){
                let branchID = _.getNumber(this.lookupHelpers.getBranch(this.user.branchID), "bankCompanyID", 0);
                return branchID == 0 ? null : branchID;
            },
            readOnly() { return !this.getSecuritySetting('IsEscrowAdmin', 'permissions'); },
            
        },

        methods: {
            clear() {
                if(!this.gridInstance) return;
                this.gridInstance.option("focusedRowIndex", -1);
                this.gridInstance.clearSelection();
            },

            fetchData() {
                const self = this;
                let apiPromise = self.$api.EscrowAccountingApi.getUnverifiedDeposits();
                self.$rqBusy.wait(apiPromise)
                    .then(result => {
                        self.items = _.map(result, i => new UnverifiedDepositDto(i));
                        self.activeBankCompanyId = self.selectedBankCompanyId;
                    })
                    .catch(error => {
                        console.error(error);
                        self.$toast.error({ message: `Error loading Unverified Deposits.` });
                    })
                    .finally(() => {
                        self.refresh();
                    });
            },

            getSecuritySetting(name, type) {
                let perm = _.find(_.get(this.securitySettings, type), {name}) || new UserSecuritySetting();
                return perm.value;
            },

            initNonReactiveVariables() {
                const self = this;
                self.escrowBanks = self.lookupHelpers.getLookupItems(self.lookupItems.USER_ESCROW_ACCOUNTS);
                self.request.bankCompanyID = self.activeRequest.bankCompanyID = self.userBankCompanyID;
                self.selectionActions = [
                    {
                        name: "verify",
                        text: "Verify",
                        eventName: "verify",
                        allowMultiSelection: true,
                        requireSelection: true,
                        tooltip: "Verify Deposit(s)",
                        disabled: function(e) {
                            return (self.readOnly) ? "Access Restricted" : false;
                        }
                    },
                ];
            },

            initGridConfig(){
                const self = this;
                self.gridConfig = {
                    focusedRowEnabled: false,
                    paging: { enabled: true },
                    pager: { showPageSizeSelector: true, allowedPageSizes: [50,100,500], showInfo: true},
                    remoteOperations: { sorting: false, paging: false },
                    height: "100%",
                    columns: [
                        {
                            dataField: "gfNo",
                            dataType: "string",
                            caption: "File #",
                        },
                        DxGridUtils.dateColumn({
                            dataField: "depositDate",
                        }),
                        {
                            dataField: "transnumber",
                            dataType: "string",
                            caption: "Trans Num",
                        },
                        {
                            dataField: "description",
                            dataType: "string",
                            caption: "Description",
                        },
                        {
                            dataField: "amount",
                            dataType: "number",
                            format: {
                                type: "currency",
                                precision: 2
                            },
                        },
                        {
                            dataField: "typeFundDisplay",
                            dataType: "string",
                            caption: "Type of Funds",
                        },
                        {
                            dataField: "bankCompanyID",
                            dataType: "number",
                            caption: "Escrow Account", sortIndex: 0, sortOrder: "asc",
                            lookup: {
                                dataSource: self.escrowBanks,
                                valueExpr: "id",
                                displayExpr: "name"
                            },
                            rqFilter: {
                                disabled: true,
                                disabledTooltip: "Use fields at the top of the page to filter this column."
                            }
                        },
                        {
                            dataField: "branchDisplay",
                            dataType: "string",
                            caption: "Branch",
                        },
                    ],
                };

                self.gridDataSource = {
                    key: "clientID",
                    load (loadOptions) {
                        let items = _.isNil(self.selectedBankCompanyId) ? _.clone(self.items) : _.filter(_.clone(self.items), ["bankCompanyID", self.selectedBankCompanyId]);
                        return Promise.resolve(items);
                    },
                };
            },

            onClearSearch() {
                this.filtersExpanded = true;
                this.request = new EscrowDepositSearchRequest();
                this.activeRequest = new EscrowDepositSearchRequest();
                this.items = [];
                this.refresh();
            },

            onSearch() {
                // if(!this.hasFilter) {
                //     this.onClearSearch();
                //     return;
                // }
                this.fetchData();
            },

            onVerifyItem(e) {
                if(!e || !e.data || this.readOnly) return;
                const self = this;
                self.errorMessage = "";
                let items = e.data;
                let ok = function (args) {
                    let ids = _.map(items, "clientID");
                    self.verifyDeposits(ids)
                        .then(result => {
                            self.items = _.map(result, i => new UnverifiedDepositDto(i));
                            return true;
                        })
                        .catch(error => {
                            self.errorMessage = error.errorMessage;
                            return true;
                        })
                        .finally(() => {
                            self.refresh();
                        });
                }

                self.$dialog.confirm("Confirm Verify", `Are you sure you want to verify the selected Deposit${items.length > 1 ? "s" : ""}?`, ok, null, { cancelTitle: 'No', okTitle: 'Yes'});
            },

            refresh() {
                if(!this.gridInstance) return;
                this.clear();
                this.gridInstance.refresh();
            },

            verifyDeposits(ids) {
                const self = this;
                let apiPromise = self.$api.EscrowAccountingApi.verifyDeposits(ids);
                return self.$rqBusy.wait(apiPromise)
            },
        }
    }
</script>
<template>
    <div class="content-wrapper">
        <div class="row ps-3 pe-3">
            <div :class="{ 'col col-6 form-group form-required':true, 'has-error':v$.checkNumberFrom.$error }">
                <label for="drp_check_number_from">Beginning Check</label>
                <rqSelectBox
                    automation_id="drp_check_number_from"
                    :items="items"
                    :defaultItemEnabled="true"
                    v-model="v$.checkNumberFrom.$model"
                    />
                    <rq-validation-feedback
                        :messages="{ 'Beginning Check required': v$.checkNumberFrom.$error }"
                    />
            </div>
        </div>
        <div class="row ps-3 pe-3">
            <div :class="{ 'col col-6 form-group form-required':true, 'has-error':v$.checkNumberTo.$error }">
                <label for="drp_check_number_to">Ending Check</label>
                <rqSelectBox
                    automation_id="drp_check_number_to"
                    :items="endingItems"
                    :disabled="disableEndingItems"
                    :defaultItemEnabled="true"
                    v-model="v$.checkNumberTo.$model"
                    />
                    <rq-validation-feedback
                        :messages="{ 'Ending Check required': v$.checkNumberTo.$error }"
                    />
            </div>
        </div>
    </div>
</template>

<script>
    import { required } from '@vuelidate/validators';
    import { useVuelidate } from "@vuelidate/core";

    export default {
        name: 'ReconciliationSelectCheckRange',
        props: {
            items: { type: Array, required: true, default: () => [] },
        },

        setup: () => ({ v$: useVuelidate() }),

        data() {
            return {
                checkNumberFrom: null,
                checkNumberTo: null,
            }
        },
        watch: {
            "v$.$error":{
                handler: function(newValue, oldValue) {
                    this.$emit(`${newValue ? "disable" : "enable"}-ok`);
                },
                deep: true,
                immediate: true
            },
        },
        computed: {
            disableEndingItems() { return _.isNil(this.checkNumberFrom); },
            tooltipContainer() { return `#${this.dialogId}`; },
            endingItems() {
                if (_.isNil(this.checkNumberFrom)) {
                    return this.items;
                }
                if (this.checkNumberFrom > 0) {
                    return _.filter(_.clone(this.items), i => i > this.checkNumberFrom);
                }
                return [];
            },
        },
        validations: () =>  ({
            checkNumberFrom: { required },
            checkNumberTo: { required }
        }),
        methods:{
            isValid() {
                this.v$.$touch();
                this.showBanner = true;
                return !this.v$.$error;
            }
        }
    }
</script>
<style lang="scss">
</style>
<template>
    <div class="content-wrapper">
        <rqdx-action-data-grid
            ref="dataGrid"
            automation_id="tbl_escrow_memo_activity"
            :actions="selectionActions"
            :config="gridConfig"
            class="rq-tab-content-grid"
            :data-source="gridDataSource"
            @navigate="onGotoFile"
            export-file-name="escrow_memo_activity_data"
            :strikethrough-if-true="['inactive']"
            hide-show-column-chooser
            integrated-search
            :hide-search="items.length == 0"
            :read-only="readOnly"
            rq-filters
            force-floating-header>
            <template #toolbar>
                <ul class="nav ms-auto me-3">
                    <li :class="{ 'nav-item': true }">
                         <rq-report-button
                            text="View Report"
                            :disabled="disabledPrint"
                            :path="reportOptions.path"
                            :name="reportOptions.title"
                            :report-options="reportOptions"
                        />
                    </li>
                </ul>
            </template>
        </rqdx-action-data-grid>
    </div>
</template>

<script>
    import { mapState, mapGetters } from "vuex";
    import { EscrowMemoActivityDto }  from "../models";
    import { CheckActions } from '../enums';
    import DxGridUtils from "@/shared/utilities/DxGridUtils";

    export default {
        name: "EscrowMemoActivityGrid",
        components: {  },
        props: {
            items: {type: Array, required: true},
            reportOptions: { type: Object, default: null},
            printOptionDisabled: {type: Boolean, default: true}
        },
        data () {
            return {
                actions: [],
            };
        },

        watch: {
            items:{
                handler: function(newValue, oldValue) {
                    if(!_.isEqual(newValue, oldValue)) {
                        this.refresh();
                    }
                },
                deep: true,
                immediate: false
            },
        },

        created() {
            this.initNonReactiveVariables();
            this.initGridConfig();
        },

        computed: {
            ...mapGetters([
                "lookupHelpers",
                "lookupItems"
            ]),
            gridInstance() { return _.get(this, "$refs.dataGrid.gridInstance", null); },
            localSecurity(){
                return this.securitySettings.findValues([
                    "IsAdmin",
                    "IsEscrowAdmin",
                    "CanEnterEditAccountingEscrowMemo",
                    "CheckWriting_ScreenAccess"
                    ]);
            },
            readOnly() { return (!this.localSecurity.IsAdmin && !this.localSecurity.IsEscrowAdmin) || !this.localSecurity.CheckWriting_ScreenAccess; },
            disabledPrint () { return this.printOptionDisabled; } ,
        },

        methods: {
            clear() {
                if(!this.gridInstance) return;
                this.gridInstance.option("focusedRowIndex", -1);
                this.gridInstance.clearSelection();
            },

            initNonReactiveVariables() {
                const self = this;
                self.itemTypeName = "Escrow Memo";
                self.itemTypeNamePlural = "Escrow Memos";
                self.itemKey = "checkSessionDetailID";
                self.escrowBanks = self.lookupHelpers.getAllLookupItems(self.lookupItems.ESCROW_ACCOUNTS);
                self.actions = CheckActions.lookupItems;
                self.selectionActions = [
                    { name: "goto", text: "Go To File", eventName: "navigate", requireSelection: true, tooltip: `Go to Check Writing for this File.`, disabled: function(e) { return this.readOnly;} },
                ];
            },

            initGridConfig(){
                const self = this;
                self.gridConfig = {
                    columnHidingEnabled: false,
                    focusedRowEnabled: false,
                    paging: { enabled: true },
                    pager: { showPageSizeSelector: true, allowedPageSizes: [50,100,500], showInfo: true},
                    remoteOperations: { sorting: false, paging: false },
                    height: "100%",
                    columns: [
                        {
                            dataField: self.itemKey,
                            visible: false,
                            allowSearch: false,
                            showInColumnChooser: false
                        },
                        {
                            dataField: "sessionDate",
                            dataType: "datetime",
                            caption: "Action Date/Time",
                            sortIndex: 0 ,
                            sortOrder: "desc",
                        },
                        {
                            dataField: "usersDisplayName",
                            dataType: "string",
                            caption: "Person",
                            cellTemplate: DxGridUtils.truncateCellTemplate
                        },
                        {
                            dataField: "bankCompanyID",
                            dataType: "number",
                            caption: "Escrow Account",
                            lookup: {
                                dataSource: self.escrowBanks,
                                valueExpr: "id",
                                displayExpr: "name"
                            },
                        },
                        {
                            dataField: "gfNo",
                            dataType: "string",
                            caption: "File#",
                        },
                        {
                            dataField: "escrowMemo",
                        },
                    ],
                };

                self.gridDataSource = {
                    key: self.itemKey,
                    load (loadOptions) {
                        return Promise.resolve(self.items);
                    },
                };
            },

            onGotoFile(e) {
                if(!e || !e.data) return;
                this.$router.push(`/order/${e.data.ordersID}/check-writing`);
            },

            refresh() {
                if(!this.gridInstance) return;
                this.clear();
                this.gridInstance.refresh();
            },
        }
    }
</script>
<template>
    <div class="content-wrapper">
        <rq-banner
            variant="error"
            icon="fas fa-exclamation-triangle"
            :message="errorMessage"
            :visible="errorMessage.length > 0"
            @dismiss="errorMessage=''"
            dismissable
        />
        <rqdx-action-data-grid
            ref="dataGrid"
            automation_id="tbl_reconciliations"
            :config="gridConfig"
            class="grid-container mt-4"
            :data-source="gridDataSource"
            @rowDoubleClick="onRowDoubleClick"
            @selectionChanged="onSelectionChanged"
            export-file-name="reconciliations_data"
            :persist-state="false"
            hide-show-column-chooser
            integrated-search
            hide-search
            hide-toolbar
            rq-filters>
        </rqdx-action-data-grid>
    </div>
</template>

<script>
    import { ReconciliationDto }  from "../models";
    import DxGridUtils from "@/shared/utilities/DxGridUtils";

    export default {
        name:"SelectReconciliationForm",
        props: {
            bankCompanyID: { type: Number, required: true, default: 0 },
            excludeReconciliationID: { type: Number, required: true, default: 0 },
        },
        data () {
            return {
                reconciliationID: 0,
                items: [],
                completed: false,
                errorMessage: "",
            };
        },
        watch: {
            reconciliationID(newValue, oldValue) {
                if(_.isEqual(newValue, oldValue)) return;
                if(_.isEqual(newValue, 0)) {
                    this.$emit('disable-ok');
                } else {
                    this.$emit('enable-ok');
                }
            },
        },

        created() {
            this.$emit('disable-ok');
            this.initNonReactiveVariables();
            this.initGridConfig();
            this.fetchData();
        },

        computed: {
            gridInstance() { return _.get(this, "$refs.dataGrid.gridInstance", null); },
            localSecurity(){
                return this.securitySettings.findValues([
                    "AllowFinalizedReconModifications",
                    ]);
            },
        },

        methods: {
            clear() {
                if(!this.gridInstance) return;
                this.gridInstance.option("focusedRowIndex", -1);
                this.gridInstance.clearSelection();
            },

            fetchData() {
                const self = this;
                let apiPromise = self.$api.ReconciliationApi.getAllReconciliations(self.bankCompanyID);
                self.$rqBusy.wait(apiPromise)
                    .then(result => {
                        self.items = _.filter(_.map(result, i => new ReconciliationDto(i)), i => i.reconciliationID != self.excludeReconciliationID);
                    })
                    .catch(error => {
                        console.error(error);
                        self.$toast.error({ message: `Error loading ${self.itemTypeNamePlural}.` });
                    })
                    .finally(() => {
                        self.refresh();
                    });
            },

            initNonReactiveVariables() {
                const self = this;
                self.itemTypeName = "Reconciliation";
                self.itemTypeNamePlural = "Reconciliations";
                self.itemKey = "reconciliationID";
            },

            initGridConfig(){
                const self = this;
                self.gridConfig = {
                    columnHidingEnabled: false,
                    focusedRowEnabled: false,
                    selection: { mode: "single", showCheckBoxesMode: "never" },
                    paging: { enabled: true, pageSize: 15 },
                    pager: { showPageSizeSelector: true, allowedPageSizes: [15,50,100], showInfo: true},
                    remoteOperations: { sorting: false, paging: false },
                    height: "100%",
                    columns: [
                        {
                            dataField: "reconciliationID",
                            dataType: "number",
                            caption: "Recon ID",
                        },
                        {
                            dataField: "reconciliationDate",
                            dataType: "date",
                            caption: "Recon Date",
                            cellTemplate: DxGridUtils.reconDateCellTemplate,
                            sortIndex: 0,
                            sortOrder: "asc",
                        },
                        {
                            dataField: "beginningBalance",
                            dataType: "number",
                            format: {
                                type: "currency",
                                precision: 2
                            },
                        },
                        {
                            dataField: "miscCharges",
                            dataType: "number",
                            format: {
                                type: "currency",
                                precision: 2
                            },
                        },
                        {
                            dataField: "miscCredits",
                            dataType: "number",
                            format: {
                                type: "currency",
                                precision: 2
                            },
                        },
                        {
                            dataField: "receipts",
                            dataType: "number",
                            format: {
                                type: "currency",
                                precision: 2
                            },
                        },
                        {
                            dataField: "disbursements",
                            dataType: "number",
                            format: {
                                type: "currency",
                                precision: 2
                            },
                        },
                        {
                            dataField: "endingBalance",
                            dataType: "number",
                            format: {
                                type: "currency",
                                precision: 2
                            },
                        },
                        {
                            dataField: "workingEndBal",
                            dataType: "number",
                            format: {
                                type: "currency",
                                precision: 2
                            },
                        },
                        {
                            dataField: "completed",
                            caption: "Finalized",
                            dataType: "boolean",
                            cellTemplate: DxGridUtils.boolCellTemplate,
                            visible: false
                        },
                    ],
                };

                self.gridDataSource = {
                    key: self.itemKey,
                    load (loadOptions) {
                        return Promise.resolve(self.items);
                    },
                };
            },

            onRowDoubleClick (e) {
                this.errorMessage = '';
                let data = _.get(e, "selectedRowsData[0]", 0);
                if (_.parseBool(data.completed, false) && !this.localSecurity.AllowFinalizedReconModifications) {
                    this.errorMessage = 'Access Restricted. Unable to modify finalized recons.';
                    this.reconciliationID = 0;
                } else {
                    this.reconciliationID = _.parseNumber(data.reconciliationID, 0);
                    this.$emit("ok");
                }
            },

            onSelectionChanged(e) {
                this.errorMessage = '';
                let data = _.get(e, "selectedRowsData[0]", 0);
                if (_.parseBool(data.completed, false) && !this.localSecurity.AllowFinalizedReconModifications) {
                    this.errorMessage = 'Access Restricted. Unable to modify finalized recons.';
                    this.reconciliationID = 0;
                } else {
                    this.reconciliationID = _.parseNumber(data.reconciliationID, 0);
                }
            },

            refresh() {
                if(!this.gridInstance) return;
                this.clear();
                this.gridInstance.refresh();
            },
        }
    }
</script>
<template>
    <div class="content-wrapper">
        <rqdx-action-data-grid
            ref="dataGrid"
            automation_id="tbl_e_remit_file_summary"
            :config="gridConfig"
            class="rq-tab-content-grid"
            :data-source="gridDataSource"
            hide-show-column-chooser
            integrated-search
            :hide-search="items.length == 0"
            :read-only="readOnly"
            rq-filters
            force-floating-header>
        </rqdx-action-data-grid>
    </div>
</template>

<script>
    export default {
        name: "CmERemitFileSummaryGrid",
        components: {  },
        props: {
            items: {type: Array, required: true}
        },
        data () {
            return {
                gridConfig: {},
            };
        },

        watch: {
            items:{
                handler: function(newValue, oldValue) {
                    if(!_.isEqual(newValue, oldValue)) {
                        this.refresh();
                    }
                },
                deep: true,
                immediate: false
            },
        },

        created() {
            this.initGridConfig();
        },

        computed: {
            gridInstance() { return _.get(this, "$refs.dataGrid.gridInstance", null); },
            readOnly() { return false; },
        },

        methods: {
            clear() {
                if(!this.gridInstance) return;
                this.gridInstance.option("focusedRowIndex", -1);
                this.gridInstance.clearSelection();
            },

            initGridConfig(){
                const self = this;
                self.gridConfig = {
                    columnHidingEnabled: false,
                    focusedRowEnabled: false,
                    selection: { mode: "none" },
                    paging: { enabled: true },
                    pager: { showPageSizeSelector: true, allowedPageSizes: [50,100,500], showInfo: true},
                    remoteOperations: { sorting: false, paging: false },
                    height: "195px",
                    columns: [
                        {
                            dataField: "type",
                            dataType: "string",
                        },
                        {
                            dataField: "typeCount",
                            dataType: "number",
                            caption: "Count",
                        },
                        {
                            dataField: "liabilityTotal",
                            dataType: "number",
                            caption: "Liability",
                            format: {
                                type: "currency",
                                precision: 2
                            },
                        },
                        {
                            dataField: "grossTotal",
                            dataType: "number",
                            caption: "Gross Premium",
                            format: {
                                type: "currency",
                                precision: 2
                            },
                        },
                        {
                            dataField: "underwriterSplitTotal",
                            dataType: "number",
                            caption: "Under Split",
                            format: {
                                type: "currency",
                                precision: 2
                            },
                        },
                        {
                            dataField: "agentSplitTotal",
                            dataType: "number",
                            caption: "Agent Split",
                            format: {
                                type: "currency",
                                precision: 2
                            },
                        },
                    ],
                    summary: {
                        totalItems: [
                            {
                                name: "TypeCountTotal",
                                column: "typeCount",
                                alignment: "right",
                                displayFormat: "{0}",
                                summaryType: "sum"
                            },
                            {
                                name: "LiabilityTotal",
                                column: "liabilityTotal",
                                alignment: "right",
                                valueFormat: {
                                    type: "currency",
                                    precision: 2
                                },
                                displayFormat: "{0}",
                                summaryType: "sum"
                            },
                            {
                                name: "GrossTotal",
                                column: "grossTotal",
                                alignment: "right",
                                valueFormat: {
                                    type: "currency",
                                    precision: 2
                                },
                                displayFormat: "{0}",
                                summaryType: "sum"
                            },
                            {
                                name: "UnderwriterSplitTotal",
                                column: "underwriterSplitTotal",
                                alignment: "right",
                                valueFormat: {
                                    type: "currency",
                                    precision: 2
                                },
                                displayFormat: "{0}",
                                summaryType: "sum"
                            },
                            {
                                name: "AgentSplitTotal",
                                column: "agentSplitTotal",
                                alignment: "right",
                                valueFormat: {
                                    type: "currency",
                                    precision: 2
                                },
                                displayFormat: "{0}",
                                summaryType: "sum"
                            },
                            {
                                name: "AmountTotalLabel",
                                column: "type",
                                alignment: "left",
                                displayFormat: "TOTAL",
                                cssClass: "rq-summary-label",
                                summaryType: "sum"
                            },
                        ],
                    },
                };

                self.gridDataSource = {
                    key: "type",
                    load (loadOptions) {
                        return Promise.resolve(self.items);
                    },
                };
            },

            refresh() {
                if(!this.gridInstance) return;
                this.clear();
                this.gridInstance.refresh();
            },
        }
    }
</script>
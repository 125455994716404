import { ChecksDto, CheckShortDto, DepositShortDto, InvestmentDto } from "../file/check-writing/models";
import { CheckStatus } from "../file/check-writing/enums";
import DateTimeHelper from "@/shared/utilities/DateTimeHelper";

export class UnverifiedDepositDto {
	constructor(options) {
        options = options || {};
        this.id = _.parseNumber(options.id, 0);
        this.bankCompanyID = _.parseNumber(options.bankCompanyID, null);
        this.gfNo = options.gfNo || null;
        this.transnumber = options.transnumber || null;
        this.description = options.description || null;
        this.depositDate = options.depositDate || null;
        this.amount = _.parseNumber(options.amount, 0);
        this.typeFundDisplay = options.typeFundDisplay || null;
        this.branchDisplay = options.branchDisplay || null;
        this.isDepositSlip = _.parseBool(options.isDepositSlip, false);
    }
    get clientID(){ return `${this.isDepositSlip ? 'S' : 'D'}${this.id}`; }
}
export class EscrowDepositDto extends DepositShortDto {
	constructor(options) {
        options = options || {};
        super(options);
        this.gfNo = options.gfNo || null;
        this.escrowUnitID = _.parseNumber(options.escrowUnitID, null);
        this.reconciliationID = _.parseNumber(options.reconciliationID, null);
        this.reconTempID = _.parseNumber(options.reconTempID, null);
        this.usersDisplayName = options.usersDisplayName || null;
        this.isLocked = _.parseBool(options.isLocked, false);
        this.isEscrowLocked = _.parseBool(options.isEscrowLocked, false);
        this.escrowUnitID = _.parseNumber(options.escrowUnitID, null);
        this.lastFinalizedReconDate = options.lastFinalizedReconDate || '1/1/2000T00:00:00';
        this.reconciliationStartDate = options.reconciliationStartDate || null;
        this.reconciliationEndDate = options.reconciliationEndDate || null;
    }
}
export class EscrowCheckDto extends CheckShortDto {
	constructor(options) {
        options = options || {};
        super(options);
        this.gfNo = options.gfNo || null;
        this.payeeName = options.payeeName || null;
        this.stopPayDate = options.stopPayDate || null;
        this.reconciliationID = _.parseNumber(options.reconciliationID, null);
        this.reconTempID = _.parseNumber(options.reconTempID, null);
        this.isLocked = _.parseBool(options.isLocked, false);
        this.isEscrowLocked = _.parseBool(options.isEscrowLocked, false);
        this.useForwardingAddress = _.parseBool(options.useForwardingAddress, false);
        this.buyerSellerID = _.parseNumber(options.buyerSellerID, null);
        this.wireNumber = options.wireNumber || null;
        this.lastFinalizedReconDate = options.lastFinalizedReconDate || '1/1/2000T00:00:00';
        this.reconciliationStartDate = options.reconciliationStartDate || null;
        this.reconciliationEndDate = options.reconciliationEndDate || null;
    }

    get inactive() { return this.checkStatus === 3 || this.checkStatus === 4 || !_.isNil(this.voidDate); }

    toDataObject() { return _.toPlainObject(this); }
}
export class AutoReconCheckSearchRequest {
	constructor(options) {
        options = options || {};
        this.autoReconUserImportID = _.parseNumber(options.autoReconUserImportID, 0);
        this.bankCompanyID = _.parseNumber(options.bankCompanyID, null);
        this.escrowCheckStatusIDs = options.escrowCheckStatusIDs || [];
        this.checkAmountFrom = _.parseNumber(options.checkAmountFrom, null);
        this.checkAmountTo = _.parseNumber(options.checkAmountTo, null);
        this.checkNumberFrom = _.parseNumber(options.checkNumberFrom, null);//reused as wire# when isWire = true
        this.checkNumberTo = _.parseNumber(options.checkNumberTo, null);//reused as wire# when isWire = true
        this.checkDateFrom = options.checkDateFrom || null;
        this.checkDateTo = options.checkDateTo || null;
        this.isWire = _.parseBool(options.isWire, false);
    }
}

export class AutoReconDepositSearchRequest {
	constructor(options) {
        options = options || {};
        this.autoReconUserImportID = _.parseNumber(options.autoReconUserImportID, 0);
        this.bankCompanyID = _.parseNumber(options.bankCompanyID, null);
        this.depositStatusIDs = options.depositStatusIDs || [];
        this.depositAmountFrom = _.parseNumber(options.depositAmountFrom, null);
        this.depositAmountTo = _.parseNumber(options.depositAmountTo, null);
        this.depositReferenceFrom = _.parseNumber(options.depositReferenceFrom, null);//reused as wire# when isWire = true
        this.depositReferenceTo = _.parseNumber(options.depositReferenceTo, null);//reused as wire# when isWire = true
        this.depositDateFrom = options.depositDateFrom || null;
        this.depositDateTo = options.depositDateTo || null;
        this.typeFundIDs = options.typeFundIDs || [];
        this.isWire = _.parseBool(options.isWire, false);
    }
}

export class EscrowCheckSearchRequest {
	constructor(options) {
        options = options || {};
        this.gfNo = options.gfNo || null;
        this.bankCompanyID = _.parseNumber(options.bankCompanyID, null);
        this.escrowCheckStatusIDs = options.escrowCheckStatusIDs || [];
        this.ordersID = _.parseNumber(options.ordersID, 0);
        this.checkAmountFrom = _.parseNumber(options.checkAmountFrom, null);
        this.checkAmountTo = _.parseNumber(options.checkAmountTo, null);
        this.checkNumberFrom = _.parseNumber(options.checkNumberFrom, null);
        this.checkNumberTo = _.parseNumber(options.checkNumberTo, null);
        this.checkDateFrom = options.checkDateFrom || null;
        this.checkDateTo = options.checkDateTo || null;
    }
    // toDataObject() { return _.omit(_.clone(this), "gfNo"); }
}

export class EscrowDepositSearchRequest {
	constructor(options) {
        options = options || {};
        this.gfNo = options.gfNo || null;
        this.bankCompanyID = _.parseNumber(options.bankCompanyID, null);
        this.depositStatusIDs = options.depositStatusIDs || [];
        this.ordersID = _.parseNumber(options.ordersID, 0);
        this.depositAmountFrom = _.parseNumber(options.depositAmountFrom, null);
        this.depositAmountTo = _.parseNumber(options.depositAmountTo, null);
        this.depositReceiptIDFrom = _.parseNumber(options.depositReceiptIDFrom, null);
        this.depositReceiptIDTo = _.parseNumber(options.depositReceiptIDTo, null);
        this.depositDateFrom = options.depositDateFrom || null;
        this.depositDateTo = options.depositDateTo || null;
        this.typeFundIDs = options.typeFundIDs || [];
    }
    // toDataObject() { return _.omit(_.clone(this), "gfNo"); }
}

export class ExpectedWireOutDto {
	constructor(options) {
        options = options || {};
        this.checksID = _.parseNumber(options.checksID, 0);
        this.gfNo = options.gfNo || null;
        this.ordersID = _.parseNumber(options.ordersID, 0);
        this.checkStatus = _.parseNumber(options.checkStatus, 0);
        this.reference = options.reference || null;
        this.payee = options.payee || null;
        this.regionID = _.parseNumber(options.regionID, 0);
        this.branchID = _.parseNumber(options.branchID, 0);
        this.wireOutStatus = _.parseNumber(options.wireOutStatus, 0);
        this.amount = _.parseNumber(options.amount, 0);
        this.checkDate = options.checkDate || null;
        this.expectedWireOutDate = options.expectedWireOutDate || null;
        this.bankCompanyID = _.parseNumber(options.bankCompanyID, null);
        this.requestedDate = options.requestedDate || null;
        this.escrowOfficerStaffID = _.parseNumber(options.escrowOfficerStaffID, null);
        this.wireNumber = options.wireNumber || null;
        this.isLocked = _.parseBool(options.isLocked, false);
        this.isEscrowLocked = _.parseBool(options.isEscrowLocked, false);
        this.wireOutApprover = options.wireOutApprover || null;
        this.wireOutRequestor = options.wireOutRequestor || null;
    }
}

export class ExpectedWireOutSearchRequest {
	constructor(options, fromSearch) {
        options = options || {};
        this.expectedWireOutDateFrom = fromSearch ? options.expectedWireOutDateFrom : DateTimeHelper.now("MM/dd/yyyy");
        this.expectedWireOutDateTo = options.expectedWireOutDateTo || null;
    }
}

export class ExpectedWireSearchRequest {
	constructor(options, fromSearch) {
        options = options || {};
        this.expectedWireDateFrom = fromSearch ? options.expectedWireDateFrom : DateTimeHelper.now("MM/dd/yyyy");
        this.expectedWireDateTo = options.expectedWireDateTo || null;
    }
}

export class ReconciliationDto {
	constructor(options) {
        options = options || {};
        this.reconciliationID = _.parseNumber(options.reconciliationID, 0);
        this.completed = _.parseBool(options.completed);
        this.reconciliationDate = options.reconciliationDate || null;
        this.beginningBalance = _.parseNumber(options.beginningBalance, 0);
        this.disbursements = _.parseNumber(options.disbursements, 0);
        this.endingBalance = _.parseNumber(options.endingBalance, 0);
        this.miscCharges = _.parseNumber(options.miscCharges, 0);
        this.miscCredits = _.parseNumber(options.miscCredits, 0);
        this.receipts = _.parseNumber(options.receipts, 0);
        this.workingEndBal = _.parseNumber(options.workingEndBal, 0);
        this.bankCompanyID = _.parseNumber(options.bankCompanyID, 0);
        this.inactiveRecon = _.parseBool(options.inactiveRecon);
        this.createdByUsersID = _.parseNumber(options.createdByUsersID, null);
        this.autoRecon = _.parseBool(options.autoRecon);
        this.escrowTrialBalance = _.parseNumber(options.escrowTrialBalance, 0);
        this.outstandingChecks = _.parseNumber(options.outstandingChecks, 0);
        this.depositsInTransit = _.parseNumber(options.depositsInTransit, 0);
        this.isOutOfSync = _.parseBool(options.isOutOfSync, false);
        this.priorReconciliationID = _.parseNumber(options.priorReconciliationID, null);
        this.otherDisbursements = _.parseNumber(options.otherDisbursements, 0);
        this.otherReceipts = _.parseNumber(options.otherReceipts, 0);
        this.disableDelete = _.parseBool(options.disableDelete);
        this.priorBookBalance = _.parseNumber(options.priorBookBalance, 0);
        this.bookBalance = _.parseNumber(options.bookBalance, 0);
        this.reconciledBankBalance = _.parseNumber(options.reconciledBankBalance, 0);
        this.adjustedReconciledBankBalance = _.parseNumber(options.adjustedReconciledBankBalance, 0);
        this.reconciliationStartDate = options.reconciliationStartDate || null;
    }
    get isReconFinalized() { return this.completed; }
    get difference(){ return this.workingEndBal - this.endingBalance; }
    get hasAdjustments(){ return (this.otherDisbursements + this.otherReceipts) > 0; }
    get totalDisbursements(){ return this.disbursements + this.miscCharges; }
    get totalReceipts(){ return this.receipts + this.miscCredits; }
}

export class ReconciliationCheckDto {
	constructor(options) {
        options = options || {};
        this.checksID = _.parseNumber(options.checksID, 0);
        this.gfNo = options.gfNo || null;
        this.ordersID = _.parseNumber(options.ordersID, 0);
        this.checkNumber = _.parseNumber(options.checkNumber, null);
        this.numberDisplay = options.numberDisplay || null;
        this.checkDate = options.checkDate || null;
        this.amount = _.parseNumber(options.amount, 0);
        this.payee = options.payee || null;
        this.clearedTemp = _.parseBool(options.clearedTemp, false);
    }
}

export class ReconciliationDepositDto {
	constructor(options) {
        options = options || {};
        this.depositID = options.depositID || null;
        this.gfNo = options.gfNo || null;
        this.ordersID = _.parseNumber(options.ordersID, 0);
        this.transactionNumber = options.transactionNumber || null;
        this.description = options.description || null;
        this.depositDate = options.depositDate || null;
        this.referenceNumber = options.referenceNumber || null;
        this.amount = _.parseNumber(options.amount, 0);
        this.clearedTemp = _.parseBool(options.clearedTemp, false);
    }
}

export class ReconciliationBalanceSheetDto {
	constructor(options) {
        options = options || {};
        if (_.has(options, 'checksID')) {
            this.id = `Check-${_.parseNumber(options.checksID, 0)}`;
            this.number = options.numberDisplay || null;
            this.date = options.checkDate || null;
            this.description = options.payee || null;
            this.checkAmount = _.parseNumber(options.amount, 0);
            this.isCheck = true;
        } else {
            this.id = options.depositID || null;
            this.number = options.transactionNumber || null;
            this.date = options.depositDate || null;
            this.description = options.description || null;
            this.depositAmount = _.parseNumber(options.amount, 0);
            this.isCheck = false;
        }
        this.gfNo = options.gfNo || null;
        this.ordersID = _.parseNumber(options.ordersID, 0);
    }
}

export class ReconciliationInTransitDto {
	constructor(options) {
        options = options || {};
        this.id = options.id || null;
        this.number = options.number || null;
        this.itemDate = options.itemDate || null;
        this.description = options.description || null;
        this.checkAmount = _.parseNumber(options.checkAmount, 0);
        this.depositAmount = _.parseNumber(options.depositAmount, 0);
    }
}
export class OtherDisbursementReconciliationDto {
	constructor(options) {
        options = options || {};
        this.otherDisbursementReconciliationID = _.parseNumber(options.otherDisbursementReconciliationID, 0);
        this.description = options.description || null;
        this.disburse = _.parseBool(options.disburse, false);
        this.error = _.parseBool(options.error, false);
        this.errorRecon = _.parseBool(options.errorRecon, false);
        this.amount = _.parseNumber(options.amount, 0);
        this.reconciliationID = _.parseNumber(options.reconciliationID, null);
        this.carryForwardToRBB = _.parseBool(options.carryForwardToRBB, false);
        this.carryForwardToRecon = _.parseBool(options.carryForwardToRecon, false);
    }
}

export class OpenInvestmentDto extends InvestmentDto {
    constructor(options) {
        options = options || {};
        super(options);
        this.gfNo = options.gfNo || null;
        this.regionID = _.parseNumber(options.regionID, 0);
        this.branchID = _.parseNumber(options.branchID, 0);
        this.bankCompanyID = _.parseNumber(options.bankCompanyID, 0);
        this.isLocked = _.parseBool(options.isLocked, false);
        this.isEscrowLocked = _.parseBool(options.isEscrowLocked, false);
    }
}

export class OpenInvestmentSearchRequest {
	constructor(options) {
        options = options || {};
        this.regionID = _.parseNumber(options.regionID, null);
        this.branchID = _.parseNumber(options.branchID, null);
    }
}

export class ConsolidatedCheckDto {
    constructor(options) {
        options = options || {};
        this.consolidatedCheckID = _.parseNumber(options.consolidatedCheckID, 0);
        this.bankCompanyID = _.parseNumber(options.bankCompanyID, null);
        this.bankName = options.bankName || "";
        this.checkNumber = _.parseNumber(options.checkNumber, null);
        this.checkDate = options.checkDate || null;
        this.reconciliationDate = options.reconciliationDate || null;
        this.voidDate = options.voidDate || null;
        this.amount = _.parseNumber(options.amount, 0);
        this.checkStatus = _.parseNumber(options.checkStatus, CheckStatus.None);
        this.reconciliationID = _.parseNumber(options.reconciliationID, null);
        this.memo = options.memo || null;
        this.usersID = _.parseNumber(options.usersID, null);
        this.consolidatedOrdersID = _.parseNumber(options.consolidatedOrdersID, null);
        this.checksID = _.parseNumber(options.checksID, null);
        this.isWire = _.parseBool(options.isWire);
        this.wireNumber = options.wireNumber || null;
        this.reference = options.reference || null;
        this.receivingBankName = options.receivingBankName || null;
        this.receivingABA = options.receivingABA || null;
        this.receivingBankAddress = options.receivingBankAddress || null;
        this.receivingBankCity = options.receivingBankCity || null;
        this.receivingBankState = options.receivingBankState || null;
        this.receivingBankZip = options.receivingBankZip || null;
        this.receivingAccountNumber = options.receivingAccountNumber || null;
        this.receivingCustomerName = options.receivingCustomerName || null;
        this.receivingWireInstructions = options.receivingWireInstructions || null;
        this.offlineBankName = options.offlineBankName || null;
        this.offlineABA = options.offlineABA || null;
        this.offlineBankAddress = options.offlineBankAddress || null;
        this.offlineBankCity = options.offlineBankCity || null;
        this.offlineBankState = options.offlineBankState || null;
        this.offlineBankZip = options.offlineBankZip || null;
        this.offlineAccountNumber = options.offlineAccountNumber || null;
        this.offlineFurtherCredit = options.offlineFurtherCredit || null;
        this.offlineNotes = options.offlineNotes || null;

        this.payeeCompanyID = _.parseNumber(options.payeeCompanyID, null);
        this.payeeName = options.payeeName || null;
        this.payeeAddress1 = options.payeeAddress1 || null;
        this.payeeAddress2 = options.payeeAddress2 || null;
        this.payeeCity = options.payeeCity || null;
        this.payeeState = options.payeeState || null;
        this.payeeZip = options.payeeZip || null;

        this.consolidatedFileNumber = options.consolidatedFileNumber || null;

        this.checks = _.isArray(options.checks) ? _.map(options.checks, c => new ConsolidatedCheckSelectCheckDto(c)) : null;

        this.isReconFinalized = _.parseBool(options.isReconFinalized, false);
        this.isReconciled = _.parseNumber(this.reconciliationID, 0) > 0;
        this.isIssued = this.checkStatus === CheckStatus.Issued;
        this.isVoid = this.checkStatus === CheckStatus.Void;
        this.isStopped = this.checkStatus === CheckStatus.StopPay;
        this.noStatus = this.checkStatus === CheckStatus.None;
        this.hasStatus = !this.noStatus;
        this.hasCheckNumber = this.isWire
            ? !_.isEmpty(this.wireNumber)
            : _.parseNumber(this.checkNumber, 0) > 0;
        this.numberDisplay = this.isWire
            ? this.wireNumber && `W-${this.wireNumber}`
            : this.checkNumber && `C-${this.checkNumber}`;
    }

    get assignedAmount() { return _.sumBy(this.checks, c => c.createSource * c.amount); }
    get isDirty() { return this.amount !== this.assignedAmount; }

    toDataObject() { return _.toPlainObject(this); }
}

export class ConsolidatedCheckSelectCheckDto extends ChecksDto {
    constructor(options) {
        options = options || {};
        super(options);

        this.gfNo = options.gfNo || null;
        this.transferredToOrder = options.transferredToOrder || null;
        this.transferredFromOrder = options.transferredFromOrder || null;
        this.bankName = options.bankName || null;
        this.consolidatedFileNumber = options.consolidatedFileNumber || null;
    }
}

export class ConsolidatedCheckSearchRequestDto {
	constructor(options) {
        options = options || {};
        this.bankCompanyID = _.parseNumber(options.bankCompanyID, null);
        this.payeeCompanyIDs = options.payeeCompanyIDs || [];
        // this.statusIDs = options.statusIDs || [];
        this.dateFrom = options.dateFrom || null;
        this.dateTo = options.dateTo || null;
    }
}


//I removed several fields that had no references
export class PositivePay {
	constructor(options) {
        options = options || {};
        this.positivePayID = _.parseNumber(options.positivePayID, 0);
        this.bankCompanyID = _.parseNumber(options.bankCompanyID, null);
        this.accountNumber = options.accountNumber || null; //Maintained at the Escrow Bank level
        this.reportExtension = _.parseNumber(options.reportExtension, 0);//Zero = PDF
        this.exportFileName = options.exportFileName || null;
        this.uniqueFileNameExtension = options.uniqueFileNameExtension || null;
        this.sumReportExtension = _.parseNumber(options.sumReportExtension, 0);//Zero = PDF
        this.exportFormat = _.parseNumber(options.exportFormat, null);
        this.backDays = _.parseNumber(options.backDays, null);
        this.sendOnce = _.parseBool(options.sendOnce, false);
        this.showReport = _.parseBool(options.showReport, false);
        this.summaryReport = _.parseBool(options.summaryReport, false);
        this.makeUniqueName = _.parseBool(options.makeUniqueName, false);
        this.useIssueTime = _.parseBool(options.useIssueTime, false);
        this.info1 = options.info1 || null;
        this.info2 = options.info2 || null;
        this.info3 = options.info3 || null;
        this.info4 = options.info4 || null;
        this.info5 = options.info5 || null;
        this.deleted = _.parseBool(options.deleted, false);
    }
    // get companyID() { return this.bankCompanyID; }//had to make a new column for the grid with a different name
    toDataObject() { return _.toPlainObject(this); }
}

// export class PositivePayDto extends PositivePay {
// 	constructor(options) {
//         options = options || {};
//         super(options);
//         this.exportFormatDisplayName = options.exportFormatDisplayName || null;
//     }
// }
export class PositivePaySearchRequestDto {
	constructor(options) {
        options = options || {};
        this.checkTimeFrom = options.checkTimeFrom || null;
        this.checkTimeTo = options.checkTimeTo || null;
    }
}

export class PositivePayProcessRequestDto extends PositivePaySearchRequestDto {
    constructor(options) {
        options = options || {};
        super(options);
        this.bankIDs = options.bankIDs || null;
    }
}

export class PositivePayProcessResultDto {
	constructor(options) {
        options = options || {};
        this.bankCompanyID = _.parseNumber(options.bankCompanyID, 0);
        this.fileName = options.fileName || null;
        this.fileContent = options.fileContent || null;
        this.showReport = _.parseBool(options.showReport, false);//future use
        this.summaryReport = _.parseBool(options.summaryReport, false);//future use
    }
}

export class PositivePayValidationResultDto {
	constructor(options) {
        options = options || {};
        this.isValid = _.parseBool(options.isValid);
        this.info1Required = _.parseBool(options.info1Required);
        this.info1Message = options.info1Message || null;
        this.info2Required = _.parseBool(options.info2Required);
        this.info2Message = options.info2Message || null;
        this.info3Required = _.parseBool(options.info3Required);
        this.info3Message = options.info3Message || null;
        this.info4Required = _.parseBool(options.info4Required);
        this.info4Message = options.info4Message || null;
        this.info5Required = _.parseBool(options.info5Required);
        this.info5Message = options.info5Message || null;
    }
}
export class EscrowTrialBalanceDto {
	constructor(options) {
        options = options || {};
        this.ordersID = _.parseNumber(options.ordersID, 0);
        this.bankCompanyID = _.parseNumber(options.bankCompanyID, null);
        this.regionID = _.parseNumber(options.regionID, 0);
        this.branchID = _.parseNumber(options.branchID, 0);
        this.gfNo = options.gfNo || null;
        this.escrowMemo = options.escrowMemo || null;
        this.lastActivityDate = options.lastActivityDate || null;
        this.accountBalance = _.parseNumber(options.accountBalance, 0);
        this.receipts = _.parseNumber(options.receipts, 0);
        this.disbursements = _.parseNumber(options.disbursements, 0);
        this.isEscrowLocked = _.parseBool(options.isEscrowLocked, false);
        this.isLocked = _.parseBool(options.isLocked, false);
        this.isGlobal = _.parseBool(options.isGlobal, false);
    }
    get hasNote() { return !_.isNullOrEmpty(this.escrowMemo); }
}

export class EscrowTrialBalanceSearchRequest {
	constructor(options) {
        options = options || {};
        this.bankCompanyID = _.parseNumber(options.bankCompanyID, null);
        this.noteOnly = _.parseBool(options.noteOnly, false);
    }
}

export class EscrowActivitySearchRequestDto {
	constructor(options) {
        options = options || {};
        this.gfNo = options.gfNo || null;
        this.bankCompanyID = _.parseNumber(options.bankCompanyID, null);
        this.usersID = _.parseNumber(options.usersID, null);
        this.ordersID = _.parseNumber(options.ordersID, 0);
        this.checkActionIDs = options.checkActionIDs || [];
        this.amountFrom = _.parseNumber(options.amountFrom, null);
        this.amountTo = _.parseNumber(options.amountTo, null);
        this.dateFrom = options.dateFrom || null;
        this.dateTo = options.dateTo || null;
    }
    // toDataObject() { return _.omit(_.clone(this), "gfNo"); }
}

export class EscrowActivityDto {
	constructor(options) {
        options = options || {};
        this.checkSessionDetailID = _.parseNumber(options.checkSessionDetailID, 0);
        if (_.parseNumber(options.checksID, 0) > 0) {
            this.itemDate = options.checkDate || null;
            this.itemType = 'Disbursement';
            this.payeeName = options.payeeName || null;
            this.receiptNumber = options.checkNumberDisplay || null;
        } else {
            this.itemDate = options.depositDate || null;
            this.itemType = 'Receipt';
            this.payeeName = options.payorName || null;
            this.receiptNumber = _.parseNumber(options.receiptID, 0) === 0 ? null : `${options.bankCompanyID || ""}-${options.receiptID}`;
        }
        this.checkStatusDisplay = options.checkStatusDisplay || null;
        this.amount = _.parseNumber(options.amount, 0);
        this.bankCompanyID = _.parseNumber(options.bankCompanyID, null);
        this.ordersID = _.parseNumber(options.ordersID, 0);
        this.gfNo = options.gfNo || null;
        this.actionType = _.parseNumber(options.actionType, null);
        this.usersDisplayName = options.usersDisplayName || null;
        this.sessionDate = options.sessionDate || null;
    }
}

export class EscrowCheckActivityDto {
	constructor(options) {
        options = options || {};
        this.checkSessionDetailID = _.parseNumber(options.checkSessionDetailID, 0);
        this.checksID = _.parseNumber(options.checksID, 0);
        this.checkLineNumber = _.parseNumber(options.checkLineNumber, null);
        this.checkDate = options.checkDate || null;
        this.amount = _.parseNumber(options.amount, 0);
        this.bankCompanyID = _.parseNumber(options.bankCompanyID, null);
        this.ordersID = _.parseNumber(options.ordersID, 0);
        this.gfNo = options.gfNo || null;
        this.actionType = _.parseNumber(options.actionType, null);
        this.isManualPayee = _.parseBool(options.isManualPayee, false);
        this.payeeName = options.payeeName || null;
        this.checkNumberDisplay = options.checkNumberDisplay || null;
        this.checkStatusDisplay = options.checkStatusDisplay || null;
        this.usersDisplayName = options.usersDisplayName || null;
        this.sessionDate = options.sessionDate || null;
    }
}

export class EscrowDepositActivityDto {
	constructor(options) {
        options = options || {};
        this.checkSessionDetailID = _.parseNumber(options.checkSessionDetailID, 0);
        this.depositID = _.parseNumber(options.depositID, 0);
        this.depositDate = options.depositDate || null;
        this.amount = _.parseNumber(options.amount, 0);
        this.bankCompanyID = _.parseNumber(options.bankCompanyID, null);
        this.ordersID = _.parseNumber(options.ordersID, 0);
        this.gfNo = options.gfNo || null;
        this.actionType = _.parseNumber(options.actionType, null);
        this.payorName = options.payorName || null;
        this.usersDisplayName = options.usersDisplayName || null;
        this.sessionDate = options.sessionDate || null;
        this.receiptID = _.parseNumber(options.receiptID, null);
    }

    get receiptOfDepositID (){
        if (_.parseNumber(this.receiptID, 0) == 0){ return null; }
        return this.bankCompanyID + '-' + this.receiptID
    }
}

export class EscrowMemoActivityDto {
	constructor(options) {
        options = options || {};
        this.checkSessionDetailID = _.parseNumber(options.checkSessionDetailID, 0);
        this.ordersID = _.parseNumber(options.ordersID, 0);
        this.gfNo = options.gfNo || null;
        this.bankCompanyID = _.parseNumber(options.bankCompanyID, null);
        this.actionType = _.parseNumber(options.actionType, null);
        this.escrowMemo = options.escrowMemo || null;
        this.usersDisplayName = options.usersDisplayName || null;
        this.sessionDate = options.sessionDate || null;
    }
}

export class ReconciliationSearchRequest {
	constructor(options) {
        options = options || {};
        this.bankCompanyID = _.parseNumber(options.bankCompanyID, null);
        this.completed = _.parseBool(options.completed, false);
        this.reconciliationDateFrom = options.reconciliationDateFrom || null;
        this.reconciliationDateTo = options.reconciliationDateTo || null;
    }
}

export class PositivePayExportTemplateItemDto {
	constructor(options) {
        options = options || {};
        this.positivePayExportTemplateItemID = _.parseNumber(options.positivePayExportTemplateItemID, null);
        this.exportEnum = _.parseNumber(options.exportEnum, null);
        this.fieldNumber = _.parseNumber(options.fieldNumber, null);
        this.field = options.field || null;
        this.fieldInfo = options.fieldInfo || null;
    }
}

export class AutoReconUserImportDto {
	constructor(options) {
        options = options || {};
        this.autoReconUserImportID = _.parseNumber(options.autoReconUserImportID, 0);
        this.usersID = _.parseNumber(options.usersID, 0);
        this.bankCompanyID = _.parseNumber(options.bankCompanyID, 0);
        this.fileName = options.fileName || null;
        this.importTime = options.importTime || null;
        this.totalCredits = _.parseNumber(options.totalCredits, 0);
        this.totalDebits = _.parseNumber(options.totalDebits, 0);
        this.endingBalance = _.parseNumber(options.endingBalance, 0);
        this.reconciliationID = _.parseNumber(options.reconciliationID, null);
        this.priorReconciliationID = _.parseNumber(options.priorReconciliationID, null);
        this.reconciliationDate = options.reconciliationDate || null;
        this.priorReconciliationDate = options.priorReconciliationDate || null;
        this.processed = _.parseBool(options.processed, false);
        this.lookBackDays = _.parseNumber(options.lookBackDays, 0);
        this.forwardDays = _.parseNumber(options.forwardDays, 0);
    }
    get displayName() { return `${this.fileName} - ${DateTimeHelper.toFormat(this.importTime, 'MM/dd/yyyy hh:mm a')}`; }
}
export class AutoReconTransactionTempDetailDto {
    constructor (options) {
        options = options || {};
		this.autoReconTransactionTempDetailID = _.parseNumber(options.autoReconTransactionTempDetailID, 0);
        this.autoReconUserImportID = _.parseNumber(options.autoReconUserImportID, 0);
        this.autoReconTransactionTypeCode = options.autoReconTransactionTypeCode || null;
        this.transactionDate = options.transactionDate || null;
        this.transactionAmount = _.parseNumber(options.transactionAmount, 0);
        this.bankReferenceNumber = options.bankReferenceNumber || null;
        this.customerReferenceNumber = options.customerReferenceNumber || null;
        this.notes = options.notes || null;
        this.checksID = _.parseNumber(options.checksID, null);
        this.depositID = _.parseNumber(options.depositID, null);
        this.depositSlipID = _.parseNumber(options.depositSlipID, null);
        this.issue = options.issue || null;
        this.transactionType = options.transactionType || null;
        this.transactionDescription = options.transactionDescription || null;
        this.ordersID = _.parseNumber(options.ordersID, null);
        this.reconciliationID = _.parseNumber(options.reconciliationID, null);
        this.isOther = _.parseBool(options.isOther, false);
    }
    get hasIssue() { return !_.isNullOrEmpty(this.issue); }
    get hasMatch() { return ((_.parseNumber(this.checksID, 0) + _.parseNumber(this.depositID, 0)) > 0 && _.isNullOrEmpty(this.issue)); }
    get isCheck() { return _.startsWith(this.transactionType, "Debit"); }
    get isWire() { return _.endsWith(this.transactionType, "Wire"); }
}

export class PendingApprovalDto {
    constructor (options) {
        options = options || {};
        this.moneyMovementApprovalID = _.parseNumber(options.moneyMovementApprovalID, 0);
        this.requestedOn = options.requestedOn || null;
        this.gfNo = options.gfNo || null;
        this.ordersID = _.parseNumber(options.ordersID, 0);
		this.requestorUsersID = _.parseNumber(options.requestorUsersID, 0);
		this.amount = _.parseNumber(options.amount, 0);
		this.bankCompanyID = _.parseNumber(options.bankCompanyID, 0);
		this.moneyMovementTypeID = _.parseNumber(options.moneyMovementTypeID, 0);
        this.regionID = _.parseNumber(options.regionID, 0);
        this.branchID = _.parseNumber(options.branchID, 0);
        this.entityID = _.parseNumber(options.entityID, 0);
        this.requestorName = options.requestorName || null;
		this.memo = options.memo || null;
        this.approvedOn = options.approvedOn || null;
        this.rejectedOn = options.rejectedOn || null;
        this.approverUsersID = _.parseNumber(options.approverUsersID, 0);
        this.approversComment = options.approversComment || null;
        this.requestedAmount = _.parseNumber(options.requestedAmount, null);
        this.requestorComment = options.requestorComment || null;
        this.checkAmount = _.parseNumber(options.checkAmount, 0);
        this.lineDescription = options.lineDescription || null;
        this.payee = options.payee || null;
        this.lossAdvanceReasonCategory = options.lossAdvanceReasonCategory || null;
        this.lossAdvanceReason = options.lossAdvanceReason || null;
        this.isRecoverable = _.parseBool(options.isRecoverable, false);
        this.approvalDisabled = _.parseBool(options.approvalDisabled, false);
        this.totalAdjustedAmount = _.parseNumber(options.totalAdjustedAmount, 0);
        this.moneyMovementBatchApprovalType = _.parseNumber(options.moneyMovementBatchApprovalType, 0);
    }
}

export class PendingApprovalSearchRequest {
    constructor (options) {
        options = options || {};
        this.bankCompanyID = _.parseNumber(options.bankCompanyID, 0);
        this.regionID = _.parseNumber(options.regionID, 0);
        this.branchID = _.parseNumber(options.branchID, 0);
        this.moneyMovementTypeID = _.parseNumber(options.moneyMovementTypeID, 0);
    }
}

export class ERemitFileSearchRequestDto {
	constructor(options) {
        options = options || {};
        this.partnerGUID = options.partnerGUID || null;
        this.underwriterCode = options.underwriterCode || null;
        this.regionID = options.regionID || "0";
        this.branchCode = options.branchCode || null;
        this.titleCompanyID = options.titleCompanyID || null;
        this.dateFrom = options.dateFrom || null;
        this.dateTo = options.dateTo || null;
    }
}

export class ERemitHistorySearchRequestDto {
	constructor(options) {
        options = options || {};
        this.dateFrom = options.dateFrom || null;
        this.dateTo = options.dateTo || null;
    }
}

export class ERemitFileSummaryDto {
	constructor(options) {
        options = options || {};
        this.type = options.type || null;
        this.typeCount = _.parseNumber(options.typeCount, 0);
        this.liabilityTotal = _.parseNumber(options.liabilityTotal, 0);
        this.grossTotal = _.parseNumber(options.grossTotal, 0);
        this.underwriterSplitTotal = _.parseNumber(options.underwriterSplitTotal, 0);
        this.agentSplitTotal = _.parseNumber(options.agentSplitTotal, 0);
    }
}
import { DateTime } from "luxon";

const isDateBeforeLastRecon = (item, dateField) => {
    let targetDate = _.get(item, dateField, null);
    if(_.isEmpty(targetDate)) return false;
    let dtTargetDate = DateTime.fromISO(targetDate)
    let dtLastReconDate = DateTime.fromISO(item.lastFinalizedReconDate);
    return dtTargetDate.diff(dtLastReconDate, "days").days <= 0;
};

export const isCheckDateBeforeLastRecon = item => isDateBeforeLastRecon(item, "checkDate");
export const isDepositDateBeforeLastRecon = item => isDateBeforeLastRecon(item, "depositDate");

export const getIsDateDisabledFunction = (lastFinalizedReconDate, allowFinalizedReconModifications, preDateDaysSetting, postDateDaysSetting) => {
    let dtNow = DateTime.now().startOf("day");
    let dtReconDate = DateTime.fromISO(lastFinalizedReconDate).plus({ days: 1 }).startOf("day");
    let reconDaysDiff = dtReconDate.diff(dtNow, "days").days;
    let preDateDays = allowFinalizedReconModifications
        ? preDateDaysSetting
        : Math.abs(reconDaysDiff);
    let postDateDays = postDateDaysSetting;

    return ({ date }) => {
        //-1 === unlimited
        if(preDateDays === -1 && postDateDays === -1) return false;

        let currentDate = DateTime.fromISO(date).startOf("day");
        let daysDiff = currentDate.diff(dtNow, "days").days;

        if(daysDiff === 0) return false; //today is always valid

        //predate is unlimited but not postdate
        if(preDateDays === -1){
            if(daysDiff <= 0) return false;
            return daysDiff > postDateDays;
        }
        //postdate is unlimited but not predate
        else if(postDateDays === -1){
            if(daysDiff >= 0) return false;
            return Math.abs(daysDiff) > preDateDays;
        }
        //neither is unlimited
        return (daysDiff < 0 && Math.abs(daysDiff) > preDateDays) || (daysDiff > 0 && daysDiff > postDateDays);
    };
};
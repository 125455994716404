<template>
    <div class="content-wrapper rq-container">
        <rq-banner
            ref="errorBanner"
            :message="errorMessage"
            variant="error"
            icon="fas fa-exclamation-triangle"
            :visible="errorMessage.length > 0"
        />
        <fieldset class="py-3" :disabled="readOnly">
            <template v-if="isCheck">
                <div class="row">
                    <div class="col col-4 form-group">
                        <label for="txt_requested_on">Date</label>
                        <input
                            automation_id="txt_requested_on"
                            class="form-control"
                            type="text"
                            disabled
                            :value="formatDate(item.requestedOn)"
                            />
                    </div>
                    <div class="col col-4 form-group" >
                        <label for="txt_amount">Amount</label>
                        <rq-input-number
                            automation_id="txt_amount"
                            ref="txt_amount"
                            formatType="money"
                            decimals="2"
                            disabled
                            prependIcon="fas fa-dollar-sign"
                            v-model="item.requestedAmount"
                            />
                    </div>
                    <div class="col col-4 form-group">
                        <label for="txt_requestor_name">Requestor</label>
                        <input
                            automation_id="txt_requestor_name"
                            class="form-control"
                            type="text"
                            disabled
                            :value="item.requestorName"
                            />
                    </div>
                </div>
                <div class="row" v-show="!readOnly">
                    <div class="col col-4 form-group">
                        <label for="txt_gfNo">File#</label>
                        <input
                            automation_id="txt_gfNo"
                            class="form-control"
                            type="text"
                            disabled
                            :value="item.gfNo"
                            />
                    </div>
                    <div class="col col-4 form-group">
                        <label for="txt_payee">Payee</label>
                        <input
                            automation_id="txt_payee"
                            class="form-control"
                            type="text"
                            disabled
                            :value="item.payee"
                            />
                    </div>
                    <div class="col col-4 form-group">
                        <label for="txt_memo">Memo</label>
                        <input automation_id="txt_memo"
                            class="form-control"
                            type="text"
                            disabled
                            :value="item.memo"
                            />
                    </div>
                </div>
                <div class="row" v-show="hasDebtorContact">
                    <div class="col col-4 form-group">
                        <label for="txt_debtor_role">Debtor Role</label>
                        <input
                            automation_id="txt_debtor_role"
                            class="form-control"
                            type="text"
                            disabled
                            :value="debtorContact.role"
                            />
                    </div>
                    <div class="col col-4 form-group">
                        <label for="txt_debtor_name">Debtor Name</label>
                        <input
                            automation_id="txt_debtor_name"
                            class="form-control"
                            type="text"
                            disabled
                            :value="debtorContact.name"
                            />
                    </div>
                </div>
                <div class="row" v-if="isLossAdvance">
                    <div class="col col-6 form-group">
                        <label for="txt_lossAdvanceCategory">{{moneyMovementType}} Category</label>
                        <input
                            automation_id="txt_lossAdvanceCategory"
                            class="form-control"
                            type="text"
                            disabled
                            :value="item.lossAdvanceReasonCategory"
                            />
                    </div>
                    <div class="col col-6 form-group">
                        <label for="txt_lossAdvanceReason">{{moneyMovementType}} Reason</label>
                        <input
                            automation_id="txt_lossAdvanceReason"
                            class="form-control"
                            type="text"
                            disabled
                            :value="item.lossAdvanceReason"
                            />
                    </div>
                </div>
            </template>
            <template v-if="isAdjustment">
                <div class="row">
                    <div class="col col-4 form-group">
                        <label for="dtp_requested_on">Date</label>
                        <input
                            automation_id="txt_requested_on"
                            class="form-control"
                            type="text"
                            disabled
                            :value="formatDate(item.requestedOn)"
                            />
                    </div>
                    <div class="col col-4 form-group" >
                        <label for="txt_amount">Check Amount</label>
                        <rq-input-number
                            automation_id="txt_amount"
                            ref="txt_amount"
                            formatType="money"
                            decimals="2"
                            disabled
                            prependIcon="fas fa-dollar-sign"
                            v-model="item.checkAmount"
                            />
                    </div>
                    <div class="col col-4 form-group">
                        <label for="txt_requestor_name">Requestor</label>
                        <input
                            automation_id="txt_requestor_name"
                            class="form-control"
                            type="text"
                            disabled
                            :value="item.requestorName"
                            />
                    </div>
                </div>
                <div class="row">
                    <div class="col col-4 form-group">
                        <label for="txt_gfNo">File#</label>
                        <input
                            automation_id="txt_gfNo"
                            class="form-control"
                            type="text"
                            disabled
                            :value="item.gfNo"
                            />
                    </div>
                    <div class="col col-4 form-group">
                        <label for="txt_payee">Payee</label>
                        <input
                            automation_id="txt_payee"
                            class="form-control"
                            type="text"
                            disabled
                            :value="item.payee"
                            />
                    </div>
                    <div class="col col-4 form-group">
                        <label for="txt_memo">Memo</label>
                        <input automation_id="txt_memo"
                            class="form-control"
                            type="text"
                            disabled
                            :value="item.memo"
                            />
                    </div>
                </div>
                <div class="row">
                    <div class="col col-4 form-group">
                        <label for="txt_lineDescription">Line Description</label>
                        <input
                            automation_id="txt_lineDescription"
                            class="form-control"
                            type="text"
                            disabled
                            :value="item.lineDescription"
                            />
                    </div>
                    <div class="col col-4 form-group" >
                        <label for="txt_amount">Line Amount</label>
                        <rq-input-number
                            automation_id="txt_amount"
                            ref="txt_amount"
                            formatType="money"
                            decimals="2"
                            disabled
                            prependIcon="fas fa-dollar-sign"
                            v-model="item.amount"
                            />
                    </div>
                    <div class="col col-4 form-group">
                        <label for="txt_requested_amount">{{lineAdjustmentLabel}} Line Amount
                            <span class="ms-auto"
                                :title="lineAdjustmentToolTip"
                                v-if="showAdjustmentTooltip"
                                v-rq-tooltip.hover.right>
                                <FontAwesomeIcon icon="fas fa-info-circle" class="text-dark" />
                            </span>
                        </label>
                        <rq-input-number
                            automation_id="txt_requested_amount"
                            ref="txt_requested_amount"
                            formatType="money"
                            decimals="2"
                            disabled
                            prependIcon="fas fa-dollar-sign"
                            v-model="effectiveLineAmount"
                            />
                    </div>
                </div>
                <div class="row">
                    <div class="col col-12 form-group">
                        <label for="txt_requestor_comment">Requestor Comments</label>
                        <input
                            class="form-control"
                            type="text"
                            disabled
                            v-model="item.requestorComment"
                            />
                    </div>
                </div>
            </template>
            <div class="row" v-show="showComments">
                <div :class="{ 'col form-group':true, 'form-required':!approving && !readOnly, 'has-error':v$.item.approversComment.$error }">
                    <label for="txt_approvers_comment">{{readOnly ? "Approver " : ""}}Comments</label>
                    <input automation_id="txt_approvers_comment"
                        class="form-control"
                        type="text"
                        v-model="item.approversComment"
                        :disabled="readOnly"
                        placeholder="Enter comment here..."
                        />
                </div>
                <rq-validation-feedback :messages="{ 'Comment is required': v$.item.approversComment.required.$invalid }"/>
            </div>
            <div class="row" v-if="readOnly">
                <div class="col col-4 form-group">
                    <label for="txt_approved_by">Approved By</label>
                    <input automation_id="txt_approved_by"
                        class="form-control"
                        type="text"
                        v-model="item.approverName"
                        :disabled="readOnly"
                        />
                </div>
                <div class="col col-4 form-group">
                    <label for="txt_approved_on">Approved On</label>
                    <input automation_id="txt_approved_on"
                        class="form-control"
                        type="text"
                        :value="formatDate(item.approvedOn)"
                        :disabled="readOnly"
                        />
                </div>
            </div>
        </fieldset>
    </div>
</template>

<script>
    import { mapGetters, mapState } from "vuex";
    import DateTimeHelper from "@/shared/utilities/DateTimeHelper";
    import { requiredIf } from '@vuelidate/validators';
    import { useVuelidate } from "@vuelidate/core";
    import { MoneyMovementType } from '@config/enums';

    export default {
        name: "MoneyMovementApprovalForm",
        props: {
            approving: {type: Boolean, required: true},
            item: {type: Object, required: true},
            readOnly: {type: Boolean, required: false, default: false}
        },
        components: {  },

        setup: () => ({ v$: useVuelidate() }),

        data() {
            return {
                errorMessage: ""
            }
        },
        computed: {
            ...mapGetters([
                "lookupHelpers",
                "lookupItems"
            ]),
            ...mapState({
                systemDefaults: state => state.system.systemDefaults,
                user: state => state.authentication.session.user,
                order: state => state.orders.order,
                contacts: state => state.orders.contacts.contacts,
            }),
            debtorContact() {return _.find(this.contacts, ["rolesID", this.item.debtorRolesID]) || {};},
            hasDebtorContact() {return !_.isEmpty(this.debtorContact);},
            effectiveLineAmount() {return _.parseNumber(this.item.amount, 0) - _.parseNumber(this.item.totalAdjustedAmount, 0) ;},
            priorAdjustmentAmount() {return _.parseNumber(this.item.totalAdjustedAmount, 0) - (_.parseNumber(this.item.amount, 0) - _.parseNumber(this.item.requestedAmount, 0) );},
            lineAdjustmentLabel() {return _.parseNumber(this.item.totalAdjustedAmount, 0) > 0 ? "Effective" : "Adjusted";},
            lineAdjustmentToolTip() {return _.parseNumber(this.item.totalAdjustedAmount, 0) > 0 ? `Includes prior adjustments of ${accounting.formatMoney(this.priorAdjustmentAmount)}` : "";},
            isAdjustment() {return _.isEqual(this.item.moneyMovementTypeID, MoneyMovementType.ConsolidatedDisbursementAdjustment)},
            isLossAdvance() {return _.includes([MoneyMovementType.Advance, MoneyMovementType.Loss], this.item.moneyMovementTypeID)},
            isCheck() {return _.includes([MoneyMovementType.Advance, MoneyMovementType.Disbursement, MoneyMovementType.Loss], this.item.moneyMovementTypeID)},
            moneyMovementType() { return MoneyMovementType.displayValue(this.item.moneyMovementTypeID); },
            showComments() {return !this.readOnly || _.size(this.item.approversComment) > 0;},
            showAdjustmentTooltip() {return _.parseNumber(this.item.totalAdjustedAmount, 0) > 0;}
        },
        validations() {
            const self = this;
            var result = {
                item: {
                    approversComment: {
                        required: requiredIf(function(){
                            return !self.approving;
                        }),
                    },
                }
            }
            return result;
        },
        methods: {
            formatDate(date){
                return DateTimeHelper.toLocalTimeString(date, "MM/dd/yyyy hh:mm a");
            },
            save(){
                const self = this;
                self.errorMessage = "";
                self.v$.$touch();
                if (self.v$.$error) { return Promise.reject({errorMessage: 'Please provide all required fields and correct errors.'}); }

                let apiPromise = self.$api.MoneyMovementApprovalsApi.submitMoneyMovementApproval(self.user.usersID, self.approving, [self.item]);
                return self.$rqBusy.wait(apiPromise);
            },
            saveRecovery(){
                const self = this;
                let apiPromise = self.$api.CheckWritingApi.saveLossAdvanceRecovery(self.item);
                return self.$rqBusy.wait(apiPromise);
            },
        }
    }
</script>

<template>
    <div id="consolidated-checks-container" class="content-wrapper escrow-browser">
        <rq-banner
            ref="errorBanner"
            :message="errorMessage"
            variant="error"
            icon="fas fa-exclamation-triangle"
            :visible="errorMessage.length > 0"
        />
        <rq-page-section title="Search Consolidated Checks" headerSize="lg" class="browser-filter-section" v-model:expanded="filtersExpanded" @keyup.enter="onSearch" collapsible>
            <template #header-actions>
                <transition name="simple-fade">
                    <ul v-if="!filtersExpanded" class="nav browser-filter-display">
                        <li class="nav-item" v-if="bankFilterDisplay != 'All'">
                            <div class="filter-name">Escrow Account:</div>
                            <div class="filter-value">{{bankFilterDisplay}}</div>
                        </li>
                        <li class="nav-item" v-if="payeeFilterDisplay != 'All'" v-rq-tooltip.html.hover.top :title="payeeFilterTooltip">
                            <div class="filter-name">Payee:</div>
                            <div class="filter-value">{{payeeFilterDisplay}}</div>
                        </li>
                        <li class="nav-item" v-if="dateFilterDisplay.length > 0">
                            <div class="filter-name">Date:</div>
                            <div class="filter-value">{{dateFilterDisplay}}</div>
                        </li>
                        <li class="nav-item" v-if="hasActiveFilter">
                            <b-btn
                                variant="link"
                                class="btn-theme"
                                @click="onClearSearch">Clear All Filters
                            </b-btn>
                        </li>
                    </ul>
                </transition>
                <transition name="simple-fade">
                    <ul v-if="filtersExpanded" class="nav ms-auto">
                        <li class="nav-item">
                            <b-btn
                                automation_id="btn_clear"
                                variant="theme"
                                @click="onClearSearch">Clear</b-btn>
                        </li>
                        <li class="nav-item">
                            <b-btn
                                automation_id="btn_search"
                                variant="theme"
                                @click="onSearch">Search</b-btn>
                        </li>
                    </ul>
                </transition>
            </template>
            <div class="row">
                <div :class="{ 'col col-4 col-xl-4 form-group form-required':true, 'has-error':v$.request.bankCompanyID.$error }">
                    <label for="dtp_escrow_bank">Escrow Account</label>
                    <dx-select-box
                        :input-attr="{ automation_id: 'dtp_escrow_bank', id: 'dtp_escrow_bank' }"
                        :data-source="escrowAccountDataSource"
                        value-expr="id"
                        display-expr="name"
                        v-model="v$.request.bankCompanyID.$model"
                        placeholder="Select Escrow Account..."
                        :search-enabled="true"
                        :show-clear-button="true"
                        :grouped="true"
                        @valueChanged="onChangeEscrowAccount"
                    />
                    <rq-validation-feedback
                        :messages="{ 'Escrow Account is required': v$.request.bankCompanyID.$error }"
                    />
                </div>
                <div class="col col-4 col-xl-4 form-group">
                    <label for="dtp_payee">Payee</label>
                    <dx-tag-box
                        automation_id="dtp_payee"
                        ref="dtp_payee"
                        class="form-control"
                        :data-source="consolidatedPayeeDataSource"
                        display-expr="name"
                        value-expr="id"
                        :search-enabled="true"
                        :show-selection-controls="true"
                        :show-clear-button="true"
                        :max-displayed-tags="1"
                        :show-drop-down-button="true"
                        :grouped="true"
                        placeholder="All Payees..."
                        apply-value-mode="useButtons"
                        v-model="request.payeeCompanyIDs"
                    />
                </div>
                <div class="col col-4 col-xl-4 form-group">
                    <label for="txt_date_range">Date Range</label>
                    <rq-date-range
                        id="txt_date_range"
                        v-model:start-date="request.dateFrom"
                        v-model:end-date="request.dateTo"
                        match-value="before-clear"
                        match-field="end"
                        format="MM/dd/yyyy"
                        type="date"
                        show-calendar-drop-downs
                        show-clear-buttons
                        no-calendars
                        borderless
                    />
                </div>
            </div>
        </rq-page-section>
        <rq-banner
            ref="pendingBanner"
            :message="pendingMessage"
            variant="warn"
            icon="fas fa-exclamation-triangle"
            dismissable
            :visible="pendingMessage.length > 0"
        />
        <rq-tabs
            :tabs="tabItems"
            v-model="tabIndex"
            @click="onTabActivated"
            >
            <template #pendingTitle>
                <consolidated-checks-pending-grid
                    ref="pendingList"
                    :items="checks"
                    :reportOptions="reportOptions"
                    :printOptionDisabled="hasFilterChanged || (consolidatedChecks.length === 0 && checks.length ===0)"
                    @update-error-message="onComponentError"
                    @update-data="onUpdateData"
                ></consolidated-checks-pending-grid>
            </template>
            <template #processingTitle>
                <consolidated-checks-grid
                    ref="processingList"
                    :items="activeChecks"
                    :checks="checks"
                    :processing="true"
                    :lastFinalizedReconDate="lastFinalizedReconDate"
                    :reportOptions="reportOptions"
                    :printOptionDisabled="hasFilterChanged || consolidatedChecks.length === 0"
                    @update-error-message="onComponentError"
                    @update-data="onUpdateData"
                    @edit-memo="onEditMemo"
                ></consolidated-checks-grid>
            </template>
            <template #allTitle>
                <consolidated-checks-grid
                    ref="allList"
                    :items="consolidatedChecks"
                    :checks="checks"
                    :processing="false"
                    :lastFinalizedReconDate="lastFinalizedReconDate"
                    :reportOptions="reportOptions"
                    :printOptionDisabled="hasFilterChanged || consolidatedChecks.length === 0"
                    @update-error-message="onComponentError"
                    @update-data="onUpdateData"
                    @edit-memo="onEditMemo"
                ></consolidated-checks-grid>
             </template>
        </rq-tabs>
        <address-detail-popover
            title="Payee Information"
            :target="popover.target"
            container="consolidated-checks-container"
            placement="right"
            :display-number="popover.item.payeeCompanyID"
            :name="popover.item.payeeName"
            :address1="popover.item.payeeAddress1"
            :address2="popover.item.payeeAddress2"
            :city="popover.item.payeeCity"
            :state="popover.item.payeeState"
            :zip="popover.item.payeeZip"
            v-model:visible="popover.visible"
        />
        <grid-popover
            container="#consolidated-check-grid"
            :popover="gridPopover"
            v-model:visible="gridPopover.visible"
        />
        <grid-popover
            container="#consolidated-check-grid-processing"
            :popover="gridPopover"
            v-model:visible="gridPopover.visible"
        />
    </div>
</template>

<script>
    import { mapState, mapGetters } from "vuex";
    import AddressDetailPopover from "@/shared/components/rq/AddressDetailPopover";
    import GridPopover from "@/shared/components/rq/GridPopover";
    import { ReportOptionsDto } from "@reporting/exago-reports/report-models";
    import { required } from '@vuelidate/validators';
    import { useVuelidate } from "@vuelidate/core";
    import { CheckStatus } from "@check-writing/enums";
    import DataSource from 'devextreme/data/data_source';
    import ConsolidatedChecksGrid from "../components/ConsolidatedChecksGrid";
    import ConsolidatedChecksPendingGrid from "../components/ConsolidatedChecksPendingGrid";
    import MemoDialog from "@config/shared/NoteDialog.vue";
    import { ConsolidatedCheckDto, ConsolidatedCheckSelectCheckDto, ConsolidatedCheckSearchRequestDto } from "../models";
    import { SYSTEM_MUTATIONS } from "@/store/mutations";

    export default {
        name:"ConsolidatedChecks",
        components: { AddressDetailPopover, ConsolidatedChecksGrid, ConsolidatedChecksPendingGrid, GridPopover },
        setup: () => ({ v$: useVuelidate() }),
        data () {
            return {
                consolidatedChecks: [],
                request: new ConsolidatedCheckSearchRequestDto(),
                consolidatedPayees: [],
                escrowAccounts: [],
                checks: [],
                tabIndex: 0,
                currentTabIndex: 0,
                filtersExpanded: true,
                searched: false,
                errorMessage: '',
                escrowAccountDataSource: {},
                lastFinalizedReconDate: '1/1/1900',
                popover: {
                    visible: false,
                    target: null,
                    item: {}
                },
                gridPopover: {
                    visible: false,
                    target: null,
                    gridConfig: {},
                    items: [],
                    title: null
                },
            };
        },

        computed: {
            ...mapGetters([
                "lookupHelpers",
                "lookupItems"
            ]),
            ...mapState({
                user: state => state.authentication.session.user,
                activeRequest: state => state.system.consolidatedChecksSearch,
            }),
            containerID(){
                return this.processing ? '#consolidated-check-grid-processing' : '#consolidated-check-grid';
            },
            localSecurity(){
                return this.securitySettings.findValues([
                    "AllowChangePayee",
                    "AllowCheckStop",
                    "AllowCheckVoid",
                    "AllowDeleteCheck",
                    "AllowDeleteCheckFromEscrowAccounting",
                    "AllowPrintChecks",
                    "AllowUndoCheck",
                    "AllowVoidTransfers",
                    "CanAddChecks",
                    "VoidCheckDateDays"
                ]);
            },
            tabItems() {
                const self = this;
                return [
                    { automation_id: "btn_tab_pendingTitle", name: "pendingTitle", title: self.pendingTitle },
                    { automation_id: "btn_tab_processingTitle", name: "processingTitle", title: self.processingTitle},
                    { automation_id: "btn_tab_allTitle", name: "allTitle", title: self.allTitle},
                ];
            },
            activeChecks(){
                return _.filter(this.consolidatedChecks, c => _.includes([CheckStatus.Hold, CheckStatus.None], c.checkStatus)) || [];
            },
            pendingTitle(){
                return `Pending (${this.checks.length})`;
            },
            processingTitle(){
                return `Processing (${this.activeChecks.length})`;
            },
            allTitle(){
                return `All (${this.consolidatedChecks.length})`;
            },
            bankFilterDisplay(){
                return _.isNil(this.activeRequest.bankCompanyID) ? "All" : this.lookupHelpers.getLookupItemName(this.lookupItems.ESCROW_ACCOUNTS, this.activeRequest.bankCompanyID);
            },
            consolidatedPayeeDataSource(){
                let items = this.consolidatedPayees;
                if (!_.isNil(this.request.bankCompanyID)) {
                    items = _.filter(this.consolidatedPayees, i => _.parseNumber(i.data, -1) === _.parseNumber(this.request.bankCompanyID, -1));
                }

                return  new DataSource({
                    store: items,
                    key: 'id',
                    group: 'additionalIdentity'
                });
            },
            hasFilter(){
                return !_.isEqual(new ConsolidatedCheckSearchRequestDto(this.request), new ConsolidatedCheckSearchRequestDto());
            },
            hasActiveFilter(){
                return !_.isEqual(new ConsolidatedCheckSearchRequestDto(this.activeRequest), new ConsolidatedCheckSearchRequestDto());
            },
            hasFilterChanged(){
                return !_.isEqual(new ConsolidatedCheckSearchRequestDto(this.activeRequest), new ConsolidatedCheckSearchRequestDto(this.request));
            },
            hasValidFilter(){
                return !_.isNil(this.request.bankCompanyID);
            },
            dateFilterDisplay(){
                if (_.isNil(_.get(this, "activeRequest.dateFrom")) && _.isNil(_.get(this, "activeRequest.dateTo"))) {
                    return "";
                }
                return `${this.getDate(this.activeRequest.dateFrom, "Anything")} to ${this.getDate(this.activeRequest.dateTo, "Anything")}`;
            },
            payeeFilterDisplay(){
                let itemNames = _.map(this.activeRequest.payeeCompanyIDs, id => _.find(this.consolidatedPayees, { id }).name);
                if (_.size(itemNames) == 0) return "All";
                if (_.size(itemNames) > 2) return `${itemNames.length} Selected`;
                return _.join(_.sortBy(itemNames, ['name']), ", ");
            },
            payeeFilterTooltip(){
                let itemNames = _.map(this.activeRequest.payeeCompanyIDs, id => _.find(this.consolidatedPayees, { id }).name);
                if (_.size(itemNames) == 0) return "";
                return _.join(_.sortBy(itemNames, ['name']), ", ");
            },
            userBankCompanyID(){
                let bankCompanyID = _.getNumber(this.lookupHelpers.getBranch(this.user.branchID), "bankCompanyID", 0);
                return bankCompanyID == 0 ? null : bankCompanyID;
            },
            reportOptions() {
                let reportOptions =
                    new ReportOptionsDto({
                        reportPath: "System Reports\\File Specific\\Consolidated Checks",
                        title: "Consolidated Checks",
                        disabled: true,
                        immediate: true,
                        parameters: {
                            p_CompanyIDs:           this.request.payeeCompanyIDs.length === 0 ? '' : _.join(this.request.payeeCompanyIDs, ','),
                            p_CheckStatuses:        '',
                            p_Banks:                this.request.bankCompanyID || null,
                            p_StartDate:            this.request.dateFrom,
                            p_EndDate:              this.request.dateTo,
                        }
                });

                return reportOptions;
            },
            pendingMessage(){
                return this.checks.length == 0 ? "" : `${this.checks.length} Items Pending for Payment`;
            },
        },

        created() {
            this.initNonReactiveVariables();
            this.initSearch(this.activeRequest);
            this.$events.on("show-popover", this.onShowPopover);
            this.$events.on("show-grid-popover", this.onShowGridPopover);
            this.$events.on("reload-data", this.fetchData);
            this.$events.on("undo-checks", this.onUndoChecks);
        },

        beforeUnmount () {
            this.$events.off("show-popover", this.onShowPopover);
            this.$events.off("show-grid-popover", this.onShowGridPopover);
            this.$events.off("reload-data", this.fetchData);
            this.$events.off("undo-checks", this.onUndoChecks);
        },

        validations: () => ({
            request: {
                bankCompanyID: {
                    required
                }
            }
        }),

        methods: {
            createDropdownGroup(items) {
                _.each(items, i => {
                    i.additionalIdentity = i.inactive ? 'Inactive' : 'Active';
                });
                return items;
            },

            getActiveGridName() {
                switch (this.tabIndex) {
                    case 0:
                        return 'pendingList';
                    case 1:
                        return 'processingList';
                    case 2:
                        return 'allList';
                }
            },

            getDate(dateStr, nullValue = null) {
                return _.isNullOrEmpty(dateStr) ? nullValue : dateStr;
            },

            initNonReactiveVariables() {
                const self = this;
                self.consolidatedPayees = self.createDropdownGroup(self.lookupHelpers.getAllLookupItems(self.lookupItems.AUTO_CONSOLIDATE_PAYEES, self.user.regionID));
                self.escrowAccounts = self.createDropdownGroup(self.lookupHelpers.getAllLookupItems(self.lookupItems.USER_ESCROW_ACCOUNTS));
                self.escrowAccountDataSource = new DataSource({
                    store: self.escrowAccounts,
                    key: 'id',
                    group: 'additionalIdentity',
                });
                self.request.bankCompanyID = self.userBankCompanyID;
            },

            fetchData(tabIndex = -1) {
                const self = this;
                let idx = _.parseNumber(tabIndex, 0);
                if (idx > -1) {
                    self.tabIndex = idx;
                    self.currentTabIndex = tabIndex;
                }
                self.errorMessage = '';
                let apiPromise = self.$api.ConsolidatedChecksApi.getConsolidatedCheckData(self.request);
                self.$rqBusy.wait(apiPromise)
                    .then(data => {
                        let consolidatedChecks = _.map(data.checks, item => new ConsolidatedCheckDto(item));
                        let checks = _.map(data.unassignedChecks, item => new ConsolidatedCheckSelectCheckDto(item));
                        self.lastFinalizedReconDate = data.lastFinalizedReconDate;
                        self.onUpdateData({consolidatedChecks, checks});
                        self.$store.commit(SYSTEM_MUTATIONS.SET_CONSOLIDATED_CHECKS_SEARCH, new ConsolidatedCheckSearchRequestDto(self.request));
                    })
                    .catch(error => {
                        console.error(error);
                        self.$toast.error({ message: `Error loading Consolidated Checks.` });
                    });
            },

            onComponentError(errorMessage) {
                this.errorMessage = errorMessage;
            },

            onEditMemo(e) {
                this.showMemoDialog(e.consolidatedCheckID);
            },

            onTabActivated(e) {
                const self = this;
                if(self.tabIndex === self.currentTabIndex)
                    return;

                self.currentTabIndex = self.tabIndex;
                let name = _.camelCase(`${this.tabItems[this.tabIndex].title.split(' ')[0]}List`);
                self.$nextTick(()=> {
                    self.refresh(name);
                });
                if (self.popover.visible) self.onShowPopover({visible: false, target: null, item: {}});
                if (self.gridPopover.visible) self.onShowGridPopover({visible: false, gridConfig: {}, target: null, items: [], title: null});
            },

            onUpdateData(data) {
                const self = this;
                self.consolidatedChecks = data.consolidatedChecks || [];
                self.checks = data.checks || [];
                if (!self.searched) {
                    self.searched = true;
                    self.tabIndex = _.size(self.checks) == 0 ? (_.size(self.activeChecks) > 0 ? 1 : 2) : 0;
                    self.currentTabIndex = self.tabIndex;
                }
                self.refresh(self.getActiveGridName());
            },

            refresh(name, clear = true) {
                let grid = _.get(this.$refs, name);
                let hasRefresh = _.isFunction(_.get(grid, 'refresh'));
                if (grid && hasRefresh) {
                    grid.refresh(clear);
                }
                this.popover.visible = false;
            },

            onChangeEscrowAccount(e) {
                this.request.payeeCompanyIDs = [];
                this.searched = false;
            },

            onSearch() {
                const self = this;
                self.errorMessage = '';
                self.v$.$touch();
                if (self.v$.$error) { return self.errorMessage = 'Please provide all required fields.'; }
                self.fetchData();
            },

            onClearSearch() {
                this.errorMessage = '';
                this.filtersExpanded = true;
                this.request = new ConsolidatedCheckSearchRequestDto();
                this.request.bankCompanyID = self.userBankCompanyID;
                this.request.payeeCompanyIDs = ""
                this.$store.commit(SYSTEM_MUTATIONS.SET_CONSOLIDATED_CHECKS_SEARCH, null);
                this.checks = [];
                this.consolidatedChecks = [];
                this.refresh(this.getActiveGridName());
            },

            onShowGridPopover(data) {
                this.gridPopover = data;
            },

            onShowPopover(data) {
                this.popover = _.assign({}, this.popover, data);
            },

            onUndoChecks(checkIDs) {
                const self = this;
                //the doc viewer uses checksID not consolidatedCheckID so we'll have to translate first
                //we also notified them in doc viewer to confirm undo, so we'll bypass confirmAction()
                let items = _.filter(self.consolidatedChecks, c => _.includes(checkIDs, c.checksID));
                if(_.isEmpty(items)) return;
                let ids = _.map(items, "consolidatedCheckID");
                let apiPromise = self.$api.ConsolidatedChecksApi.undoChecks(ids);
                self.$rqBusy.wait(apiPromise)
                    .then(result => {
                        self.fetchData();
                    }).catch(error => {
                        self.$toast.error({ message: `${error.errorMessage}` });
                    });
            },

            initSearch(storeRequest) {
                const self = this;

                if (!_.isNil(storeRequest)) {
                    self.request = _.clone(self.activeRequest);
                    self.fetchData();
                }
                else{
                    self.request.bankCompanyID = self.userBankCompanyID;
                }
            },

            saveMemo(id, item) {
                const self = this;
                let apiPromise = self.$api.ConsolidatedChecksApi.saveCheckMemo(id, item);
                return self.$rqBusy.wait(apiPromise);
            },

            showMemoDialog(consolidatedCheckID) {
                const self = this;
                let item = _.find(self.consolidatedChecks, {consolidatedCheckID: consolidatedCheckID} );
                let onOk = (e) => {
                    let memo = e.component.localNotes || null;
                    item.memo = memo;
                    self.saveMemo(item)
                        .then(result => {
                            self.$toast.success({ message: `Consolidated Check Memo was saved.` });
                        }).catch(error => {
                            self.errorMessage = error.errorMessage;
                            self.$toast.error({ message: `Error saving Consolidated Check Memo.` });
                        })
                        .finally(() => {
                            self.refresh(self.getActiveGridName());
                        });
                    return true;
                };
                let title = 'Consolidated Check Memo';
                self.$dialog.open({
                    title: title,
                    height: "auto",
                    width: "600",
                    scrollable: false,
                    component: MemoDialog,
                    props: {
                        notes: item.memo,
                        maxlength: 200,
                        rows: 3
                    },
                    onOk: onOk
                });
            },
        }
    }
</script>

<template>
    <rqdx-action-data-grid
        ref="dataGrid"
        :title="title"
        title-size="sm"
        no-data-text="No checks found"
        :config="gridConfig"
        :data-source="gridData"
        @mouseout="payeePopoverVisible=false"
        @rowDoubleClick="$emit('row-double-click', $event)"
        @selectionChanged="onGridSelectionChanged"
        hide-show-column-chooser
        integrated-search
        hide-export
        rq-filters>
        <address-detail-popover
            title="Payee Information"
            :target="payeePopoverTarget"
            :container="popoverContainer"
            :placement="popoverPlacement"
            :display-number="payeePopoverDetail.payeeCompanyID"
            :name="payeePopoverDetail.payeeName"
            :address1="payeePopoverDetail.payeeAddress1"
            :address2="payeePopoverDetail.payeeAddress2"
            :city="payeePopoverDetail.payeeCity"
            :state="payeePopoverDetail.payeeState"
            :zip="payeePopoverDetail.payeeZip"
            v-model:visible="payeePopoverVisible"
        />
    </rqdx-action-data-grid>
</template>
<script>
    import { mapState, mapGetters } from "vuex";
    import DxGridUtils from "@/shared/utilities/DxGridUtils";
    import GridInvokerMixin from "@/shared/mixins/GridInvokerMixin";
    import { ConsolidatedCheckSelectCheckDto } from "../models";
    import { CheckStatus } from "@/modules/file/check-writing/enums";

    import { CompanyPicker } from '@/shared/components/rq';
    import AddressDetailPopover from "@/shared/components/rq/AddressDetailPopover";
    export default {
        name: "ConsolidatedCheckSelectionGrid",
        mixins: [ GridInvokerMixin({ grid: "dataGrid" }) ],
        props: {
            title: { type: String, default: "" },
            gridData: { type: Array, default: () => [] },
            popoverContainer: { type: [String,HTMLElement], default: "consolidated-checks-container" },
            popoverPlacement: { type: String, default: "right" },
            summaryMode: { type: String, default: "all" }
        },
        components: { AddressDetailPopover },
        data() {
            const self = this;
            return {
                payeePopoverTarget: null,
                payeePopoverDetail: {},
                payeePopoverVisible: false,
                totalAmount: 0
            }
        },
        computed: {
            ...mapGetters([ "lookupHelpers", "lookupItems" ]),
            escrowAccounts() { return this.lookupHelpers.getLookupItems(this.lookupItems.ESCROW_ACCOUNTS); },
            isSelectedSummaryMode() { return this.summaryMode === "selected"; },
            summaryLabel() {
                let labelParts = [ _.isEmpty(this.title) ? "Checks" : this.title, "Total" ];
                if(this.isSelectedSummaryMode) labelParts.unshift("Selected");
                return _.toUpper(_.joinParts(labelParts, " "));
            }
        },
        watch: {
            gridData: {
                handler(newValue, oldValue) {
                    if(this.initialized)
                        this.refresh();
                    else
                        this.initGridConfig();

                    if(this.isSelectedSummaryMode) return;

                    this.totalAmount = _.sumBy(newValue, "amount");
                },
                immediate: true
            }
        },
        methods: {
            initGridConfig() {
                const self = this;
                self.gridConfig = {
                    keyExpr: "checksID",
                    columns: [
                        {
                            dataField: "bankName",
                            dataType: "string",
                            caption: "Bank",
                            width: 200,
                            allowFiltering: false
                        },
                        {
                            dataField: "payeeName",
                            dataType: "string",
                            cellTemplate: DxGridUtils.infoIconCellTemplate({
                                handlers:{
                                    mouseover(cellElement, cellInfo, e) {
                                        self.updatePayeePopover(cellInfo.data, e.target);
                                    },
                                    mouseout(cellElement, cellInfo, e) {
                                        self.updatePayeePopover();
                                    }
                                }
                            })
                        },
                        {
                            dataField: "amount",
                            dataType: "number",
                            format: { type: "currency", precision: 2 }
                        },
                        {
                            dataField: "gfNo",
                            dataType: "string",
                            caption: "Original File Number",
                            width: 200
                        },
                        {
                            dataField: "transferredToOrder",
                            dataType: "string",
                            caption: "File Number",
                            width: 200
                        }
                    ],
                    summary: {
                        totalItems: [
                            { name: "TotalChecksLabel", column: 0, alignment: "left", customizeText: () => self.summaryLabel },
                            { name: "TotalChecksAmount", column: "amount", alignment: "right", customizeText: () => accounting.formatMoney(self.totalAmount) }
                        ]
                    },
                    allowColumnReordering: false
                };
                self.initialized = true;
            },

            updatePayeePopover(item=null, target=null) {
                const self = this;

                self.payeePopoverTarget = null;
                self.payeePopoverVisible = false;

                if(_.isNil(target) || _.isNil(item)) return;

                self.$nextTick(()=>{
                    self.payeePopoverDetail = _.pick(item, [
                        "payeeCompanyID",
                        "payeeName",
                        "payeeAddress1",
                        "payeeAddress2",
                        "payeeCity",
                        "payeeState",
                        "payeeZip"
                    ]);
                    self.payeePopoverTarget = target;
                    self.payeePopoverVisible = true;
                });
            },

            updateDimensions() {
                this.invokeGridMethod("updateDimensions");
            },

            clearSelection() {
                this.invokeGridMethod("clearSelection");
                this.invokeGridMethod("option", "focusedRowIndex", -1);
            },

            refresh() {
                this.invokeGridMethod("refresh");
            },

            onGridSelectionChanged(e) {
                this.$emit("selectionChanged", e);
                if(!this.isSelectedSummaryMode) return;
                this.totalAmount = _.sumBy(e.selectedRowsData, "amount");
                this.$nextTick(() => {
                    e.component.repaint();
                });
            }
        }
    }
</script>
<template>
    <section class="summary-section">
        <div :class="classAttr" tabindex="0" @click="onExpandSummary">
            <span class="summary-title">{{title}}</span>
            <button v-if="reconciliation.isOutOfSync" type="button" class="btn btn-icon" tabindex="-1" v-rq-tooltip.top.hover title="Sync Changes" @click="onSync">
                <FontAwesomeLayers class="fa-lg">
                    <FontAwesomeIcon icon="fas fa-circle" fixed-width />
                    <FontAwesomeIcon icon="far fa-bell-on" transform="shrink-6" class="text-danger" fixed-width />
                </FontAwesomeLayers>
            </button>
            <button type="button" class="btn btn-icon btn-summary-toggle" tabindex="-1" v-rq-tooltip="expanded ? 'Collapse Summary' : 'Expand Summary'">
                <FontAwesomeIcon icon="fas fa-caret-down" :rotation="iconRotation"/>
            </button>
        </div>
        <b-collapse id="reconciliation_summary" v-model="expanded">
            <div class="summary-pane">
                <div class="row">
                    <div class="col col-4">
                        <div class="summary-pane-label">Cleared Receipts:</div>
                        <div class="summary-pane-value">{{formatMoney(reconciliation.receipts)}}</div>
                    </div>
                    <div class="col col-4">
                        <div class="summary-pane-label">Cleared Disbursements:</div>
                        <div class="summary-pane-value">{{formatMoney(reconciliation.disbursements)}}</div>
                    </div>
                    <div class="col col-4">
                        <div class="summary-pane-label">Beginning Balance:</div>
                        <div class="summary-pane-value">{{formatMoney(reconciliation.beginningBalance)}}</div>
                    </div>
                </div>
                <div class="row">
                    <div class="col col-4">
                        <div class="summary-pane-label">Other Receipts:</div>
                        <div class="summary-pane-value">{{formatMoney(reconciliation.miscCredits)}}</div>
                    </div>
                    <div class="col col-4">
                        <div class="summary-pane-label">Other Disbursements:</div>
                        <div class="summary-pane-value">{{formatMoney(reconciliation.miscCharges)}}</div>
                    </div>
                    <div class="col col-4">
                        <div class="summary-pane-label">Bank Stmt Ending Balance:</div>
                        <div class="summary-pane-value">{{formatMoney(reconciliation.workingEndBal)}}</div>
                    </div>
                </div>
                <div class="row">
                    <div class="col col-4">
                        <div class="summary-pane-label">Total Receipts:</div>
                        <div class="summary-pane-value">{{formatMoney(reconciliation.totalReceipts)}}</div>
                    </div>
                    <div class="col col-4">
                        <div class="summary-pane-label">Total Disbursements:</div>
                        <div class="summary-pane-value">{{formatMoney(reconciliation.totalDisbursements)}}</div>
                    </div>
                    <div class="col col-4">
                        <div class="summary-pane-label">Recon Ending Balance:</div>
                        <div class="summary-pane-value">{{formatMoney(reconciliation.endingBalance)}}</div>
                    </div>
                </div>
            </div>
        </b-collapse>
        <div class="row summary-item-row">
            <div class="col col-4">
                <div class="summary-item-panel">
                    <div class="summary-item-panel-label">Book Balance</div>
                    <div class="summary-item-panel-value">{{formatMoney(reconciliation.bookBalance)}}</div>
                </div>
            </div>
            <div class="col col-4">
                <div class="summary-item-panel">
                    <div class="summary-item-panel-label">Escrow Trial Balance</div>
                    <div class="summary-item-panel-value">{{formatMoney(reconciliation.escrowTrialBalance)}}</div>
                </div>
            </div>
            <div class="col col-4">
                <div :class="panelClassAttr">
                    <div class="summary-item-panel-label"><span v-if="reconciliation.hasAdjustments">Adj </span>Reconciled Bank Balance</div>
                    <div class="summary-item-panel-value">{{formatMoney(reconciliation.adjustedReconciledBankBalance)}}</div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    import { ReconciliationDto } from '../models';

    export default {
        name: "ReconciliationSummary",

        props: {
            reconciliation: { type: Object, required: true, default: () => new ReconciliationDto() },
            readOnly: { type: Boolean, required: true, default: false },
        },

        data() {
            return {
                expanded: true,
            };
        },

        computed: {
            adjustedReconciledBankBalanceIsBalanced() {
                return _.isEqual(this.formatMoney(this.reconciliation.adjustedReconciledBankBalance), this.formatMoney(this.reconciliation.bookBalance)) && _.isEqual(this.formatMoney(this.reconciliation.adjustedReconciledBankBalance), this.formatMoney(this.reconciliation.escrowTrialBalance));
            },
            hasAdjustments() {
                return this.reconciliation.hasAdjustments;
            },
            colorAttr() {
                return this.reconciliation.difference == 0 ? ['balanced'] : ['not-balanced'];
            },
            classAttr() {
                return ['summary-header', (this.reconciliation.difference == 0 && !this.reconciliation.isOutOfSync) ? 'balanced' : 'not-balanced'];
            },
            panelClassAttr() {
                return ['summary-item-panel', this.adjustedReconciledBankBalanceIsBalanced ? 'balanced' : 'not-balanced'];
            },
            title() {
                return `Difference: ${this.formatMoney(this.reconciliation.difference)}${this.reconciliation.completed ? ' - Finalized' : ''}`;
            },
            iconRotation() {
                if(this.expanded){ return 0;}
                return 90;
            }
        },

        watch:{
            reconciliation(newValue, oldValue) {
                if(_.isEqual(newValue, oldValue)) return;
            },
        },

        methods: {
            //RQO-31880 - removed, since it doesn't appear to be used
            // formatDate(v) { return moment(v).format('MM/DD/YYYY'); },

            formatMoney(v) { return accounting.formatMoney(_.parseNumber(v, 0), { format: { pos:"%s%v", neg:"(%s%v)", zero:"%s%v" } }); },

            onExpandSummary() {
                this.expanded = !this.expanded;
            },

            onSync(e) {
                e.stopPropagation();
                this.$emit("refresh");
            },
        }
    };
</script>

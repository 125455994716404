<template>
    <div class="content-wrapper escrow-browser">
        <rq-banner
            variant="error"
            icon="fas fa-exclamation-triangle"
            :message="errorMessage"
            :visible="errorMessage.length > 0"
            @dismiss="errorMessage=''"
            dismissable
        />
        <rq-page-section title="Search Expected Wires Out" headerSize="lg" class="browser-filter-section" v-model:expanded="filtersExpanded" @keyup.enter="onSearch" collapsible>
            <template #header-actions>
                <transition name="simple-fade">
                    <ul v-if="!filtersExpanded" class="nav browser-filter-display">
                        <li class="nav-item" v-if="dateFilterDisplay.length > 0">
                            <div class="filter-name">Date:</div>
                            <div class="filter-value">{{dateFilterDisplay}}</div>
                        </li>
                        <li class="nav-item" v-if="hasActiveFilter">
                            <b-btn
                                variant="link"
                                class="btn-theme"
                                @click="onClearSearch">Clear All Filters
                            </b-btn>
                        </li>
                    </ul>
                </transition>
                <transition name="simple-fade">
                    <ul v-if="filtersExpanded" class="nav ms-auto">
                        <li class="nav-item">
                            <b-btn
                                automation_id="btn_clear_search"
                                variant="theme"
                                @click="onClearSearch">Clear</b-btn>
                        </li>
                        <li class="nav-item">
                            <b-btn
                                automation_id="btn_search"
                                variant="theme"
                                :disabled="errorMessage.length > 0"
                                @click="onSearch">Search</b-btn>
                        </li>
                    </ul>
                </transition>
            </template>
            <div class="row">
                <div class="col col-12 col-lg-8 col-xl-6 col-pc-4 form-group">
                    <label for="txt_check_date_range">Date Range</label>
                    <rq-date-range
                        id="txt_check_date_range"
                        v-model:start-date="request.expectedWireOutDateFrom"
                        v-model:end-date="request.expectedWireOutDateTo"
                        match-value="before-clear"
                        match-field="end"
                        format="MM/dd/yyyy"
                        type="date"
                        show-calendar-drop-downs
                        show-clear-buttons
                        no-calendars
                        borderless
                    />
                </div>
            </div>
        </rq-page-section>
        <rqdx-action-data-grid
            ref="dataGrid"
            automation_id="tbl_expected_wires_out"
            :actions="selectionActions"
            :config="gridConfig"
            class="grid-container"
            title="Expected Wires Out"
            title-size="sm"
            :data-source="gridDataSource"
            @accept="onAcceptItem"
            @edit="onEditItem"
            @delete="onDeleteItem"
            @rowDoubleClick="onEditItem"
            export-file-name="expected_wires_out_data"
            integrated-search
            hide-show-column-chooser
            rq-filters>
            <template #toolbar>
                <ul class="nav">
                    <li class="nav-item">
                        <rq-report-button
                            text="View Report"
                            :disabled="readOnly || items.length == 0 || hasFilterChanged"
                            :path="reportOptions.path"
                            :name="reportOptions.title"
                            :report-options="reportOptions"
                        />
                    </li>
                </ul>
            </template>
        </rqdx-action-data-grid>
    </div>
</template>

<script>
    import { mapState, mapGetters } from "vuex";
    import { DateTime } from "luxon";
    import { ExpectedWireOutDto, ExpectedWireOutSearchRequest }  from "../models";
    import DxGridUtils from "@/shared/utilities/DxGridUtils";
    import CheckWritingWireTransferForm from "../../file/check-writing/components/CheckWritingWireTransferForm";
    import { ReportOptionsDto } from "@reporting/exago-reports/report-models";

    export default {
        name:"ExpectedWiresOutList",
        data () {
            return {
                items: [],
                request: new ExpectedWireOutSearchRequest(),
                activeRequest: new ExpectedWireOutSearchRequest(),
                errorMessage: "",
                filtersExpanded: true,
                selectionActions: [],
            };
        },

        created() {
            this.initNonReactiveVariables();
            this.initGridConfig();
        },

        watch: {
            "request.expectedWireOutDateFrom": {
                handler: function(newValue, oldValue) {
                if(newValue === oldValue) return;
                this.activeRequest.expectedWireOutDateFrom = newValue;
                }
            }
        },

        computed: {
            ...mapGetters([
                "lookupHelpers",
                "lookupItems"
            ]),
            gridInstance() { return _.get(this, "$refs.dataGrid.gridInstance", null); },
            localSecurity() {
                return this.securitySettings.findValues(["IsAdmin", "IsEscrowAdmin", "AllowDeleteCheckFromEscrowAccounting"]);
            },
            hasFilter(){
                return !_.isEqual(new ExpectedWireOutSearchRequest(this.request), new ExpectedWireOutSearchRequest());
            },
            hasActiveFilter(){
                return !_.isEqual(new ExpectedWireOutSearchRequest(this.activeRequest), new ExpectedWireOutSearchRequest());
            },
            hasFilterChanged(){
                return !_.isEqual(new ExpectedWireOutSearchRequest(this.activeRequest), new ExpectedWireOutSearchRequest(this.request));
            },
            dateFilterDisplay(){
                if (_.isNil(_.get(this, "activeRequest.expectedWireOutDateFrom")) && _.isNil(_.get(this, "activeRequest.expectedWireOutDateTo"))) {
                    return "All";
                }
                return `${this.getDate(this.activeRequest.expectedWireOutDateFrom, "Anything")} to ${this.getDate(this.activeRequest.expectedWireOutDateTo, "Anything")}`;
            },
            readOnly() { return !this.localSecurity.IsAdmin && !this.localSecurity.IsEscrowAdmin; },
            reportOptions() {
                let reportOptions =
                    new ReportOptionsDto({
                    text: "Print",
                    reportPath: "System Reports\\File Specific\\Expected Wires Out",
                    title: "Expected Wires Out",
                    disabled: true,
                    immediate: true,
                    parameters: {
                        p_StartDate:            this.request.expectedWireOutDateFrom,
                        p_EndDate:              this.request.expectedWireOutDateTo,
                    },
                });

                return reportOptions;
            },
        },

        methods: {
            acceptWires(keys){
                const self = this;
                if (self.readOnly) return;
                let apiPromise = self.$api.EscrowAccountingApi.acceptWires(keys);
                return self.$rqBusy.wait(apiPromise);
            },

            clear() {
                if(!this.gridInstance) return;
                this.gridInstance.option("focusedRowIndex", -1);
                this.gridInstance.clearSelection();
            },

            fetchData() {
                const self = this;
                let fromDate = _.isNil(self.request.expectedWireOutDateFrom) ? "minDate" : DateTime.fromFormat(self.request.expectedWireOutDateFrom, "MM/dd/yyyy").toFormat('MMddyyyy');
                let toDate = _.isNil(self.request.expectedWireOutDateTo) ? "maxDate" : DateTime.fromFormat(self.request.expectedWireOutDateTo, "MM/dd/yyyy").toFormat('MMddyyyy');
                let apiPromise = self.$api.EscrowAccountingApi.getExpectedWiresOut(fromDate, toDate);
                self.$rqBusy.wait(apiPromise)
                    .then(result => {
                        self.items = _.map(result, i => new ExpectedWireOutDto(i));
                        self.activeRequest = new ExpectedWireOutSearchRequest(self.request, true);
                    })
                    .catch(error => {
                        console.error(error);
                        self.$toast.error({ message: `Error loading Expected Wires Out.` });
                    })
                    .finally(() => {
                        self.refresh();
                    });
            },

            getDate(dateStr, nullValue = null) {
                return _.isNullOrEmpty(dateStr) ? nullValue : dateStr;
            },

            initNonReactiveVariables() {
                const self = this;
                self.itemTypeName = "Expected Wire Out";
                self.itemTypeNamePlural = "Expected Wires Out";
                self.itemKey = "checksID";
                self.escrowBanks = self.lookupHelpers.getLookupItems(self.lookupItems.USER_ESCROW_ACCOUNTS);
                self.escrowOfficers = self.lookupHelpers.getLookupItems(self.lookupItems.ESCROW_OFFICERS);
                self.regions = self.lookupHelpers.getLookupItems(self.lookupItems.REGIONS, 1);
                self.branches = self.lookupHelpers.getLookupItems(self.lookupItems.BRANCHES, 1);
                self.selectionActions = [
                    {
                        name: "accept",
                        text: "Accept",
                        eventName: "accept",
                        requireSelection: true,
                        allowMultiSelection: true,
                        disabled: function(e) {
                            if (_.some(e.data, ['isLocked', true]) || _.some(e.data, ['isEscrowLocked', true])) return "One or more Order is locked or Escrow is locked.";
                            if (self.readOnly) return "Access Restricted";
                            return false;
                        }
                    },
                    {
                        name: "edit",
                        text: "Edit",
                        eventName: "edit",
                        requireSelection: true,
                        tooltip: `Edit Wire Transfer Details`,
                        disabled: function(e) {
                            if (e.data.isLocked || e.data.isEscrowLocked) return "Order is locked or Escrow is locked.";
                            if (self.readOnly) return "Access Restricted";
                            return false;
                        }
                    },
                    {
                        name: "delete",
                        text: "Delete",
                        eventName: "delete",
                        requireSelection: true,
                        allowMultiSelection: true,
                        tooltip: `Delete ${self.itemTypeName}`,
                        disabled: function(e) {
                            if (!_.every(e.data, ['wireOutStatus', 1])) return "One or more Wire cannot be delete because of it's status.";
                            if (e.data.isLocked || e.data.isEscrowLocked) return "Order is locked or Escrow is locked.";
                            if (!self.localSecurity.AllowDeleteCheckFromEscrowAccounting || self.readOnly) return "Access Restricted";
                            return false;
                        }
                    },
                ];
            },

            initGridConfig(){
                const self = this;
                self.gridConfig = {
                    columnHidingEnabled: false,
                    focusedRowEnabled: false,
                    paging: { enabled: true },
                    pager: { showPageSizeSelector: true, allowedPageSizes: [50,100,500], showInfo: true},
                    remoteOperations: { sorting: false, paging: false },
                    height: "100%",
                    columns: [
                        {
                            dataField: self.itemKey,
                            visible: false,
                            allowSearch: false,
                            showInColumnChooser: false
                        },
                        {
                            dataField: "wireNumber",
                            dataType: "string",
                            caption: "Wire #",
                        },
                        {
                            dataField: "gfNo",
                            dataType: "string",
                            caption: "File #",
                        },
                        {
                            dataField: "escrowOfficerStaffID",
                            dataType: "number",
                            caption: "Escrow Officer",
                            lookup: {
                                dataSource: self.escrowOfficers,
                                valueExpr: "id",
                                displayExpr: "name"
                            },
                        },
                        {
                            dataField: "regionID",
                            dataType: "number",
                            caption: "Region",
                            calculateSortValue: DxGridUtils.regionDisplaySortValue,
                            lookup: {
                                dataSource: self.regions,
                                valueExpr: "id",
                                displayExpr: "name"
                            },
                        },
                        {
                            dataField: "branchID",
                            dataType: "number",
                            caption: "Branch",
                            lookup: {
                                dataSource: self.branches,
                                valueExpr: "id",
                                displayExpr: "name"
                            },
                        },
                        {
                            dataField: "bankCompanyID",
                            dataType: "number",
                            caption: "Escrow Account",
                            lookup: {
                                dataSource: self.escrowBanks,
                                valueExpr: "id",
                                displayExpr: "name"
                            },
                        },
                        {
                            dataField: "payee",
                            dataType: "string",
                            caption: "Payee",
                        },
                        {
                            dataField: "amount",
                            dataType: "number",
                            format: {
                                type: "currency",
                                precision: 2
                            },
                        },
                        {
                            dataField: "reference",
                            dataType: "string",
                            caption: "Description",
                        },
                        {
                            dataField: "wireOutRequestor",
                            dataType: "string",
                            caption: "Requestor",
                        },
                        DxGridUtils.dateColumn({
                            dataField: "requestedDate",
                        }),
                        DxGridUtils.dateColumn({
                            dataField: "expectedWireOutDate",
                            sortIndex: 0,
                            sortOrder: "asc",
                        }),
                        {
                            dataField: "wireOutApprover",
                            dataType: "string",
                            caption: "Approver",
                        },
                        DxGridUtils.dateColumn({
                            dataField: "checkDate",
                        }),
                        {
                            dataField: "isLocked",
                            dataType: "boolean",
                            cellTemplate: DxGridUtils.boolCellTemplate,
                        },
                        {
                            dataField: "isEscrowLocked",
                            dataType: "boolean",
                            cellTemplate: DxGridUtils.boolCellTemplate,
                        },
                    ],
                };

                self.gridDataSource = {
                    key: self.itemKey,
                    load (loadOptions) {
                        return Promise.resolve(self.items);
                    },
                };
            },

            onAcceptItem(e) {
                if(!e || !e.data) return;
                const self = this;
                this.errorMessage = "";
                let items = e.data;
                let onCancel = function (args) {
                    self.refresh();
                }
                let onOk = function (args) {
                    let ids = _.map(items, self.itemKey);

                    self.acceptWires(ids)
                        .then(data => {
                            _.pullAllBy(self.items, items, self.itemKey);
                            return true;
                        })
                        .catch(error => {
                            self.$toast.error({ message: `Error accepting ${items.length > 1 ? self.itemTypeNamePlural : self.itemTypeName}.` });
                            return true;
                        })
                        .finally(() => {
                            self.refresh();
                        });
                }

                self.$dialog.confirm("Confirm Accept", `Are you sure you want to accept the selected ${items.length > 1 ? self.itemTypeNamePlural : self.itemTypeName}?`, onOk, onCancel, { cancelTitle: 'No', okTitle: 'Yes'});
            },

            onSearch() {
                // if(!this.hasFilter) {
                //     this.onClearSearch();
                //     return;
                // }
                this.errorMessage = "";
                this.fetchData();
            },

            onClearSearch() {
                this.filtersExpanded = true;
                this.request = new ExpectedWireOutSearchRequest();
                this.activeRequest = new ExpectedWireOutSearchRequest();
                this.items = [];
                this.refresh();
            },

            onDeleteItem(e) {
                if(!e || !e.data) return;
                const self = this;
                if (!self.localSecurity.AllowDeleteCheckFromEscrowAccounting || self.readOnly) return;
                let items = e.data;
                let onCancel = function (args) {
                    self.refresh();
                }
                let onOk = function (args) {
                    let ids = _.map(items, self.itemKey);

                    let apiPromise = self.$api.EscrowAccountingApi.deleteChecks(ids);
                    return self.$rqBusy.wait(apiPromise)
                        .then(data => {
                            _.pullAllBy(self.items, items, self.itemKey);
                            return true;
                        })
                        .catch(error => {
                            self.$toast.error({ message: `Error deleting ${items.length > 1 ? self.itemTypeNamePlural : self.itemTypeName}.` });
                            return true;
                        })
                        .finally(() => {
                            self.refresh();
                        });
                }

                self.$dialog.confirm("Confirm Delete", `Are you sure you want to delete the selected ${items.length > 1 ? self.itemTypeNamePlural : self.itemTypeName}?`, onOk, onCancel, { cancelTitle: 'No', okTitle: 'Yes'});
            },

            onEditItem(e) {
                if(!e || !e.data || this.readOnly) return;
                this.errorMessage = "";
                let checksID = _.get(e.data, this.itemKey);
                this.showWireTransferDetailsDialog(checksID);
            },

            // onExpectedWireOutDateChanged(e) {
            //     this.fetchData();
            // },

            refresh() {
                if(!this.gridInstance) return;
                this.clear();
                this.gridInstance.refresh();
            },

            showWireTransferDetailsDialog(checksID) {
                const self = this;
                let onOk = (e) => {
                    let form = e.component;
                    if (!form.isValid()) {
                       return false;
                    }
                    form.save()
                        .then(result => {
                            return true;
                        }).catch(e => {
                            console.log(e.errorMessage);
                            form.errorMessage = e.errorMessage;
                            return false;
                        })
                        .finally(() => {
                            self.fetchData();
                        });
                };
                let onCancel = e => {};
                self.$dialog.open({
                    title: `Wire Transfer:`,
                    height: window.innerHeight > 768 ? "80%" : 700,
                    width: window.innerWidth > 1367 ? "80%" : 1200,
                    resizable: true,
                    scrollable: true,
                    adaptive: false,
                    closeOnEsc: true,
                    component: CheckWritingWireTransferForm,
                    onOk: onOk,
                    onCancel: onCancel,
                    props: {
                        checksID: checksID
                    },
                });
            },
        }
    }
</script>
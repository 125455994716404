<template>
    <rqdx-action-data-grid
        ref="dataGrid"
        :automation_id="elementName('tbl_reconciliation_adjustments')"
        :actions="selectionActions"
        :config="gridConfig"
        :read-only="readOnly"
        class="rq-tab-content-grid"
        :data-source="gridDataSource"
        :export-file-name="elementName('reconciliation_adjustment', 'data')"
        @delete="onDeleteItem"
        integrated-search
        rq-editable
        hide-settings
    />
</template>

<script>
    import { OtherDisbursementReconciliationDto }  from "../models";
    import DxGridUtils from "@/shared/utilities/DxGridUtils";
    export default {
        name:"ReconciliationAdjustmentList",
        props: {
            reconciliation: { type: Object, required: true, default: () => ({}) },
            items: { type: Array, required: true, default: () => [] },
            readOnly: { type: Boolean, required: true, default: false },
        },
        data () {
            return {
                errorMessage: "",
                selectedItem: {},
                selectionActions: [],
                disburse: false
            };
        },

        watch: {
            items:{
                handler: function(newValue, oldValue) {
                    if(!_.isEqual(newValue, oldValue)) {
                        this.refresh();
                    }
                },
                deep: true,
                immediate: false
            },
            errorMessage(newValue, oldValue) {
                if(_.isEqual(newValue, oldValue)) return;
                this.$emit("update-error-message", this.errorMessage);
            },
        },

        created() {
            this.initNonReactiveVariables();
            this.initGridConfig();
        },

        computed: {
            gridInstance() { return _.get(this, "$refs.dataGrid.gridInstance", null); },
        },

        methods: {
            clear() {
                if(!this.gridInstance) return;
                this.gridInstance.option("focusedRowIndex", -1);
                this.gridInstance.clearSelection();
            },

            elementName(prefix="", suffix="") { return _.snakeCase(`${prefix} ${this.itemTypeName} ${suffix}`); },

            initGridConfig(){
                const self = this;
                self.gridConfig = {
                    onEditorPreparing: self.onEditorPreparing,
                    allowColumnReordering: false,
                    focusedRowEnabled: false,
                    paging: { enabled: true },
                    pager: { showPageSizeSelector: true, allowedPageSizes: [25,50,100], showInfo: true},
                    remoteOperations: { sorting: false, paging: false },
                    wordWrapEnabled: true,
                    columns: [
                        {
                            dataField: "description",
                            dataType: "string",
                            editorOptions: { maxLength: 100 },
                        },
                        {
                            dataField: "disburse",
                            dataType: "boolean",
                            caption: "Disbursement",
                            cellTemplate: DxGridUtils.boolCellTemplate,
                            width: 165,
                        },
                        {
                            dataField: "amount",
                            dataType: "number",
                            format: {
                                type: "currency",
                                precision: 2
                            },
                            validationRules: [
                                { type: "required" },
                            ],
                            width: 120,
                            minWidth: 120,
                        },
                        {
                            dataField: "error",
                            dataType: "boolean",
                            caption: "Apply to RBB",
                            cellTemplate: DxGridUtils.boolCellTemplate,
                            width: 165,
                        },
                        {
                            dataField: "carryForwardToRBB",
                            dataType: "boolean",
                            caption: "Carry Forward to RBB",
                            cellTemplate: DxGridUtils.boolCellTemplate,
                            width: 165,
                        },
                        {
                            dataField: "errorRecon",
                            dataType: "boolean",
                            caption: "Apply to Recon",
                            cellTemplate: DxGridUtils.boolCellTemplate,
                            width: 165,
                        },
                        {
                            dataField: "carryForwardToRecon",
                            dataType: "boolean",
                            caption: "Carry Forward to Recon",
                            cellTemplate: DxGridUtils.boolCellTemplate,
                            width: 165,
                        },
                    ],
                    summary: {
                        totalItems: [
                            {
                                name: "AppliedChecks",
                                column: "amount",
                                alignment: "right",
                                valueFormat: {
                                    type: "currency",
                                    precision: 2
                                },
                                displayFormat: "{0}",
                                summaryType: "custom"
                            },
                            {
                                name: "AppliedChecksLabel",
                                column: "description",
                                alignment: "left",
                                cssClass: "rq-summary-label",
                                displayFormat: "OTHER DISBURSEMENTS",
                                summaryType: "sum"
                            },
                            {
                                name: "AppliedDeposits",
                                column: "amount",
                                alignment: "right",
                                valueFormat: {
                                    type: "currency",
                                    precision: 2
                                },
                                displayFormat: "{0}",
                                summaryType: "custom"
                            },
                            {
                                name: "AppliedDepositsLabel",
                                column: "description",
                                alignment: "left",
                                cssClass: "rq-summary-label",
                                displayFormat: "OTHER RECEIPTS",
                                summaryType: "sum"
                            },
                        ],
                        calculateCustomSummary(options) {
                            if (options.name == "AppliedChecks") {
                                options.totalValue = _.sumBy(_.filter(self.items, i => (i.error || i.errorRecon) && i.disburse == true), 'amount');
                            }
                            if (options.name == "AppliedDeposits") {
                                options.totalValue = _.sumBy(_.filter(self.items, i => (i.error || i.errorRecon) && i.disburse == false), 'amount');
                            }
                        }
                    },
                    onInitNewRow(e) {
                        e.data.otherDisbursementReconciliationID = 0;
                        e.data.error = true;
                        e.data.errorRecon = true;
                        e.data.carryForwardToRBB = false;
                        e.data.carryForwardToRecon = false;
                        e.data.reconciliationID = self.reconciliation.reconciliationID;
                        e.data.disburse = self.disburse;
                    },
                };

                self.gridDataSource = {
                    key: self.itemKey,
                    load (loadOptions) {
                        return Promise.resolve(self.items);
                    },
                    insert: self.onGridInsert,
                    update: self.onGridUpdate
                };
            },

            initNonReactiveVariables() {
                const self = this;
                self.itemTypeName = "Adjustment";
                self.itemTypeNamePlural = "Adjustments";
                self.itemKey = "otherDisbursementReconciliationID";
                self.selectionActions = [
                    {
                        name: "delete",
                        text: "Delete",
                        eventName: "delete",
                        requireSelection: true,
                        allowMultiSelection: true,
                        tooltip: `Delete ${self.itemTypeName}`,
                        disabled: function(e) { return self.readOnly;}
                    },
                ];
            },

            onAddItem(disburse) {
                if(!this.gridInstance) return;
                this.disburse  = disburse;
                this.gridInstance.addRow();
            },

            onDeleteItem(e) {
                if(!e || !e.data) return;
                const self = this;
                let items = e.data;
                let ok = function (args) {
                    let ids = _.map(items, self.itemKey);

                    let apiPromise = self.$api.ReconciliationApi.deleteAdjustments(self.reconciliation.reconciliationID, ids);
                    return self.$rqBusy.wait(apiPromise)
                        .then(data => {
                            self.$emit("update-data", data);
                            return true;
                        })
                        .catch(error => {
                            if (error.errorMessage.indexOf("REFERENCE constraint") > 0) {
                                 self.$dialog.confirm(`Delete Error`, `One or more of the selected ${self.itemTypeNamePlural} are currently being used and could not be deleted.`);
                            } else {
                                self.$toast.error({ message: `Error deleting ${self.itemTypeName}.` });
                            }
                            return true;
                        })
                        .finally(() => {
                            self.refresh();
                        });
                }

                self.$dialog.confirm("Confirm Delete", `Are you sure you want to delete the selected ${items.length > 1 ? self.itemTypeNamePlural : self.itemTypeName}?`, ok, null, { cancelTitle: 'No', okTitle: 'Yes'});
            },

            onEditorPreparing(e){
                if(e.parentType !== "dataRow") return;
                e.editorOptions.disabled = this.readOnly;
            },

            onGridInsert(values) {
                const self = this;
                let newItem = new OtherDisbursementReconciliationDto(values);
                let changes = _.map(values, (v,k) => ({ name: k, old: null, new: v }));
                return self.saveAdjustment(newItem, changes)
                    .then(data => {
                        self.$toast.success({ message: `${self.itemTypeName} ${newItem.description} was created.` });
                        self.$emit("update-data", data);
                    }).catch(error => {
                        self.$toast.error({ message: `Error creating ${self.itemTypeName}.` });
                    });
            },

            onGridUpdate(key, values) {
                const self = this;
                let itemIndex = _.findIndex(self.items, [self.itemKey, key]);
                if(itemIndex < 0) return;

                let originalItem = _.cloneDeep(self.items[itemIndex]);
                let updatedItem = new OtherDisbursementReconciliationDto(_.assign({}, self.items[itemIndex], values));
                let changes = self.getAuditChanges(originalItem, updatedItem);

                return self.saveAdjustment(updatedItem, changes)
                    .then(data => {
                        self.$toast.success({ message: `${self.itemTypeName} ${updatedItem.description} was saved.` });
                        self.$emit("update-data", data);
                    }).catch(error => {
                        self.$toast.error({ message: `Error saving ${self.itemTypeName}.` });
                    })
                    .finally(() => {
                        self.refresh(false);
                    });
            },

            refresh(clear=true) {
                if(!this.gridInstance) return;
                if (clear) {
                    this.clear();
                }
                this.gridInstance.refresh();
                this.gridInstance.updateDimensions();
            },

            saveAdjustment(item, changes){
                const self = this;

                if(changes.length === 0) {
                    self.$toast.success({ message: `No Changed Detectd.` });
                    return Promise.resolve(item);
                }
                let apiPromise = self.$api.ReconciliationApi.saveAdjustment(item, changes);
                return self.$rqBusy.wait(apiPromise);
            },

            updateDimensions() {
                this.$nextTick(() => {
                    _.invoke(this, "gridInstance.updateDimensions");
                });
            },
        }
    }
</script>
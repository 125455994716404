<template>
    <div id="ebank-recon-main" class="content-wrapper">
        <rq-banner
            variant="warning"
            icon="fas fa-exclamation-triangle"
            message="No Exceptions!  You can now Create the Auto Recon"
            :visible="canCreateRecon"
            dismissable
        />
        <rq-banner
            variant="error"
            icon="fas fa-exclamation-triangle"
            :message="errorMessage"
            :visible="errorMessage.length > 0"
            @dismiss="errorMessage=''"
            dismissable
        />
        <rq-page-section title="Add eBank Reconciliation" headerSize="lg" class="browser-filter-section" v-model:expanded="filtersExpanded" collapsible>
            <template #header-actions>
                <transition name="simple-fade">
                    <ul v-if="!filtersExpanded" class="nav browser-filter-display">
                        <li class="nav-item" v-if="bankFilterDisplay != 'All'">
                            <div class="filter-name">Escrow Account:</div>
                            <div class="filter-value">{{bankFilterDisplay}}</div>
                        </li>
                        <li class="nav-item" v-if="hasBankSelected">
                            <b-btn
                                variant="link"
                                class="btn-theme"
                                @click="onClearSearch">Clear All Filters
                            </b-btn>
                        </li>
                    </ul>
                </transition>
            </template>
            <div class="row">
                <div :class="{ 'col col-4 col-lg-4 col-xl-4 form-group form-required':true, 'has-error':v$.bankCompanyID.$error }">
                    <label for="dtp_escrow_bank">Escrow Account</label>
                    <dx-select-box
                        :input-attr="{ automation_id: 'dtp_escrow_bank', id: 'dtp_escrow_bank' }"
                        :items="escrowBanks"
                        value-expr="id"
                        display-expr="name"
                        v-model="v$.bankCompanyID.$model"
                        placeholder="Select Escrow Account..."
                        :search-enabled="true"
                        :show-clear-button="true"
                    />
                    <rq-validation-feedback
                        :messages="{
                            'Escrow Account is required': v$.bankCompanyID.required.$invalid,
                            }"
                    />
                </div>
                <div class="col col-4 col-lg-4 col-xl-4 form-group" v-show="showPendingImports">
                    <label for="dtp_escrow_bank">Pending Import</label>
                    <dx-select-box
                        :input-attr="{ automation_id: 'dtp_import_file', id: 'dtp_import_file' }"
                        :items="pendingImports"
                        value-expr="autoReconUserImportID"
                        display-expr="displayName"
                        placeholder="Select Import..."
                        :search-enabled="true"
                        :show-clear-button="true"
                        v-model="autoReconUserImportID"
                    />
                </div>
            </div>
        </rq-page-section>
        <rqdx-action-data-grid
            ref="dataGrid"
            automation_id="tbl_ebank_recon_exceptions"
            :actions="selectionActions"
            :config="gridConfig"
            title="eBank Reconciliation Exceptions"
            class="grid-container"
            title-size="sm"
            :data-source="gridDataSource"
            @find-match="onFindMatch"
            @goto-checkwriting="onGotoCheckWriting"
            @goto-file="onGotoFile"
            @goto-reconciliation="onGotoReconciliation"
            @to-other="onMoveToOther"
            @from-other="onRemoveFromOther"
            @rowDoubleClick="onFindMatch"
            export-file-name="e_bank_reconciliation_exceptions_data"
            integrated-search
            rq-filters>
            <template #toolbar>
                <ul class="nav">
                    <li class="nav-item" v-if="canCreateRecon" v-rq-tooltip.hover.top="'Create Auto Reconciliation From Selected Import'">
                        <b-button
                            automation_id="btn_add"
                            class="btn btn-theme me-1"
                            variant="theme"
                            @click="onCreateEBankRecon(true)"
                            >Create Recon
                        </b-button>
                    </li>
                    <li class="nav-item" v-if="canCreateUnfinalizedRecon" v-rq-tooltip.hover.top="'Create Unfinalized Reconciliation From Selected Import'">
                        <b-button
                            automation_id="btn_add"
                            class="btn btn-theme me-1"
                            variant="theme"
                            @click="onCreateEBankRecon(false)"
                            >Create Unfinalized Recon
                        </b-button>
                    </li>
                    <li class="nav-item" v-if="!hasPendingImportSelected" v-rq-tooltip.hover.top="{ title: readOnly ? 'Access Restricted or Bank Must Meet A Requirement' : 'Add New eBank Reconciliation' }">
                        <b-button
                            automation_id="btn_add"
                            class="btn btn-theme me-1"
                            variant="theme"
                            @click="onProcessEBankRecon"
                            :disabled="readOnly"
                            >Import
                        </b-button>
                    </li>
                    <li class="nav-item" v-else>
                        <b-button
                            automation_id="btn_add"
                            class="btn btn-theme me-1"
                            variant="theme"
                            @click="onDeleteImport"
                            :disabled="readOnly"
                            >Delete
                        </b-button>
                    </li>
                    <li class="nav-item" v-if="hasReconciliationID" v-rq-tooltip.hover.top="'View New Reconciliation'">
                        <b-button
                            automation_id="btn_add"
                            class="btn btn-theme me-1"
                            variant="theme"
                            @click="onViewRecon"
                            >View Recon
                        </b-button>
                    </li>
                    <li class="nav-item" v-if="hasIssues" v-rq-tooltip.hover.top="'Download Exceptions'">
                        <b-button
                            automation_id="btn_add"
                            class="btn btn-theme me-1"
                            variant="theme"
                            @click="onDownloadExceptions"
                            >Download Exceptions
                        </b-button>
                    </li>
                </ul>
            </template>
        </rqdx-action-data-grid>
        <ebank-recon-popover
            container="#ebank-recon-main"
            :popover="popover"
            v-model:visible="popover.visible"
        />
    </div>
</template>

<script>
    import { mapState, mapGetters } from "vuex";
    import { SYSTEM_MUTATIONS } from "@/store/mutations";
    import UploadEBankReconFile from "../components/UploadEBankReconFile";
    import DepositMatchDialog from "../components/DepositMatchDialog";
    import CheckMatchDialog from "../components/CheckMatchDialog";
    import { AutoReconUserImportDto, AutoReconTransactionTempDetailDto, ReconciliationDto, EscrowCheckDto, EscrowDepositDto } from "../models";
    import { SystemLookupItem } from "@/shared/models/models";
    import EbankReconPopover from "@/shared/components/rq/EbankReconPopover";
    import { ReportOptionsDto } from "@reporting/exago-reports/report-models";
    import { required } from '@vuelidate/validators';
    import { useVuelidate } from "@vuelidate/core";
    import DxGridUtils from "@/shared/utilities/DxGridUtils";
    const AMOUNT_MISMATCH = "Amount Mismatch";
    const MULTIPLE_MATCHES = "Multiple Matches";
    const NO_MATCH = "No Match Found";

    export default {
        name: "ProcessEBankRecon",
        components: { EbankReconPopover },

        setup: () => ({ v$: useVuelidate() }),

        data () {
            return {
                autoReconUserImportID: null,
                activeBankCompanyID: null,
                bankCompanyID: null,
                gridConfig: {},
                items: [],
                pendingImports: [],
                errorMessage: "",
                selectionActions: [],
                filtersExpanded: true,
                reconciliation: null,
                popover: {
                    visible: false,
                    target: null,
                    isCheck: false,
                    bankItem: {},
                    item: {},
                    title: ""
                },
            };
        },

        created() {
            this.initNonReactiveVariables();
            this.initGridConfig();
            this.initSearch();
        },

        beforeRouteLeave(to, from, next) {
            this.$store.commit(SYSTEM_MUTATIONS.SET_EBANK_RECON_SEARCH, {bankCompanyID: this.bankCompanyID, autoReconUserImportID: this.autoReconUserImportID});
            next();
        },

        watch: {
            "autoReconUserImportID":{
                handler: function(newValue, oldValue) {
                    if (_.isNull(newValue)) {
                        this.items = [];
                    } else {
                        this.fetchData();
                    }
                }
            },
            "bankCompanyID":{
                handler: function(newValue, oldValue) {
                    this.validate();
                    this.fetchData();
                }
            },
        },

        computed: {
            ...mapGetters([
                "lookupHelpers",
                "lookupItems"
            ]),
            ...mapState({
                user: state => state.authentication.session.user,
                activeRequest: state => state.system.eBankReconSearch,
                globalRegionId: state => state.system.globalRegionId,
            }),
            accepted() {
                if (!this.hasBankSelected) return "";
                let bank = _.find(this.escrowBanks, ["id", this.bankCompanyID]);
                let data = _.split(bank ? bank.data : null, '-');
                if (_.size(data) != 3) return false;
                return data[2];
            },
            gridInstance() { return _.get(this, "$refs.dataGrid.gridInstance", null); },
            currentBank() { return _.find(this.escrowBanks, ["id", this.bankCompanyID]) || new SystemLookupItem(); },
            showPendingImports() { return _.size(this.pendingImports) > 0; },
            localSecurity(){
                return this.securitySettings.findValues(["CanProcessEBankRecon"]);
            },
            bankFilterDisplay(){
                return _.isNil(this.bankCompanyID) ? "None" : this.lookupHelpers.getLookupItemName(this.lookupItems.ESCROW_ACCOUNTS, this.bankCompanyID);
            },
            canCreateRecon(){
                return this.hasPendingImportSelected && !this.hasIssues && !this.hasReconciliationID && !this.$rqBusy.isBusy();
            },
            canCreateUnfinalizedRecon(){
                return this.hasPendingImportSelected && !this.hasReconciliationID && !this.$rqBusy.isBusy();
            },
            reportTooltip(){
                if (this.hasBankSelected && !this.hasReconciliationID) return "Create A New Recon By Selecting Import First";
                return "";
            },
            userBankCompanyID(){
                const branch = this.lookupHelpers.getBranch(this.user.branchID);
                let bankCompanyID = _.parseNumber(branch?.bankCompanyID, 0);
                return bankCompanyID == 0 ? null : bankCompanyID;
            },
            hasBankSelected(){
                return _.parseNumber(this.bankCompanyID, 0) > 0;
            },
            hasFilterChanged(){
                return !_.isEqual(this.activeBankCompanyID, this.bankCompanyID);
            },
            hasConfigSetting(){
                if (!this.hasBankSelected) return false;
                let bank = _.find(this.escrowBanks, ["id", this.bankCompanyID]);
                let data = _.split(bank ? bank.data : null, '-');
                if (_.size(data) != 3) return false;
                let hasConfig = data[0];
                return _.isEqual(hasConfig, "true");
            },
            hasIssues(){
                if (_.size(this.items) == 0) return false;
                return _.some(this.items, ["hasIssue", true]);
            },
            hasPreviousRecons(){
                if (!this.hasBankSelected) return false;
                let bank = _.find(this.escrowBanks, ["id", this.bankCompanyID]);
                let data = _.split(bank ? bank.data : null, '-');
                if (_.size(data) != 3) return false;
                let reconCount = _.parseNumber(data[1], 0);
                return _.gte(reconCount, 3);
            },
            hasPendingImportSelected(){
                return _.parseNumber(this.autoReconUserImportID, 0) > 0;
            },
            hasReconciliationID(){
                return _.parseNumber(this.reconciliation?.reconciliationID, 0) > 0;
            },
            hasValidFormat(){
                if (!this.hasBankSelected) return false;
                let bank = _.find(this.escrowBanks, ["id", this.bankCompanyID]);
                let data = _.split(bank ? bank.data : null, '-');
                if (_.size(data) != 3) return false;
                let hasUnknownFormat = data[2];
                return !_.isEqual(hasUnknownFormat, "unknown");
            },
            readOnly() { return !this.localSecurity.CanProcessEBankRecon || !this.hasPreviousRecons || !this.hasConfigSetting; },
            reportOptions() {
                let reportOptions =
                    new ReportOptionsDto({
                        reportPath: "System Reports\\File Specific\\Reconciliation Exceptions Report",
                        title: 'Reconciliation Exceptions',
                        disabled: true,
                        immediate: true,
                        parameters: {
                            p_Banks:        this.bankCompanyID || null,
                            p_StartDate:    this.reconciliationDateFrom,
                            p_EndDate:      this.reconciliationDateTo,
                            p_IsIncludeFinalized: 0
                        },
                    });
                return reportOptions;
            },
        },

        validations: () => ({ bankCompanyID: { required }}),

        methods: {
            clear() {
                if(!this.gridInstance) return;
                this.gridInstance.option("focusedRowIndex", -1);
                this.gridInstance.clearSelection();
            },

            createReconFromImport(finalized){
                const self = this;
                let apiPromise = self.$api.EBankReconApi.createReconFromImport(self.bankCompanyID, self.autoReconUserImportID, finalized);
                return self.$rqBusy.wait(apiPromise);
            },

            deleteImport(){
                const self = this;
                if (_.parseNumber(self.autoReconUserImportID, 0) == 0 || _.parseNumber(self.bankCompanyID, 0) == 0) return;
                let apiPromise = self.$api.EBankReconApi.deleteImport(self.bankCompanyID, self.autoReconUserImportID);
                return self.$rqBusy.wait(apiPromise);
            },

            fetchData() {
                const self = this;
                if (!_.isNullOrEmpty(self.errorMessage) || _.parseNumber(self.bankCompanyID, 0)== 0) return;
                self.items = [];
                let apiPromise = self.$api.EBankReconApi.getData(self.bankCompanyID, self.autoReconUserImportID || 0);
                self.$rqBusy.wait(apiPromise)
                    .then(result => {
                        self.activeBankCompanyID = self.bankCompanyID;
                        self.setData(result);
                    })
                    .catch(error => {
                        console.error(error);
                        self.$toast.error({ message: `Error loading ${self.itemTypeNamePlural}.` });
                    })
                    .finally(() => {
                        self.refresh();
                    });
            },

            fetchEscrowCheck(checksID) {
                const self = this;
                if (_.parseNumber(checksID, 0) == 0) return;
                let apiPromise = self.$api.EBankReconApi.getEscrowCheck(checksID);
                return self.$rqBusy.wait(apiPromise);
            },

            fetchEscrowDeposit(depositID) {
                const self = this;
                if (_.parseNumber(depositID, 0) == 0) return;
                let apiPromise = self.$api.EBankReconApi.getEscrowDeposit(depositID);
                return self.$rqBusy.wait(apiPromise);
            },

            async initNonReactiveVariables() {
                const self = this;
                self.itemTypeName = "eBank Recon";
                self.itemTypeNamePlural = "eBank Recons";
                self.itemKey = "autoReconTransactionTempDetailID";
                self.transactionTypeMapping = [{transactionType: "Credit", transactionTypeDisplay: "Receipt"}, {transactionType: "CreditWire", transactionTypeDisplay: "Wire In"}, {transactionType: "Debit", transactionTypeDisplay: "Disbursement"}, {transactionType: "DebitWire", transactionTypeDisplay: "Wire Out"}];
                self.issueTypes = [{name: "No Match Found"}, {name: "Amount Mismatch"}, {name: "Multiple Matches"}, {name: "On Finalized Reconciliation"}, {name: "On Unfinalized Reconciliation"}];
                self.selectionActions = [
                    {
                        name: "find-match",
                        text: "Find Match",
                        eventName: "find-match",
                        requireSelection: true,
                        disabled: function(e) { return self.findMatchDisabled(e); },
                    },
                    {
                        name: "to-other",
                        text: "Move to Other",
                        eventName: "to-other",
                        allowMultiSelection: true,
                        requireSelection: true,
                        disabled: function(e) { return self.moveToOtherDisabled(e); },
                    },
                    {
                        name: "from-other",
                        text: "Remove from Other",
                        eventName: "from-other",
                        allowMultiSelection: true,
                        requireSelection: true,
                        disabled: function(e) { return self.removeFromOtherDisabled(e); },
                    },
                    {
                        name: "navigate",
                        text: "Goto",
                        allowMultiSelection: false,
                        children: [
                            { name: "goto-checkwriting", text: "Check Writing", eventName: "goto-checkwriting", requireSelection: true, disabled: function(e) { return _.parseNumber(e.data?.ordersID, 0) == 0} },
                            { name: "goto-file", text: "File", eventName: "goto-file", requireSelection: true, disabled: function(e) { return _.parseNumber(e.data?.ordersID, 0) == 0} },
                            { name: "goto-reconciliation", text: "Reconciliation", eventName: "goto-reconciliation", requireSelection: true, disabled: function(e) { return _.parseNumber(e.data?.reconciliationID, 0) == 0} },
                        ],
                    },
                ];
                self.escrowBanks = await self.$api.LookupsApi.getSystemLookup("escrowaccountsuncached", self.globalRegionId);
            },

            initGridConfig(){
                const self = this;
                self.gridConfig = {
                    columnHidingEnabled: false,
                    focusedRowEnabled: false,
                    paging: { enabled: true },
                    pager: { showPageSizeSelector: true, allowedPageSizes: [50,100,500], showInfo: true},
                    remoteOperations: { sorting: false, paging: false },
                    height: "100%",
                    columns: [
                        {
                            dataField: "autoReconTransactionTypeCode",
                            dataType: "string",
                            caption: "Type Code",
                        },
                        {
                            dataField: "transactionType",
                            dataType: "string",
                            lookup: {
                                dataSource: self.transactionTypeMapping,
                                valueExpr: "transactionType",
                                displayExpr: "transactionTypeDisplay"
                            },
                            rqFilter: {
                                displayExpr: "transactionTypeDisplay",
                                valueExpr: "transactionType",
                                filterType: "tags",
                                dataSource: {
                                    loadMode: "raw",
                                    load() {
                                        return self.transactionTypeMapping;
                                    }
                                }
                            }
                        },
                        DxGridUtils.dateColumn({
                            dataField: "transactionDate",
                            caption: "Recon Date",
                            visible: false
                        }),
                        {
                            dataField: "transactionAmount",
                            dataType: "number",
                            caption: "Amount",
                            format: {
                                type: "currency",
                                precision: 2
                            },
                        },
                        {
                            dataField: "customerReferenceNumber",
                            dataType: "string",
                            caption: "Reference#",
                        },
                        {
                            dataField: "bankReferenceNumber",
                            dataType: "string",
                            caption: "Bank Reference#",
                            visible: false
                        },
                        {
                            dataField: "transactionDescription",
                            dataType: "string",
                            caption: "Description",
                        },
                        {
                            dataField: "notes",
                            dataType: "string",
                            caption: "Notes",
                        },
                        {
                            dataField: "issue",
                            dataType: "string",
                            caption: "Issue",
                            cellTemplate: DxGridUtils.autoReconIssueCellTemplate({
                                idAppend: "ebank-popup-info-",
                                handlers:{
                                    click(cellElement, cellInfo, e) {
                                        self.updatePopover(cellInfo.data, e.target);
                                        e.stopPropagation();
                                    },
                                }
                            }),
                            rqFilter: {
                                displayExpr: "name",
                                valueExpr: "name",
                                filterType: "tags",
                                dataSource: {
                                    loadMode: "raw",
                                    load() {
                                        return self.issueTypes;
                                    }
                                }
                            }
                        },
                        {
                            dataField: "isOther",
                            dataType: "boolean",
                            cellTemplate: DxGridUtils.boolCellTemplate
                        },
                        {
                            dataField: "hasMatch",
                            caption: "Match",
                            dataType: "boolean",
                            cellTemplate: DxGridUtils.boolCellTemplate,
                            visible: false
                        },
                    ],
                };

                self.gridDataSource = {
                    key: self.itemKey,
                    load (loadOptions) {
                        return Promise.resolve(self.items);
                    },
                };
            },

            initSearch() {
                const self = this;
                // If there is any search request history, do the search with the last criteria
                if (!_.isNil(self.activeRequest)) {
                    let request = _.clone(self.activeRequest);
                    self.activeBankCompanyID = self.bankCompanyID = request.bankCompanyID;
                    self.autoReconUserImportID = request.autoReconUserImportID;
                } else {
                    self.activeBankCompanyID = self.bankCompanyID = self.userBankCompanyID;
                }
                self.fetchData();
            },

            findMatchDisabled(e){
                const self = this;
                if (self.readOnly) {
                    return "Access Restricted";
                }
                if (e.data.isOther === true) {
                    return "This item has been marked as an Other Receipt or Disbursement";
                }
                return false;
            },

            moveToOtherDisabled(e){
                const self = this;
                if (self.readOnly) {
                    return "Access Restricted";
                }
                if (_.some(e.data, { isOther: true})) {
                    return "Some transactions are already set to Other.";
                }
                return false;
            },

            removeFromOtherDisabled(e){
                const self = this;
                if (self.readOnly) {
                    return "Access Restricted";
                }
                if (_.some(e.data, { isOther: false})) {
                    return "Some transactions are not already set to Other.";
                }
                return false;
            },

            onClearSearch() {
                this.activeBankCompanyID = this.bankCompanyID = this.userBankCompanyID;
                this.filtersExpanded = true;
                this.items = [];
                this.refresh();
            },

            onCreateEBankRecon(finalized=false) {
                const self = this;
                if (!_.isNullOrEmpty(self.errorMessage) || _.parseNumber(self.autoReconUserImportID, 0)== 0) return;
                self.createReconFromImport(finalized)
                    .then(result => {
                        self.setData(result);
                        if (self.hasReconciliationID) {
                            self.$toast.success({ message: `Reconciliation ID#${self.reconciliation.reconciliationID} created successfully.` });
                        }
                        self.autoReconUserImportID = null;
                        return true;
                    })
                    .catch(error => {
                        return false;
                    })
                    .finally(() => {
                        self.refresh();
                    });
            },

            onDeleteImport() {
                if(this.readOnly) return;
                const self = this;
                let onYes = () => {
                    return self.deleteImport()
                        .then(result => {
                            self.autoReconUserImportID = null;
                            self.setData(result);
                            self.$toast.success({ message: `Import deleted successfully.` });
                            return true;
                        })
                        .catch(error => {
                            return false;
                        })
                        .finally(() => {
                            self.refresh();
                        });
                }
                self.$dialog.confirm("Confirm", 'Are you sure you want to delete this import?', onYes, null, { cancelTitle: "No", okTitle: "Yes"});
            },

            onDownloadExceptions() {
                const self = this;
                let pi = _.find(self.pendingImports, {autoReconUserImportID: self.autoReconUserImportID});
                let fileNameBase = _.join(_.dropRight(_.split(pi.fileName, '.')));
                DxGridUtils.exportToCsv(self.gridInstance, `${fileNameBase}_Exceptions.csv`);
            },

            onFindMatch(e) {
                const self = this;
                if(!e || !e.data || self.readOnly || !self.hasPendingImportSelected) return;
                if (_.parseBool(e.data.isCheck, false)) {
                    self.showCheckMatchDialog(e.data);
                } else {
                    self.showDepositMatchDialog(e.data);
                }
            },

            onGotoFile(e) {
                if(!e || !e.data || this.readOnly) return;
                let orderId = e.data.ordersID;
                let tab = this.popover.isCheck ? 2 : 1;
                this.$router.push({ name: "check-writing", params: { orderId, tab } });
            },

            onGotoCheckWriting(e) {
                if(!e || !e.data || this.readOnly) return;
                let orderId = e.data.ordersID;
                let tab = !_.isNull(e.data.checksID) ? 2 : 1;
                this.$router.push({ name: "check-writing", params: { orderId, tab } });
            },

            onGotoReconciliation(e) {
                if(!e || !e.data || this.readOnly) return;
                let reconciliationID = e.data.reconciliationID;
                this.$router.push({ name: 'ea:reconciliation', params: { reconciliationID: reconciliationID }});
            },

            onMoveToOther(e) {
                if(!e || !e.data || this.readOnly) return;
                const self = this;
                let items = _.clone(e.data);
                _.each(items, item => {
                    _.set(item, 'isOther', true);
                });

                self.saveTransactionDetail(items)
                    .then(result => {
                        self.setData(result);
                        self.$toast.success({ message: `Transaction Detail${items.length == 1 ? '' : 's'} successfully set to other.` });
                        return true;
                    })
                    .catch(error => {
                        return false;
                    })
                    .finally(() => {
                        self.refresh();
                    });
            },

            onRemoveFromOther(e) {
                if(!e || !e.data || this.readOnly) return;
                const self = this;
                let items = _.clone(e.data);
                _.each(items, item => {
                    _.set(item, 'isOther', false);
                });
                self.saveTransactionDetail(items)
                    .then(result => {
                        self.setData(result);
                        self.$toast.success({ message: `Transaction Detail${items.length == 1 ? '' : 's'} successfully removed from other.` });
                        return true;
                    })
                    .catch(error => {
                        return false;
                    })
                    .finally(() => {
                        self.refresh();
                    });
            },

            onProcessEBankRecon() {
                if(this.readOnly) return;
                this.validate();
                if (!_.isNullOrEmpty(this.errorMessage)) return;
                this.showUploadFileDialog();
            },

            onViewRecon() {
                if (!this.hasReconciliationID) return;
                this.$router.push({ name: 'ea:reconciliation', params: { reconciliationID: this.reconciliation.reconciliationID }});
            },

            refresh() {
                if(!this.gridInstance) return;
                this.clear();
                this.gridInstance.refresh();
            },

            saveTransactionDetail(items){
                const self = this;
                let apiPromise = self.$api.EBankReconApi.saveTransactionDetail(self.bankCompanyID, items);
                return self.$rqBusy.wait(apiPromise);
            },

            showCheckMatchDialog(bankInfo) {
                const self = this;
                let noun = _.parseBool(bankInfo.isWire, false) ? "Wire" : "Disbursement";
                let onOk = (e) => {
                    return e.component.save()
                            .then(result => {
                                self.$toast.success({ message: `${noun} was assigned.` });
                                self.setData(result);
                                self.refresh();
                                return true;
                            })
                            .catch(error => {
                                e.component.errorMessage = error.errorMessage;
                                console.log(error);
                                return false;
                            });
                };
                let onCancel = (e) => {
                    self.refresh();
                    return true;
                };
                let autoReconUserImport = _.find(self.pendingImports, ["autoReconUserImportID", self.autoReconUserImportID]);
                let title = `Find Matching ${noun}`;
                self.$dialog.open({
                    title: title,
                    width: "90%",
                    height: "80%",
                    closeOnEsc: true,
                    component: CheckMatchDialog,
                    props: {bankCompanyID: self.bankCompanyID, bankInfo: bankInfo, autoReconUserImport: autoReconUserImport},
                    onOk: onOk,
                    onCancel: onCancel
                });
            },

            showDepositMatchDialog(bankInfo) {
                const self = this;
                let noun = _.parseBool(bankInfo.isWire, false) ? "Wire" : "Receipt";
                let onOk = (e) => {
                    return e.component.save()
                            .then(result => {
                                self.$toast.success({ message: `${noun} was assigned.` });
                                self.setData(result);
                                self.refresh();
                                return true;
                            })
                            .catch(error => {
                                e.component.errorMessage = error.errorMessage;
                                console.log(error);
                                return false;
                            });
                };
                let onCancel = (e) => {
                    self.refresh();
                    return true;
                };
                let autoReconUserImport = _.find(self.pendingImports, ["autoReconUserImportID", self.autoReconUserImportID]);
                let title = `Find Matching ${noun}`;
                self.$dialog.open({
                    title: title,
                    width: "90%",
                    height: "80%",
                    closeOnEsc: true,
                    component: DepositMatchDialog,
                    props: {bankCompanyID: self.bankCompanyID, bankInfo: bankInfo, autoReconUserImport: autoReconUserImport},
                    onOk: onOk,
                    onCancel: onCancel
                });
            },

            showUploadFileDialog() {
                const self = this;
                let onOk = (e) => {
                    return e.component.processFile()
                        .then(result => {
                            self.reconciliation = new ReconciliationDto();
                            self.activeBankCompanyID = self.bankCompanyID;
                            self.setData(result);
                            if (self.showPendingImports) {
                                self.autoReconUserImportID = self.pendingImports[0].autoReconUserImportID;
                            }
                            self.refresh();
                            if (self.hasReconciliationID) {
                                self.$toast.success({ message: `Reconciliation ID#${self.reconciliation.reconciliationID} created successfully.` });
                            }
                            return true;
                        })
                        .catch(error => {
                            e.component.clearForm();
                            e.component.errorMessage = error.errorMessage;
                            return false;
                        });
                };
                self.$dialog.open({
                    title: `Upload eBank File (${self.accepted})`,
                    height: "auto",
                    width: "500",
                    adaptive: true,
                    closeOnEsc: true,
                    component: UploadEBankReconFile,
                    props:  {
                            accepted: self.accepted,
                            bankCompanyID: self. bankCompanyID
                            },
                    onOk: onOk
                });
            },

            setData(data) {
                const self = this;
                self.items = _.map(data.details, i => new AutoReconTransactionTempDetailDto(i));
                self.pendingImports = _.map(data.pendingImports, i => new AutoReconUserImportDto(i));
                if (_.hasIn(data, "reconciliation")) self.reconciliation = new ReconciliationDto(data.reconciliation);
            },

            updatePopover(bankItem=null, target=null) {
                const self = this;
                let newID = _.get(target, "id") || null;
                let lastID = _.get(self.popover, "target") || null;
                let isNewItem = !_.isEqual(newID, lastID);
                if (isNewItem) {
                    if (!_.isNil(lastID)) {
                        self.popover.visible = false;
                    }
                    if (_.parseBool(bankItem.isCheck, false)) {
                        self.popover.isCheck = true;
                        let noun = _.parseBool(bankItem.isWire, false) ? "Wire" : "Disbursement";
                        self.fetchEscrowCheck(bankItem.checksID)
                            .then(result => {
                                self.popover.title = `${noun} Comparison`;
                                self.popover.target = newID;
                                self.popover.item = new EscrowCheckDto(_.assign({}, result));
                                self.popover.bankItem = bankItem;
                            })
                            .catch(error => {
                                self.$toast.error({ message: `Error loading ${noun}.` });
                                return;
                            })
                            .finally(() => {
                                self.$nextTick(() => {
                                    self.popover.visible = true;
                                });
                            });
                    } else {
                        self.popover.isCheck = false;
                        let noun = _.parseBool(bankItem.isWire, false) ? "Wire" : "Receipt";
                        self.fetchEscrowDeposit(bankItem.depositID)
                            .then(result => {
                                self.popover.title = `${noun} Comparison`;
                                self.popover.target = newID;
                                self.popover.item = new EscrowDepositDto(_.assign({}, result));
                                self.popover.bankItem = bankItem;
                            })
                            .catch(error => {
                                self.$toast.error({ message: `Error loading ${noun}.` });
                                return;
                            })
                            .finally(() => {
                                self.$nextTick(() => {
                                    self.popover.visible = true;
                                });
                            });
                    }
                } else {
                    self.popover.visible = !self.popover.visible;
                }
            },

            validate() {
                this.errorMessage = "";
                if (!this.hasBankSelected) {
                    this.errorMessage = "Please correct the highlighted errors on screen to continue.";
                    return;
                }
                if (!this.hasConfigSetting) {
                    this.errorMessage = "This bank has not been configured for eBank Recon.  Please see your admin.";
                    return;
                }
                if (!this.hasValidFormat) {
                    this.errorMessage = "This bank does not have a valid file type configured.  Please see your admin.";
                    return;
                }
                if (!this.hasPreviousRecons) {
                    this.errorMessage = "This bank does not have 3 finalized manual recons.  Once complete, eBank Recon will be available.";
                    return;
                }
            },
        }
    }
</script>

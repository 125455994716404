<template>
    <div id="check-browser" class="content-wrapper escrow-browser">
        <rq-banner
            variant="error"
            icon="fas fa-exclamation-triangle"
            :message="errorMessage"
            :visible="errorMessage.length > 0"
            @dismiss="errorMessage=''"
            dismissable
        />
        <rq-page-section title="Search Escrow Activity" headerSize="lg" class="browser-filter-section" v-model:expanded="filtersExpanded" @keyup.enter="onSearch" collapsible>
            <template #header-actions>
                <transition name="simple-fade">
                    <ul v-if="!filtersExpanded" class="nav browser-filter-display">
                        <li class="nav-item" v-if="bankFilterDisplay != 'All'">
                            <div class="filter-name">Escrow Account:</div>
                            <div class="filter-value">{{bankFilterDisplay}}</div>
                        </li>
                        <li class="nav-item" v-if="actionFilterDisplay != 'All'" v-rq-tooltip.html.hover.top :title="actionFilterTooltip">
                            <div class="filter-name">Action:</div>
                            <div class="filter-value">{{actionFilterDisplay}}</div>
                        </li>
                        <li class="nav-item" v-if="personFilterDisplay != 'All'">
                            <div class="filter-name">Person:</div>
                            <div class="filter-value">{{personFilterDisplay}}</div>
                        </li>
                        <li class="nav-item" v-if="request.ordersID > 0">
                            <div class="filter-name">File:</div>
                            <div class="filter-value">{{gfNo}}</div>
                        </li>
                        <li class="nav-item" v-if="amountFilterDisplay.length > 0">
                            <div class="filter-name">Amount:</div>
                            <div class="filter-value">{{amountFilterDisplay}}</div>
                        </li>
                        <li class="nav-item" v-if="dateFilterDisplay.length > 0">
                            <div class="filter-name">Date:</div>
                            <div class="filter-value">{{dateFilterDisplay}}</div>
                        </li>
                        <li class="nav-item" v-if="hasActiveFilter">
                            <b-btn
                                variant="link"
                                class="btn-theme"
                                @click="onClearSearch">Clear All Filters
                            </b-btn>
                        </li>
                    </ul>
                </transition>
                <transition name="simple-fade">
                    <ul v-if="filtersExpanded" class="nav ms-auto">
                        <li class="nav-item">
                            <b-btn
                                automation_id="btn_clear"
                                variant="theme"
                                @click="onClearSearch">Clear</b-btn>
                        </li>
                        <li class="nav-item">
                            <b-btn
                                automation_id="btn_search"
                                variant="theme"
                                :disabled="errorMessage.length > 0 && !hasFilter || fileNumberInvalid"
                                @click="onSearch">Search</b-btn>
                        </li>
                    </ul>
                </transition>
            </template>
            <div class="row">
                <div class="col col-6 col-xl-4 form-group">
                    <label for="dtp_escrow_bank">Escrow Account</label>
                    <dx-select-box
                        :input-attr="{ automation_id: 'dtp_escrow_bank', id: 'dtp_escrow_bank' }"
                        value-expr="id"
                        display-expr="name"
                        :data-source="escrowAccountDataSource"
                        v-model="request.bankCompanyID"
                        placeholder="All Escrow Accounts..."
                        @value-changed="onEscrowBankChanged"
                        :disabled="readOnly"
                        :search-enabled="true"
                        :show-clear-button="true"
                        :grouped="true"
                    />
                </div>
                <div class="col col-6 col-xl-4 form-group">
                    <label for="dtp_check_action">Action</label>
                    <dx-tag-box
                        automation_id="tb_check_actions"
                        ref="tb_tags"
                        class="form-control"
                        :data-source="actions"
                        display-expr="name"
                        value-expr="id"
                        :search-enabled="true"
                        :show-selection-controls="true"
                        :show-clear-button="true"
                        :max-displayed-tags="3"
                        :show-drop-down-button="true"
                        :disabled="readOnly"
                        placeholder="All Actions..."
                        apply-value-mode="useButtons"
                        v-model:value="request.checkActionIDs"
                    />
                </div>
                <div class="col col-6 col-xl-4">
                    <file-number-input
                        automation_id="txt_gfno"
                        label="File #"
                        v-model:order-id="request.ordersID"
                        v-model:has-error="fileNumberInvalid"
                        v-model="request.gfNo"
                        :disabled="readOnly"
                        show-clear-button
                        show-search-button
                    />
                </div>
                <div class="col col-6 col-xl-4 form-group">
                    <label for="dtp_person">Person</label>
                    <dx-select-box
                        :input-attr="{ automation_id: 'dtp_person', id: 'dtp_person' }"
                        :items="users"
                        value-expr="id"
                        display-expr="name"
                        v-model="request.usersID"
                        placeholder="All People..."
                        :disabled="readOnly"
                        :search-enabled="true"
                        :show-clear-button="true"
                    />
                </div>
                <div class="col col-6 col-xl-4 form-group">
                    <label for="txt_date_range">Date Range</label>
                    <rq-date-range
                        id="txt_date_range"
                        v-model:start-date="request.dateFrom"
                        v-model:end-date="request.dateTo"
                        :disabled="readOnly"
                        match-value="before-clear"
                        match-field="end"
                        format="MM/dd/yyyy"
                        type="date"
                        show-calendar-drop-downs
                        show-clear-buttons
                        no-calendars
                        borderless
                    />
                </div>
                <div class="col col-6 col-xl-4 form-group">
                    <label for="txt_amount_range">Amount Range</label>
                    <rq-number-range
                        automation_id="txt_amount_range"
                        prepend-icon="fas fa-dollar-sign"
                        :decimals="2"
                        v-model:start-value="request.amountFrom"
                        v-model:end-value="request.amountTo"
                        :disabled="readOnly"
                        match-value="before-clear"
                        match-field="end"
                        show-clear-buttons
                        input-groups
                        allow-nulls
                        no-prefix
                        commas
                    />
                </div>
            </div>
        </rq-page-section>
        <rq-tabs
            :tabs="tabItems"
            v-model="tabIndex"
            @activate-tab="onTabActivated"
            >
            <template #activity>
                <escrow-activity-grid
                    :ref="tabItems[0].ref"
                    :items="activity"
                    :reportOptions="reportOptions"
                    :printOptionDisabled="hasFilterChanged || activity.length === 0"
                ></escrow-activity-grid>
            </template>
            <template #disbursements>
                <check-activity-grid
                    :ref="tabItems[1].ref"
                    :items="checks"
                    :reportOptions="reportOptions"
                    :printOptionDisabled="hasFilterChanged || checks.length === 0"
                ></check-activity-grid>
            </template>
            <template #receipts>
                <deposit-activity-grid
                    :ref="tabItems[2].ref"
                    :items="deposits"
                    :reportOptions="reportOptions"
                    :printOptionDisabled="hasFilterChanged || deposits.length === 0"
                ></deposit-activity-grid>
            </template>
            <template #escrow-memos>
                <escrow-memo-activity-grid
                    :ref="tabItems[3].ref"
                    :items="escrowMemos"
                    :reportOptions="reportOptions"
                    :printOptionDisabled="hasFilterChanged || escrowMemos.length === 0"
                ></escrow-memo-activity-grid>
            </template>
        </rq-tabs>
        <b-tooltip target="activityList___BV_tab_button__" title="All Disbursements and Receipts only" :delay="{ show: 100, hide: 0 }"></b-tooltip>
    </div>
</template>

<script>
    import { mapState, mapGetters } from "vuex";
    import { EscrowActivityDto, EscrowCheckActivityDto, EscrowDepositActivityDto, EscrowMemoActivityDto, EscrowActivitySearchRequestDto }  from "../models";
    import { CheckActions } from '../enums';
    import { SystemLookupItem } from "@/shared/models/models";
    import CheckActivityGrid from "../components/CheckActivityGrid";
    import DataSource from 'devextreme/data/data_source';
    import DepositActivityGrid from "../components/DepositActivityGrid";
    import EscrowActivityGrid from "../components/EscrowActivityGrid";
    import EscrowMemoActivityGrid from "../components/EscrowMemoActivityGrid";
    import FileNumberInput from "@/shared/components/rq/FileNumberInput";
    import { ReportOptionsDto } from "@reporting/exago-reports/report-models";

    const DEFAULT_ERROR_MESSAGE = "Please correct the highlighted errors on screen to continue.";
    const INVALID_FILE_ERROR_MESSAGE = "Invalid File Number";

    export default {
        name: "EscrowActivityList",
        components: { FileNumberInput, CheckActivityGrid, DepositActivityGrid, EscrowMemoActivityGrid, EscrowActivityGrid },
        data () {
            return {
                actions: [],
                activity: [],
                checks: [],
                deposits: [],
                escrowMemos: [],
                tabIndex: 0,
                users: [],
                request: new EscrowActivitySearchRequestDto(),
                activeRequest: new EscrowActivitySearchRequestDto(),
                gfNo: "",
                errorMessage: "",
                selectionActions: [],
                escrowAccountDataSource: {},
                filtersExpanded: true,
                fileNumberInvalid: false
            };
        },

        created() {
            this.initNonReactiveVariables();
            this.fetchLookupData();
        },

        computed: {
            ...mapGetters([
                "lookupHelpers",
                "lookupItems"
            ]),
            ...mapState({
                user: state => state.authentication.session.user
            }),
            gridInstance() { return _.get(this, "$refs.dataGrid.gridInstance", null); },
            localSecurity(){
                return this.securitySettings.findValues([
                    "IsAdmin",
                    "IsEscrowAdmin",
                    "CheckWriting_ScreenAccess"
                    ]);
            },
            defaultBankCompanyID(){
                let branch = this.lookupHelpers.getBranch(this.user.branchID);
                return _.getNumber(branch, "bankCompanyID", 0);
            },
            tabItems() {
                const self = this;
                return [
                    { automation_id: "btn_tab_activityTitle", name: "activity", title: self.activityTitle, ref: "activityList", checksAndOrDeposits: 0 },
                    { automation_id: "btn_tab_checksTitle", name: "disbursements", title: self.checksTitle, ref: "disbursementsList", checksAndOrDeposits: 2 },
                    { automation_id: "btn_tab_depositsTitle", name: "receipts", title: self.depositsTitle, ref: "receiptsList", checksAndOrDeposits: 1 },
                    { automation_id: "btn_tab_escrowMemosTitle", name: "escrow-memos", title: self.escrowMemosTitle, ref: "escrowMemosList", checksAndOrDeposits: 3 },
                ];
            },
            activeTab() { return this.tabItems[this.tabIndex]; },
            activityTitle(){
                return `All (${this.activity.length})`;
            },
            checksTitle(){
                return `Disbursements (${this.checks.length})`;
            },
            depositsTitle(){
                return `Receipts (${this.deposits.length})`;
            },
            escrowMemosTitle(){
                return `Escrow Memos (${this.escrowMemos.length})`;
            },
            hasFilter(){
                return !_.isEqual(new EscrowActivitySearchRequestDto(this.request), new EscrowActivitySearchRequestDto());
            },
            hasActiveFilter(){
                return !_.isEqual(new EscrowActivitySearchRequestDto(this.activeRequest), new EscrowActivitySearchRequestDto());
            },
            actionFilterDisplay(){
                let itemNames = _.map(this.activeRequest.checkActionIDs, id => _.find(this.actions, { id }).name);
                if (_.size(itemNames) == 0) return "All";
                if (_.size(itemNames) > 2) return `${itemNames.length} Actions Selected`;
                return _.join(itemNames, ", ");
            },
            actionFilterTooltip(){
                let itemNames = _.map(this.activeRequest.checkActionIDs, id => _.find(this.actions, { id }).name);
                if (_.size(itemNames) == 0) return "";
                return _.join(itemNames, ", ");
            },
            dateFilterDisplay(){
                if (_.isNil(_.get(this, "request.dateFrom")) && _.isNil(_.get(this, "request.dateTo"))) {
                    return "";
                }
                return `${this.getDate(this.request.dateFrom, "Anything")} to ${this.getDate(this.request.dateTo, "Anything")}`;
            },
            amountFilterDisplay(){
                if (_.getNumber(this, "request.amountFrom", 0) > 0 && _.getNumber(this, "request.amountTo", 0) > 0) {
                    return `$${this.activeRequest.amountFrom} to $${this.activeRequest.amountTo}`;
                }
                if (_.getNumber(this, "request.amountFrom", 0) == 0 && _.getNumber(this, "request.amountTo", 0) > 0) {
                    return `Anything to $${this.activeRequest.amountTo}`;
                }
                if (_.getNumber(this, "request.amountFrom", 0) > 0 && _.getNumber(this, "request.amountTo", 0) == 0) {
                    return `$${this.activeRequest.amountFrom} to Anything`;
                }
                return "";
            },
            bankFilterDisplay(){
                return _.isNil(this.activeRequest.bankCompanyID) ? "All" : this.lookupHelpers.getLookupItemName(this.lookupItems.ESCROW_ACCOUNTS, this.activeRequest.bankCompanyID);
            },
            personFilterDisplay(){
                return _.isNil(this.activeRequest.usersID) ? "All" : _.find(this.users, ["id", this.activeRequest.usersID]).name;
            },
            readOnly() { return !this.localSecurity.CheckWriting_ScreenAccess; },
            reportOptions() {

                let reportOptions =
                    new ReportOptionsDto({
                    text: "Print",
                    reportPath: "System Reports\\Accounting\\Escrow Activity Report",
                    title: "Escrow Activity",
                    disabled: true,
                    immediate: true,
                    parameters: {
                        p_StartDate:            this.request.dateFrom,
                        p_EndDate:              this.request.dateTo,
                        p_OrderIDs:             this.request.ordersID !== 0 || this.request.ordersID ?  this.request.ordersID : '' ,
                        p_Persons:                this.request.usersID || '',
                        p_Banks:                this.request.bankCompanyID ? this.request.bankCompanyID : '' ,
                        p_ChecksAndOrDeposits:  this.checksAndOrDeposits,
                        p_EscrowActionTypes:     this.request.checkActionIDs.length === 0 ? '' : _.join(this.request.checkActionIDs, ','),
                        p_MinAmount:            this.request.amountFrom || 0,
                        p_MaxAmount:            this.request.amountTo  || 0
                    },
                });

                return reportOptions;
            },
            hasFilterChanged(){
                return !_.isEqual(new EscrowActivitySearchRequestDto(this.activeRequest), new EscrowActivitySearchRequestDto(this.request));
            },
            checksAndOrDeposits() {
                return this.activeTab.checksAndOrDeposits;
            }
        },

        watch: {
            fileNumberInvalid(newValue, oldValue) {
                if(newValue || newValue === oldValue || (this.errorMessage !== INVALID_FILE_ERROR_MESSAGE && this.errorMessage !== DEFAULT_ERROR_MESSAGE)) return;
                this.errorMessage = "";
            },
            "request.gfNo":{
                handler: function(newValue, oldValue) {
                    if(newValue === oldValue) return;
                    this.fileNumberInvalid = false;
                    this.request.ordersID = 0;
                }
            },
        },

        methods: {
            clear() {
                if(!this.gridInstance) return;
                this.request.ordersID = 0;
                this.gridInstance.option("focusedRowIndex", -1);
                this.gridInstance.clearSelection();
            },

            setOrderID(){
                const self = this;
                if (_.isNil(this.request.gfNo) || this.request.gfNo === ""){
                    self.request.ordersID = 0;
                    self.activeRequest.ordersID = 0;
                } else {
                    self.request.ordersID = self.checks.length === 0 ? self.deposits.length === 0 ? self.escrowMemos.length === 0 ? 0 : self.escrowMemos[0].ordersID : self.deposits[0].ordersID : self.checks[0].ordersID;
                    self.activeRequest.ordersID = self.request.ordersID;
                }
            },

            fetchData() {
                const self = this;
                self.errorMessage = "";
                let apiPromise = self.$api.EscrowAccountingApi.getEscrowActivity(self.request);
                self.$rqBusy.wait(apiPromise)
                    .then(result => {
                        self.checks = _.map(result.checks, i => new EscrowCheckActivityDto(i));
                        self.deposits = _.map(result.deposits, i => new EscrowDepositActivityDto(i));
                        self.escrowMemos = _.map(result.escrowMemos, i => new EscrowMemoActivityDto(i));
                        self.users = _.map(result.users, i => new SystemLookupItem(i));
                        self.activeRequest = new EscrowActivitySearchRequestDto(self.request);
                        self.activity =  _.map(_.concat(result.checks, result.deposits), c => new EscrowActivityDto(c));
                        self.setOrderID();
                    })
                    .catch(error => {
                        if (error.errorMessage == INVALID_FILE_ERROR_MESSAGE) {
                            self.fileNumberInvalid = true;
                        } else {
                            self.fileNumberInvalid = false;
                            console.error(error);
                            self.$toast.error({ message: `Error loading Escrow Activity.` });
                        }
                    })
                    .finally(() => {
                        if(_.size(self.request.checkActionIDs) === 1 && self.request.checkActionIDs[0] === CheckActions.ChangedEscrowMemo) {
                            self.tabIndex = self.tabs.EscrowMemos;
                            self.onTabActivated('escrowMemosList');
                        } else {
                            self.tabIndex = self.tabs.All;
                            self.refresh();
                        }
                    });
            },

            fetchLookupData() {
                const self = this;
                let bankCompanyID = _.getNumber(self.request, "bankCompanyID", 0);
                let apiPromise = self.$api.EscrowAccountingApi.getEscrowUsers(bankCompanyID);
                self.$rqBusy.wait(apiPromise)
                    .then(result => {
                        self.users =  _.map(_.filter(result, r => r.id !== 0), i => new SystemLookupItem(i));
                    })
                    .catch(error => {
                        console.error(error);
                        self.$toast.error({ message: `Error loading Escrow Users.` });
                    });
            },

            getDate(dateStr, nullValue = null) {
                return _.isNullOrEmpty(dateStr) ? nullValue : dateStr;
            },

            createDropdownGroup(items) {
                _.each(items, i => {
                    i.additionalIdentity = i.inactive ? 'Inactive' : 'Active';
                });
                return items;
            },

            initNonReactiveVariables() {
                const self = this;
                self.tabs = {All: 0, Checks: 1, Deposits: 2, EscrowMemos: 3};
                self.actions = CheckActions.lookupItems;
                self.escrowBanks = self.createDropdownGroup(self.lookupHelpers.getAllLookupItems(self.lookupItems.USER_ESCROW_ACCOUNTS));
                self.escrowAccountDataSource = new DataSource({
                    store: self.escrowBanks,
                    key: 'id',
                    group: 'additionalIdentity',
                });
                self.request.bankCompanyID = self.activeRequest.bankCompanyID = self.defaultBankCompanyID == 0 ? null : self.defaultBankCompanyID;
            },

            onClearSearch() {
                this.filtersExpanded = true;
                this.request = new EscrowActivitySearchRequestDto();
                this.activeRequest = new EscrowActivitySearchRequestDto();
                this.activity = this.deposits = this.checks = this.escrowMemos = [];
                this.fetchLookupData();
            },

            onEscrowBankChanged(e) {
                if (!this.hasActiveFilter) return;
                this.fetchLookupData();
            },

            onPrintReport() {
                this.showWarningMessage('Coming Soon');
            },

            onSearch() {
                this.errorMessage = "";
                if(!this.hasFilter) {
                    this.errorMessage = "Please enter at least one value to search Escrow Activity";
                    return;
                }
                if(this.fileNumberInvalid) {
                    this.errorMessage = DEFAULT_ERROR_MESSAGE;
                    return;
                }
                this.fetchData();
            },

            onTabActivated(e) {
                this.refresh(e.tab.ref);
            },

            refresh(name) {
                let grid = _.get(this.$refs, name);
                let hasRefresh = _.isFunction(_.get(grid, 'refresh'));
                if (grid && hasRefresh) {
                    grid.refresh();
                }
            },

            showWarningMessage(msg) {
                const self = this;
                let okHandler = (e) => {
                    self.clear();
                }

                self.$dialog.open({
                    title: "WARNING",
                    height: "auto",
                    width: 400,
                    okOnly: true,
                    scrollable: false,
                    autoFocusOkButton: true,
                    component: {
                        name: "MessageBox",
                        template: `<div class="rq-message-box dialog-confirm-message">${msg}</div>`
                    },
                    onOk: okHandler,
                });
                return true;
            },
        }
    }
</script>

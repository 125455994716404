<template>
    <div class="task-documents flush-container content-wrapper">
        <rq-banner
            variant="error"
            icon="fas fa-exclamation-triangle"
            :message="errorMessage"
            :visible="errorMessage.length > 0"
            @dismiss="errorMessage=''"
            dismissable
        />
        <div class="document-upload-form">
            <form enctype="multipart/form-data" novalidate ref="fileForm">
                <div class="dropbox" ondragenter="this.classList.add('dropTarget');" ondragleave="this.classList.remove('dropTarget');" ondrop="this.classList.remove('dropTarget')">
                    <span>{{fileMessage}}</span>
                    <input
                        ref="fileInput"
                        type="file"
                        automation_id="task_documents"
                        name="documents"
                        :disabled="!localSecurity.CanProcessEBankRecon"
                        @change="onFileInputChange"
                        :accept="accepted"
                        class="input-file"
                    />
                </div>
            </form>
        </div>
    </div>
</template>
<script>
    import { mapState, mapGetters } from "vuex";

    export default {
        name:"UploadEBankReconFile",
        props: {
            accepted: { type: String, required: true, default: "" },
            bankCompanyID: { type: Number, required: true, default: null },
        },
        data () {
            return {
                uploadFile: null,
                errorMessage: "",
            };
        },
        computed: {
            ...mapGetters([
                "lookupHelpers",
                "lookupItems"
            ]),
            ...mapState({
                user: state => state.authentication.session.user
            }),
            acceptedFileTypes() { return _.split(this.accepted, ','); },
            localSecurity(){
                return this.securitySettings.findValues(["CanProcessEBankRecon"]);
            },
            fileForm() { return _.get(this.$refs, "fileForm", null) || {}; },
            fileInput() { return _.get(this.$refs, "fileInput", null) || {}; },
            fileMessage() {return _.isNull(this.uploadFile) ? `Drag and drop or click to add a file` : `Selected ${this.uploadFile.name}`}
        },

        watch: {
        },

        created () {
            this.setDialogOkButtonDisabled(true);
        },

        methods: {
            clearForm() {
                this.uploadFile = null;
                if (!this.fileForm) return;
                this.fileForm.reset();
                if (!this.fileInput) return;
                this.fileInput.value = null;
            },

            isValidFileExt(fileExt){
                this.fileUploadInvalid = _.includes(this.acceptedFileTypes, _.toLower(fileExt));
                return this.fileUploadInvalid;
            },

            onFileInputChange (e) {
                if (e.target.files.length <= 0) return;
                this.checkFile(e.target.files[0]);
            },

            checkFile (file) {
                if (!file) return;
                const self = this;
                self.errorMessage = "";
                let fileExt = file.name.substr(file.name.lastIndexOf('.'));
                if (!self.isValidFileExt(fileExt)) {
                    self.errorMessage = `Invalid File Type, expected (${self.accepted})`;
                    self.clearForm();
                    self.setDialogOkButtonDisabled(true);
                    return;
                }
                self.uploadFile = file;
                self.setDialogOkButtonDisabled(false);
                //future use maybe? read file and check the acct number here instead of server-side
                // file.text()
                //     .then(result => {
                //         console.log(result);
                //     })
                //     .catch(error => {
                //         console.error(error);
                //         self.$toast.error({ message: `Error reading ${self.uploadFile.name}.` });
                //     })
                //     .finally(() => {
                //         //self.refresh();
                //     });
            },

            processFile(){
                const self = this;
                self.errorMessage = "";
                const formData = new FormData();
                formData.append("File", self.uploadFile, self.uploadFile.name);
                let apiPromise = self.$api.EBankReconApi.process(self.bankCompanyID, formData);
                return self.$rqBusy.wait(apiPromise);
            },

            setDialogOkButtonDisabled(val) {
                if(val)
                    this.$emit("disable-ok");
                else
                    this.$emit("enable-ok");
            },
        }

    };
</script>

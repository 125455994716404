<template>
    <div class="cm-e-remit-iframe-container" v-if="showIframe">
        <iframe name="cm-e-remit-iframe" id="cm-e-remit-iframe" :src="cmERemitUrl" class="cm-e-remit-iframe" seamless></iframe>
    </div>
</template>
<script>
import DateTimeHelper from "@/shared/utilities/DateTimeHelper";

export default {
    name: "CmERemitForm",
    props: {
    },
    data: function() {
        return {
            showIframe: false,
            showLogin: false,
            cmERemitUrl: "",
            isSubmitted: false
        };
    },
    created() {
        this.init();
    },
    methods:{
        fetchConfigData() {
            const self = this;
            let apiPromise = self.$api.CmERemitApi.getConfiguration();
            return self.$rqBusy.wait(apiPromise);
        },
        init() {
            const self = this;
            self.fetchConfigData()
                .then(data => {
                    let ts = DateTimeHelper.now("MMddyyyy");
                    localStorage.setItem("cm:ERemitConfig", JSON.stringify(data));
                    self.cmERemitUrl = `${window.location.origin}/cmERemit.html?ts=${ts}`;
                    self.showIframe = true;
                })
                .catch(error => {
                    console.error(error);
                });
        },
    }
}
</script>
<style lang="scss">
.cm-e-remit-iframe-container {
    flex: 1;
    overflow: hidden;
    background: #EFEFEF;
    height: 100%;
}
.cm-e-remit-iframe {
    position: relative;
    overflow: hidden;
    height: calc(100vh);
    width: 100%;
    border: none;
    margin: 0;
}
</style>
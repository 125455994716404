<template>
    <div class="content-wrapper">
        <rq-banner
            ref="errorBanner"
            :message="errorMessage"
            variant="error"
            icon="fas fa-exclamation-triangle"
            :visible="errorMessage.length > 0"
        />
        <div class="row pt-1 ps-1 pe-1">
            <div class="col col-12">
                <div class="alert alert-warning">
                    <FontAwesomeIcon icon="fa fa-exclamation-circle" class="alert-icon pe-1" /> Accepting a Wire will set the Deposit Date and remove it from the list.
                </div>
            </div>
        </div>
        <div class="row pe-3 ps-3">
            <div class="col col-6 form-group">
                <label for="dtp_deposit_date">Deposit Date</label>
                <rqdx-date-box
                    id="dtp_deposit_date"
                    :disabled-dates="getDisabledDepositDates"
                    v-model="depositDate"
                />
            </div>
        </div>
    </div>
</template>

<script>
    import { UserSecuritySetting } from "@/shared/models/models";
    import DateTimeHelper from "@/shared/utilities/DateTimeHelper";
    import { DateTime } from "luxon";

    export default {
        name: 'AcceptDepositForm',
        props: {
            ids: {type: Array, required: true, default: () => [] }
        },
        data() {
            return {
                errorMessage: "",
                depositDate: ""
            }
        },
        computed: {
            readOnly() { return !this.getSecuritySetting('IsAdmin', 'permissions') && !this.getSecuritySetting('IsEscrowAdmin', 'permissions'); },
        },
        watch: {
            depositDate(newValue, oldValue) {
                const self = this;
                self.errorMessage = "";
                if(_.isNil(newValue)) {
                    self.errorMessage = "Deposit Date is Required.";
                }
                self.$emit(`${self.errorMessage.length > 0 ? 'disable' : 'enable'}-ok`);
            },
        },
        created() {
            this.depositDate = DateTimeHelper.nowClientStartOfDay('MM/dd/yyyy');
        },
        methods: {
            getDisabledDepositDates(e) {
                let depositPreDateDays = (this.getSecuritySetting('DepositPreDateDays', 'values') || 10)*-1 - 1;
                let depositPostDateDays = (this.getSecuritySetting('DepositPostDateDays', 'values') || 10) + 1;
                let now = DateTime.now().startOf("day");
                let currentDate = DateTime.fromJSDate(e.date).startOf("day");
                let daysDiff = currentDate.diff(now, "days").days;
                return !_.inRange(daysDiff, depositPreDateDays, depositPostDateDays);
            },

            getSecuritySetting(name, type) {
                let perm = _.find(_.get(this.securitySettings, type), {name}) || new UserSecuritySetting();
                return perm.value;
            },

            save(){
                const self = this;
                let depositDate = DateTime.fromFormat(self.depositDate, "MM/dd/yyyy").toFormat("MMddyyyy");
                let apiPromise = self.$api.EscrowAccountingApi.acceptDeposits(depositDate, self.ids);
                return self.$rqBusy.wait(apiPromise);
            },
        }
    }
</script>
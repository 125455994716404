import { MainLayout } from "@/layouts";
import EscrowAccountingMain from "./EscrowAccountingMain.vue";
import Routes from "./routes";
export default {
    path: "/escrow-accounting",
    component: MainLayout,
    meta: { include: false },
    children: [
        {
            name: "es:default",
            path: "",
            redirect: "/escrow-accounting",
            component: EscrowAccountingMain,
            meta: {
                label: "Accounting",
                include: true,
                skipSaveOnLeave: true
            },
            children: Routes
        }
    ]
};
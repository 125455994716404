
const escrowAccountingCheckStatus = {
    All: 0, Voided: 3, StopPay: 4, Cleared: 5, Uncleared: 6, NonVoided: 7, Issued: 1
};
export class EscrowAccountingCheckStatus {
    static get All () {return escrowAccountingCheckStatus.All;}
    static get Voided () {return escrowAccountingCheckStatus.Voided;}
    static get StopPay () {return escrowAccountingCheckStatus.StopPay;}
    static get Cleared () {return escrowAccountingCheckStatus.Cleared;}
    static get Uncleared () {return escrowAccountingCheckStatus.Uncleared;}
    static get NonVoided () {return escrowAccountingCheckStatus.NonVoided;}
    static get Issued () {return escrowAccountingCheckStatus.Issued;}

    static get lookupItems () {
        return _.map(escrowAccountingCheckStatus, c => { return { id: c, name: EscrowAccountingCheckStatus.displayValue(c) }; });
    }

    static displayValue (id) {
        let enumKey = _.findKey(escrowAccountingCheckStatus, val => val === id);
        return (enumKey && _.startCase(enumKey)) || "";
    }
}

const depositClearedStatus = {
    Cleared: 1, Uncleared: 2, All: 3 //None: 0, removed none, because its never used anyway
};
export class DepositClearedStatus {
    // static get None () {return depositClearedStatus.None;}
    static get Cleared () {return depositClearedStatus.Cleared;}
    static get Uncleared () {return depositClearedStatus.Uncleared;}
    static get All () {return depositClearedStatus.All;}

    static get lookupItems () {
        return _.map(depositClearedStatus, c => { return { id: c, name: DepositClearedStatus.displayValue(c) }; });
    }

    static displayValue (id) {
        let enumKey = _.findKey(depositClearedStatus, val => val === id);
        return (enumKey && _.startCase(enumKey)) || "";
    }
}

const checkActions = {
    ChangedAmount: 11,
    ChangedCheckNumber: 14,
    ChangedDate: 1,
    ChangedDescription: 0,
    ChangedEscrowMemo: 23,
    ChangedPayeePayor: 2,
    ChangedTypeFunds: 13,
    ChangedVoidDate: 16,
    Deleted: 3,
    FundsReceived: 10,
    Hold:20,
    PrintWithoutPaper: 9,
    Printed: 6,
    QuickDisbursement: 5,
    QuickReceipt: 19,
    ReceiptID: 17,
    // RemovedDate: 12, //not used
    SentToDocumentMgmt: 18,
    StopPay: 8,
    Undone: 7,
    UndoHold: 21,
    UndoStopPay: 22,
    UnVoided: 15,
    Voided: 4,
};
export class CheckActions {
    static get ChangedDescription () {return depositClearedStatus.ChangedDescription;}
    static get ChangedDate () {return checkActions.ChangedDate;}
    static get ChangedPayeePayor () {return checkActions.ChangedPayeePayor;}
    static get Deleted () {return checkActions.Deleted;}
    static get Voided () {return checkActions.Voided;}
    static get QuickDisbursement () {return checkActions.QuickDisbursement;}
    static get Undone () {return checkActions.Undone;}
    static get PrintWithoutPaper () {return checkActions.PrintWithOutPaper;}
    static get FundsReceived () {return checkActions.FundsReceived;}
    static get ChangedAmount () {return checkActions.ChangedAmount;}
    static get ChangedTypeFunds () {return checkActions.ChangedTypeFunds;}
    static get ChangedCheckNumber () {return checkActions.ChangedCheckNumber;}
    static get UnVoided () {return checkActions.UnVoided;}
    static get ChangedVoidDate () {return checkActions.ChangedVoidDate;}
    static get ReceiptID () {return checkActions.ReceiptID;}
    static get SentToDocumentMgmt () {return checkActions.SentToDocumentMgmt;}
    static get QuickReceipt () {return checkActions.QuickReceipt;}
    static get UndoHold () {return checkActions.UndoHold;}
    static get UndoStopPay () {return checkActions.UndoStopPay;}
    static get ChangedEscrowMemo () {return checkActions.ChangedEscrowMemo;}

    static get lookupItems () {
        return _.map(checkActions, c => { return { id: c, name: CheckActions.displayValue(c) }; });
    }

    static displayValue (id) {
        if (_.isEqual(id, checkActions.ChangedPayeePayor)) return "Changed Payee/Payor";
        if (_.isEqual(id, checkActions.ChangedTypeFunds)) return "Changed Type of Funds";
        if (_.isEqual(id, checkActions.Undone)) return "Undo Check";
        if (_.isEqual(id, checkActions.Voided)) return "Void";
        if (_.isEqual(id, checkActions.UnVoided)) return "Undo Void";
        let enumKey = _.findKey(checkActions, val => val === id);
        return (enumKey && _.startCase(enumKey)) || "";
    }
}

const exportDelimiter = {
    Comma: 1, Tab: 2, Space: 3, None: 4
};
export class ExportDelimiter {
    static get Comma () {return exportDelimiter.Comma;}
    static get Tab () {return exportDelimiter.Tab;}
    static get Space () {return exportDelimiter.Space;}
    static get None () {return exportDelimiter.None;}

    static get lookupItems () {
        return _.map(exportDelimiter, c => { return { id: c, name: ExportDelimiter.displayValue(c) }; });
    }

    static displayValue (id) {
        let enumKey = _.findKey(exportDelimiter, val => val === id);
        return (enumKey && _.startCase(enumKey)) || "";
    }
}
<template>
    <div class="content-wrapper">
        <rqdx-action-data-grid
            ref="dataGrid"
            automation_id="tbl_e_remit_request_hisotry"
            :config="gridConfig"
            class="rq-tab-content-grid"
            :data-source="gridDataSource"
            hide-show-column-chooser
            integrated-search
            :hide-search="items.length == 0"
            :read-only="readOnly"
            rq-filters
            force-floating-header>
        </rqdx-action-data-grid>
    </div>
</template>
<script>
    import DateTimeHelper from "@/shared/utilities/DateTimeHelper";
    export default {
        name: "CmERemitRequestHistoryGrid",
        components: {  },
        props: {
            items: {type: Array, required: true}
        },
        data () {
            return {
                gridConfig: {},
            };
        },

        watch: {
            items:{
                handler: function(newValue, oldValue) {
                    if(!_.isEqual(newValue, oldValue)) {
                        this.refresh();
                    }
                },
                deep: true,
                immediate: false
            },
        },

        created() {
            this.initGridConfig();
        },

        computed: {
            gridInstance() { return _.get(this, "$refs.dataGrid.gridInstance", null); },
            readOnly() { return false; },
        },

        methods: {
            clear() {
                if(!this.gridInstance) return;
                this.gridInstance.option("focusedRowIndex", -1);
                this.gridInstance.clearSelection();
            },

            initGridConfig(){
                const self = this;
                self.gridConfig = {
                    columnHidingEnabled: false,
                    focusedRowEnabled: false,
                    selection: { mode: "none" },
                    paging: { enabled: true },
                    pager: { showPageSizeSelector: true, allowedPageSizes: [50,100,500], showInfo: true},
                    remoteOperations: { sorting: false, paging: false },
                    height: "100%",
                    columns: [
                        {
                            dataField: "id",
                            dataType: "number",
                        },
                        {
                            dataField: "queuedUtc",
                            dataType: "datetime",
                            caption: "Date Submitted",
                            calculateCellValue: function(data) {
                                if (_.isNullOrEmpty(data.queuedUtc)) return "";
                                let date = DateTimeHelper.getDateFromString(data.queuedUtc);
                                if (date.isValid) {
                                    date.setZone("utc");
                                }
                                return DateTimeHelper.toLocalTimeString(date.toFormat("yyyy-MM-dd'T'HH:mm:ss"), "MM/dd/yyyy hh:mm a");
                            },
                        },
                        {
                            dataField: "completedUtc",
                            dataType: "datetime",
                            caption: "Completed Date",
                            calculateCellValue: function(data) {
                                if (_.isNullOrEmpty(data.queuedUtc)) return "";
                                let date = DateTimeHelper.getDateFromString(data.queuedUtc);
                                if (date.isValid) {
                                    date.setZone("utc");
                                }
                                return DateTimeHelper.toLocalTimeString(date.toFormat("yyyy-MM-dd'T'HH:mm:ss"), "MM/dd/yyyy hh:mm a");
                            },
                        },
                        {
                            dataField: "enterpriseName",
                            dataType: "string",
                        },
                        {
                            dataField: "gfNos",
                            dataType: "string",
                            caption: "File Numbers",
                            calculateCellValue: function(data) {
                                if (_.isNullOrEmpty(data.gfNos)) return "";
                                return _.replaceAll(data.gfNos, '*|*', ',');
                            },
                        },
                        {
                            dataField: "status",
                            dataType: "string",
                        },
                        {
                            dataField: "lastMessage",
                            dataType: "string",
                        },
                    ],
                };

                self.gridDataSource = {
                    key: "id",
                    load (loadOptions) {
                        return Promise.resolve(self.items);
                    },
                };
            },

            refresh() {
                if(!this.gridInstance) return;
                this.clear();
                this.gridInstance.refresh();
            },
        }
    }
</script>
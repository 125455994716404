<template>
    <div class="content-wrapper escrow-browser">
        <rq-banner
            variant="error"
            icon="fas fa-exclamation-triangle"
            :message="errorMessage"
            :visible="errorMessage.length > 0"
            @dismiss="errorMessage=''"
            dismissable
        />
        <rq-page-section title="Search Reconciliations" headerSize="lg" class="browser-filter-section" v-model:expanded="filtersExpanded" @keyup.enter="onSearch" collapsible>
            <template #header-actions>
                <transition name="simple-fade">
                    <ul v-if="!filtersExpanded" class="nav browser-filter-display">
                        <li class="nav-item" v-if="bankFilterDisplay != 'All'">
                            <div class="filter-name">Escrow Account:</div>
                            <div class="filter-value">{{bankFilterDisplay}}</div>
                        </li>
                        <li class="nav-item" v-if="dateFilterDisplay.length > 0">
                            <div class="filter-name">Date:</div>
                            <div class="filter-value">{{dateFilterDisplay}}</div>
                        </li>
                        <li class="nav-item" v-if="finalizedFilterDisplay != ''">
                            <div class="filter-name">Include Finalized:</div>
                            <div class="filter-value">{{finalizedFilterDisplay}}</div>
                        </li>
                        <li class="nav-item" v-if="hasActiveFilter">
                            <b-btn
                                variant="link"
                                class="btn-theme"
                                @click="onClearSearch">Clear All Filters
                            </b-btn>
                        </li>
                    </ul>
                </transition>
                <transition name="simple-fade">
                    <ul v-if="filtersExpanded" class="nav ms-auto">
                        <li class="nav-item">
                            <b-btn
                                automation_id="btn_clear"
                                variant="theme"
                                @click="onClearSearch">Clear</b-btn>
                        </li>
                        <li class="nav-item">
                            <b-btn
                                automation_id="btn_search"
                                variant="theme"
                                :disabled="errorMessage.length > 0"
                                @click="onSearch">Search</b-btn>
                        </li>
                    </ul>
                </transition>
            </template>
            <div class="row">
                <div class="col col-12 col-lg-5 col-xl-4 form-group">
                    <label for="dtp_escrow_bank">Escrow Account</label>
                    <dx-select-box
                        :input-attr="{ automation_id: 'dtp_escrow_bank', id: 'dtp_escrow_bank' }"
                        :items="escrowBanks"
                        value-expr="id"
                        display-expr="name"
                        v-model="request.bankCompanyID"
                        placeholder="All Escrow Accounts..."
                        :search-enabled="true"
                        :show-clear-button="true"
                    />
                </div>
                <div class="col col-8 col-lg-5 col-xl-4 form-group">
                    <label for="txt_reconciliation_range">Date Range</label>
                    <rq-date-range
                        id="txt_reconciliation_range"
                        v-model:start-date="request.reconciliationDateFrom"
                        v-model:end-date="request.reconciliationDateTo"
                        match-value="before-clear"
                        match-field="end"
                        show-calendar-drop-downs
                        show-clear-buttons
                        no-calendars
                        borderless
                    />
                </div>
                <div class="col col-4 col-lg-2 form-group pt-4">
                    <b-form-checkbox
                        automation_id="chk_showFinalized"
                        id="showFinalized"
                        v-model="request.completed"
                        >Include Finalized</b-form-checkbox>
                </div>
            </div>
        </rq-page-section>
        <rqdx-action-data-grid
            ref="dataGrid"
            automation_id="tbl_reconciliations"
            :actions="selectionActions"
            :config="gridConfig"
            title="Reconciliations"
            class="grid-container"
            title-size="sm"
            :data-source="gridDataSource"
            @finalize="onFinalizeItem"
            @edit="onEditItem"
            @delete="onDeleteItem"
            @rowDoubleClick="onEditItem"
            export-file-name="reconciliations_data"
            :strikethrough-if-true="['completed']"
            hide-show-column-chooser
            integrated-search
            rq-filters>
            <template #toolbar>
                <ul class="nav">
                    <li class="nav-item" v-rq-tooltip.hover.top="{ title: readOnly ? 'Access Restricted' : 'Add New Reconciliation' }">
                        <b-button
                            automation_id="btn_add"
                            class="btn btn-theme me-1"
                            variant="theme"
                            @click="onAddReconciliation"
                            :disabled="readOnly"
                            >Add
                        </b-button>
                    </li>
                    <li class="nav-item" v-rq-tooltip.html.hover.top :title="reportTooltip">
                        <rq-report-button
                            text="View Reconciliation Deviation Report"
                            :disabled="readOnly || items.length == 0 || !hasValidFilter || hasFilterChanged"
                            :path="reportOptions.reportPath"
                            :name="reportOptions.title"
                            :report-options="reportOptions"
                        />
                    </li>
                </ul>
            </template>
        </rqdx-action-data-grid>
    </div>
</template>

<script>
    import { mapState, mapGetters } from "vuex";
    import { ORDER_ACTIONS } from '@/store/actions';
    import { DateTime } from "luxon";
    import { ReconciliationDto, ReconciliationSearchRequest }  from "../models";
    import { SYSTEM_MUTATIONS } from "@/store/mutations";
    import { ReportOptionsDto } from "@reporting/exago-reports/report-models";
    import ReconciliationAddEditForm from "../components/ReconciliationAddEditForm";
    import DateTimeHelper from "@/shared/utilities/DateTimeHelper";
    import DxGridUtils from "@/shared/utilities/DxGridUtils";

    export default {
        name:"ReconciliationList",
        data () {
            return {
                items: [],
                request: new ReconciliationSearchRequest(),
                errorMessage: "",
                selectionActions: [],
                filtersExpanded: true
            };
        },

        created() {
            this.initNonReactiveVariables();
            this.initGridConfig();
            this.initSearch(this.activeRequest);
        },

        computed: {
            ...mapGetters([
                "lookupHelpers",
                "lookupItems"
            ]),
            ...mapState({
                order: state => state.orders.order,
                user: state => state.authentication.session.user,
                isPageReadOnly: state => state.isPageReadOnly,
                activeRequest: state => state.system.reconciliationSearch,
            }),
            gridInstance() { return _.get(this, "$refs.dataGrid.gridInstance", null); },
            localSecurity(){
                return this.securitySettings.findValues([
                    "AllowFinalizedReconModifications",
                    "CanProcessEBankRecon",
                    "IsAdmin",
                    "IsEscrowAdmin",
                    ]);
            },
            bankFilterDisplay(){
                return _.isNil(this.activeRequest.bankCompanyID) ? "All" : this.lookupHelpers.getLookupItemName(this.lookupItems.ESCROW_ACCOUNTS, this.activeRequest.bankCompanyID);
            },
            dateFilterDisplay(){
                if (_.isNil(_.get(this, "activeRequest.reconciliationDateFrom")) && _.isNil(_.get(this, "activeRequest.reconciliationDateTo"))) {
                    return "";
                }
                return `${this.getDate(this.activeRequest.reconciliationDateFrom, "Anything")} to ${this.getDate(this.activeRequest.reconciliationDateTo, "Anything")}`;
            },
            reportTooltip(){
                return this.hasValidFilter ? '' : 'Bank or Date Range is required to view report';
            },
            userBankCompanyID(){
                let branchID = _.getNumber(this.lookupHelpers.getBranch(this.user.branchID), "bankCompanyID", 0);
                return branchID == 0 ? null : branchID;
            },
            finalizedFilterDisplay(){
                return this.activeRequest.completed ? "Yes" : "No";
            },
            hasFilter(){
                return !_.isEqual(new ReconciliationSearchRequest(this.request), new ReconciliationSearchRequest());
            },
            hasFilterChanged(){
                return !_.isEqual(new ReconciliationSearchRequest(this.activeRequest), new ReconciliationSearchRequest(this.request));
            },
            hasActiveFilter(){
                return !_.isEqual(new ReconciliationSearchRequest(this.activeRequest), new ReconciliationSearchRequest());
            },
            hasValidFilter(){
                if (_.isNil(this.request.bankCompanyID) && _.isNil(this.request.reconciliationDateFrom) && _.isNil(this.request.reconciliationDateTo)) return false;
                return true;
            },
            readOnly() { return this.isPageReadOnly; },
            reportOptions() {
                let reportOptions =
                    new ReportOptionsDto({
                        reportPath: "System Reports\\File Specific\\Reconciliation Exceptions Report",
                        title: 'Reconciliation Exceptions',
                        disabled: true,
                        immediate: true,
                        parameters: {
                            p_Banks:        this.request.bankCompanyID || null,
                            p_StartDate:    this.request.reconciliationDateFrom,
                            p_EndDate:      this.request.reconciliationDateTo,
                            p_IsIncludeFinalized: this.request.completed ? 1 : 0
                        },
                    });
                return reportOptions;
            },
        },

        methods: {
            clear() {
                if(!this.gridInstance) return;
                this.gridInstance.option("focusedRowIndex", -1);
                this.gridInstance.clearSelection();
            },

            fetchData() {
                const self = this;
                let fromDate = _.parseDateString(self.request.reconciliationDateFrom, "minDate", "MMddyyyy");
                let toDate = _.parseDateString(self.request.reconciliationDateTo, "maxDate", "MMddyyyy");
                let bankID = self.request.bankCompanyID || 0;
                let apiPromise = self.$api.ReconciliationApi.getReconciliations(bankID, self.request.completed, fromDate, toDate);
                self.$rqBusy.wait(apiPromise)
                    .then(result => {
                        self.items = _.map(result, i => new ReconciliationDto(i));
                        self.$store.commit(SYSTEM_MUTATIONS.SET_RECONCILIATION_SEARCH, new ReconciliationSearchRequest(self.request));
                    })
                    .catch(error => {
                        console.error(error);
                        self.$toast.error({ message: `Error loading ${self.itemTypeNamePlural}.` });
                    })
                    .finally(() => {
                        self.refresh();
                    });
            },

            getDate(dateStr, nullValue = null) {
                return _.isNullOrEmpty(dateStr) ? nullValue : dateStr;
            },

            initNonReactiveVariables() {
                const self = this;
                self.itemTypeName = "Reconciliation";
                self.itemTypeNamePlural = "Reconciliations";
                self.itemKey = "reconciliationID";
                self.escrowBanks = self.lookupHelpers.getLookupItems(self.lookupItems.USER_ESCROW_ACCOUNTS);
                self.selectionActions = [
                    {
                        name: "edit",
                        text: "Edit",
                        eventName: "edit",
                        requireSelection: true,
                        disabled: e => self.readOnly || (_.parseBool(e.data.completed, false) && !self.localSecurity.AllowFinalizedReconModifications)
                    },
                    {
                        name: "finalize",
                        text: "Finalize",
                        eventName: "finalize",
                        requireSelection: true,
                        allowMultiSelection: true,
                        disabled: e => self.readOnly || _.some(e.data, "completed")
                    },
                    {
                        name: "delete",
                        text: "Delete",
                        eventName: "delete",
                        requireSelection: true,
                        allowMultiSelection: true,
                        tooltip: `Delete ${self.itemTypeName}`,
                        disabled: e => self.readOnly || _.some(e.data, "disableDelete") || (_.some(e.data, "completed") && !self.localSecurity.AllowFinalizedReconModifications)
                    }
                ];
            },

            initGridConfig(){
                const self = this;
                self.gridConfig = {
                    columnHidingEnabled: false,
                    focusedRowEnabled: false,
                    paging: { enabled: true },
                    pager: { showPageSizeSelector: true, allowedPageSizes: [50,100,500], showInfo: true},
                    remoteOperations: { sorting: false, paging: false },
                    height: "100%",
                    columns: [
                        {
                            dataField: "reconciliationID",
                            dataType: "number",
                            caption: "Recon ID",
                        },
                        DxGridUtils.dateColumn({
                            dataField: "reconciliationDate",
                            caption: "Recon Date",
                            sortIndex: 0,
                            sortOrder: "asc"
                        }),
                        {
                            dataField: "bankCompanyID",
                            dataType: "string",
                            caption: "Escrow Account",
                            lookup: {
                                dataSource: self.escrowBanks,
                                valueExpr: "id",
                                displayExpr: "name"
                            },
                        },
                        {
                            dataField: "beginningBalance",
                            dataType: "number",
                            format: {
                                type: "currency",
                                precision: 2
                            },
                        },
                        {
                            dataField: "miscCharges",
                            dataType: "number",
                            format: {
                                type: "currency",
                                precision: 2
                            },
                        },
                        {
                            dataField: "miscCredits",
                            dataType: "number",
                            format: {
                                type: "currency",
                                precision: 2
                            },
                        },
                        {
                            dataField: "receipts",
                            dataType: "number",
                            format: {
                                type: "currency",
                                precision: 2
                            },
                        },
                        {
                            dataField: "disbursements",
                            dataType: "number",
                            format: {
                                type: "currency",
                                precision: 2
                            },
                        },
                        {
                            dataField: "endingBalance",
                            dataType: "number",
                            format: {
                                type: "currency",
                                precision: 2
                            },
                        },
                        {
                            dataField: "workingEndBal",
                            caption: "Working Ending Balance",
                            dataType: "number",
                            format: {
                                type: "currency",
                                precision: 2
                            },
                        },
                        {
                            dataField: "completed",
                            caption: "Finalized",
                            dataType: "boolean",
                            cellTemplate: DxGridUtils.boolCellTemplate,
                        },
                    ],
                };

                self.gridDataSource = {
                    key: self.itemKey,
                    load (loadOptions) {
                        return Promise.resolve(self.items);
                    },
                };
            },

            initSearch(storeRequest) {
                const self = this;

                // If there is any search request history, do the search with the last criteria
                if (!_.isNil(storeRequest)) {
                    self.request = _.clone(self.activeRequest);
                    self.fetchData();
                }
                else{
                    self.request.bankCompanyID = self.userBankCompanyID;
                }
                // Do not search if the user never click search before
            },

            onAddReconciliation(e) {
                this.showAddReconciliationDialog();
            },

            onFinalizeItem(e) {
                const self = this;
                if(self.readOnly || _.some(e.data, "completed")) return;
                if(!e || !e.data) return;
                let items = e.data;
                let onCancel = function (args) {
                    self.refresh();
                }
                let onOk = function (args) {
                    let ids = _.map(items, self.itemKey);

                    let apiPromise = self.$api.ReconciliationApi.finalizeReconciliations(ids);
                    return self.$rqBusy.wait(apiPromise)
                        .then(result => {
                            self.fetchData();
                            return true;
                        })
                        .catch(error => {
                            self.$toast.error({ message: `Error finalizing ${items.length > 1 ? self.itemTypeNamePlural : self.itemTypeName}.` });
                            return true;
                        })
                        .finally(() => {
                            self.refresh();
                        });
                }
                let diffTotal = _.sumBy(items, 'difference');
                let msg = `Are you sure you want to finalize the selected ${items.length > 1 ? self.itemTypeNamePlural : self.itemTypeName}?`;
                if (diffTotal != 0) {
                    msg = msg + '  <b>One or More of them does not match the bank statement.</b>';
                }
                self.$dialog.confirm("Confirm Finalize", msg, onOk, onCancel, { cancelTitle: 'No', okTitle: 'Yes'});
            },

            onDeleteItem(e) {
                const self = this;
                if (self.readOnly || (e.data.completed && !self.localSecurity.AllowFinalizedReconModifications)) return;
                if(!e || !e.data) return;
                let items = e.data;
                let onCancel = function (args) {
                    self.refresh();
                }
                let onOk = function (args) {
                    let ids = _.map(items, self.itemKey);

                    let apiPromise = self.$api.ReconciliationApi.deleteReconciliations(ids);
                    return self.$rqBusy.wait(apiPromise)
                        .then(data => {
                            self.fetchData();//do a full refresh because when you delete a recon that is used as a prior, you need refresh data for the parent recon
                            return true;
                        })
                        .catch(error => {
                            self.$toast.error({ message: `Error deleting ${items.length > 1 ? self.itemTypeNamePlural : self.itemTypeName}.` });
                            return true;
                        });
                }

                self.$dialog.confirm("Confirm Delete", `Are you sure you want to delete the selected ${items.length > 1 ? self.itemTypeNamePlural : self.itemTypeName}?`, onOk, onCancel, { cancelTitle: 'No', okTitle: 'Yes'});
            },

            onEditItem(e) {
                if(!e || !e.data) return;
                this.errorMessage = "";
                if (self.readOnly || (e.data.completed && !this.localSecurity.AllowFinalizedReconModifications)) return;
                this.clear();
                this.$router.push({ name: 'ea:reconciliation', params: { reconciliationID: e.data.reconciliationID }});
            },

            onSearch() {
                this.errorMessage = "";
                this.fetchData();
            },

            onClearSearch() {
                this.request = new ReconciliationSearchRequest();
                this.$store.commit(SYSTEM_MUTATIONS.SET_RECONCILIATION_SEARCH, null);
                this.filtersExpanded = true;
                this.items = [];
                this.refresh();
            },

            refresh() {
                if(!this.gridInstance) return;
                this.clear();
                this.gridInstance.refresh();
            },

            showAddReconciliationDialog() {
                const self = this;
                if(self.readOnly) return;
                let item = new ReconciliationDto({reconciliationDate: DateTime.fromISO(DateTimeHelper.nowClientStartOfDay()).minus({days: 1}).toFormat('MM/dd/yyyy'), createdByUsersID: this.user.usersID, bankCompanyID: this.request.bankCompanyID});
                let onOk = (e) => {
                    return e.component.save()
                        .then(item => {
                            self.refresh();
                            self.$router.push({ name: 'ea:reconciliation', params: { reconciliationID: item.reconciliationID }});
                            return true;
                        })
                        .catch(error => {
                            console.error(error.errorMessage);
                            return false;
                        });
                };
                self.$dialog.open({
                    title: `Add Reconciliation`,
                    height: "auto",
                    width: "500",
                    adaptive: true,
                    closeOnEsc: true,
                    component: ReconciliationAddEditForm,
                    props: {item: item},
                    onOk: onOk
                });
            },
        }
    }
</script>

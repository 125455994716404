<template>
    <div class="content-wrapper escrow-browser">
        <rq-banner
            variant="error"
            icon="fas fa-exclamation-triangle"
            :message="errorMessage"
            :visible="errorMessage.length > 0"
            @dismiss="errorMessage=''"
            dismissable
        />
        <rq-page-section title="Search Open Investment Accounts" headerSize="lg" class="browser-filter-section" v-model:expanded="filtersExpanded" @keyup.enter="onSearch" collapsible>
            <template #header-actions>
                <transition name="simple-fade">
                    <ul v-if="!filtersExpanded" class="nav browser-filter-display">
                        <li class="nav-item" v-if="regionFilterDisplay != 'None'">
                            <div class="filter-name">Region:</div>
                            <div class="filter-value">{{regionFilterDisplay}}</div>
                        </li>
                        <li class="nav-item" v-if="regionFilterDisplay != 'None'">
                            <div class="filter-name">Branch:</div>
                            <div class="filter-value">{{branchFilterDisplay}}</div>
                        </li>
                        <li class="nav-item" v-if="hasActiveFilter">
                            <b-btn
                                variant="link"
                                class="btn-theme"
                                @click="onClearSearch">Reset Filters
                            </b-btn>
                        </li>
                    </ul>
                </transition>
                <transition name="simple-fade">
                    <ul v-if="filtersExpanded" class="nav ms-auto">
                        <li class="nav-item">
                            <b-btn
                                automation_id="btn_clear_search"
                                variant="theme"
                                @click="onClearSearch">Clear</b-btn>
                        </li>
                        <li class="nav-item">
                            <b-btn
                                automation_id="btn_search"
                                variant="theme"
                                :disabled="errorMessage.length > 0"
                                @click="onSearch">Search</b-btn>
                        </li>
                    </ul>
                </transition>
            </template>
            <div class="row">
                <div class="col col-4 form-group form-required">
                    <label for="dtp_region">Region</label>
                    <dx-select-box
                        :input-attr="{ automation_id: 'dtp_region', id: 'dtp_region' }"
                        :items="regions"
                        value-expr="id"
                        display-expr="name"
                        v-model="request.regionID"
                        placeholder="Region..."
                        :search-enabled="true"
                    />
                </div>
                <div class="col col-4 form-group">
                    <label for="dtp_branch">Branch</label>
                    <dx-select-box
                        :input-attr="{ automation_id: 'dtp_branch', id: 'dtp_branch' }"
                        :items="branches"
                        value-expr="id"
                        display-expr="name"
                        v-model="request.branchID"
                        placeholder="Select Branch..."
                        :disabled="request.regionID==null"
                        :search-enabled="true"
                        :show-clear-button="true"
                    />
                </div>
            </div>
        </rq-page-section>
        <rqdx-action-data-grid
            ref="dataGrid"
            automation_id="tbl_open_investment_accounts"
            :actions="selectionActions"
            :config="gridConfig"
            class="grid-container"
            title="Open Investment Accounts"
            title-size="sm"
            :data-source="gridDataSource"
            @edit="onEditItem"
            @rowDoubleClick="onEditItem"
            export-file-name="open_investment_accounts_data"
            integrated-search
            hide-show-column-chooser
            rq-filters>
            <template #toolbar>
                <ul class="nav">
                    <li class="nav-item">
                        <rq-report-button
                            text="View Report"
                            :disabled="restrictAccess || items.length == 0 || hasFilterChanged"
                            :path="reportOptions.path"
                            :name="reportOptions.title"
                            :report-options="reportOptions"
                        />
                    </li>
                </ul>
            </template>
        </rqdx-action-data-grid>
    </div>
</template>

<script>
    import { mapState, mapGetters } from "vuex";
    import { OpenInvestmentDto, OpenInvestmentSearchRequest }  from "../models";
    import DxGridUtils from "@/shared/utilities/DxGridUtils";
    import { UserScreenAccessLevel } from '@/shared/models/enums';
    import InvestmentAccountMaintenance from "../../file/check-writing/views/InvestmentAccountMaintenance";
    import { ReportOptionsDto } from "@reporting/exago-reports/report-models";

    export default {
        name:"OpenInvestmentAccountsList",
        data () {
            return {
                items: [],
                request: new OpenInvestmentSearchRequest(),
                activeRequest: new OpenInvestmentSearchRequest(),
                errorMessage: "",
                filtersExpanded: true,
                selectionActions: [],
            };
        },

        created() {
            this.initNonReactiveVariables();
            this.initGridConfig();
        },

        computed: {
            ...mapState({
                globalRegionId: state => state.system.globalRegionId,
                user: state => state.authentication.session.user,
                allBranches: state => _.get(state, "system.lookups.branches", [])
            }),
            ...mapGetters([
                "lookupHelpers",
                "lookupItems"
            ]),
            gridInstance() { return _.get(this, "$refs.dataGrid.gridInstance", null); },
            hasFilter(){
                return !_.isEqual(new OpenInvestmentSearchRequest(this.request), new OpenInvestmentSearchRequest());
            },
            hasActiveFilter(){
                return !_.isEqual(new OpenInvestmentSearchRequest(this.activeRequest), new OpenInvestmentSearchRequest());
            },
            localSecurity() {
                return this.securitySettings.findValues(["InvestmentAccountIndividual_ScreenAccess"]);
            },
            restrictAccess() { return this.localSecurity.InvestmentAccountIndividual_ScreenAccess === UserScreenAccessLevel.None; },
            regionFilterDisplay(){
                if (_.isNil(this.activeRequest.regionID)) return "None";
                return this.lookupHelpers.getLookupItemName(this.lookupItems.REGIONS, this.activeRequest.regionID);
            },
            branchFilterDisplay(){
                return _.isNil(this.activeRequest.branchID) ? "All" : this.lookupHelpers.getLookupItemName(this.lookupItems.BRANCHES, this.activeRequest.branchID);
            },
            hasFilterChanged(){
                return !_.isEqual(new OpenInvestmentSearchRequest(this.activeRequest), new OpenInvestmentSearchRequest(this.request));
            },
            reportOptions() {
                let reportOptions = new ReportOptionsDto({
                    title: 'Investment Accounts Receipts and Disbursements Ledger',
                    reportPath: "System Reports\\File Specific\\All Investment Accounts Receipts and Disbursements Ledger",
                    parameters: {
                        p_Regions:  this.request.regionID || '',
                        p_Branches:  this.request.branchID || ''
                    }
                });

                return reportOptions;
            },
        },

        watch: {
            "request.regionID" (newValue, oldValue) {
                if(_.isEqual(newValue, oldValue) || _.isNil(oldValue)) return;
                this.request.branchID = _.isEqual(newValue, this.user.regionID) ? this.user.branchID : null;
                this.branches = this.lookupHelpers.getBranches(newValue);
            },
        },

        methods: {
            clear() {
                if(!this.gridInstance) return;
                this.gridInstance.option("focusedRowIndex", -1);
                this.gridInstance.clearSelection();
            },

            fetchData() {
                const self = this;
                let regionID = _.parseNumber(self.request.regionID, 0);
                let branchID = _.parseNumber(self.request.branchID, 0);
                let apiPromise = self.$api.EscrowAccountingApi.getOpenInvestmentAccounts(regionID, branchID);
                self.$rqBusy.wait(apiPromise)
                    .then(result => {
                        self.items = _.map(result, i => new OpenInvestmentDto(i));
                        self.activeRequest = new OpenInvestmentSearchRequest(self.request);
                    })
                    .catch(error => {
                        console.error(error);
                        self.$toast.error({ message: `Error loading ${self.itemTypeNamePlural}.` });
                    })
                    .finally(() => {
                        self.refresh();
                    });
            },

            initNonReactiveVariables() {
                const self = this;
                self.itemTypeName = "Investment Account";
                self.itemTypeNamePlural = "Investment Accounts";
                self.itemKey = "investmentID";
                self.regions = self.lookupHelpers.getLookupItems(self.lookupItems.REGIONS);
                self.setRequestDefaults();
                self.selectionActions = [
                    {
                        name: "edit",
                        text: "Edit",
                        eventName: "edit",
                        requireSelection: true,
                        tooltip: `Edit ${self.itemTypeName}`,
                        disabled: function(e) {
                            if (self.restrictAccess) return "Access Restricted";
                            return false;
                        }
                    },
                ];
            },

            initGridConfig(){
                const self = this;
                self.gridConfig = {
                    columnHidingEnabled: false,
                    focusedRowEnabled: false,
                    paging: { enabled: true },
                    pager: { showPageSizeSelector: true, allowedPageSizes: [50,100,500], showInfo: true},
                    remoteOperations: { sorting: false, paging: false },
                    height: "100%",
                    columns: [
                        {
                            dataField: self.itemKey,
                            visible: false,
                            allowSearch: false,
                            showInColumnChooser: false
                        },
                        {
                            dataField: "gfNo",
                            dataType: "string",
                            caption: "File#"
                        },
                        {
                            dataField: "bankName",
                            dataType: "string"
                        },
                        {
                            dataField: "receipts",
                            dataType: "number",
                            allowEditing: false,
                            format: {
                                type: "currency",
                                precision: 2
                            },
                        },
                        {
                            dataField: "disbursements",
                            dataType: "number",
                            allowEditing: false,
                            format: {
                                type: "currency",
                                precision: 2
                            },
                        },
                        {
                            dataField: "accountBalance",
                            dataType: "number",
                            allowEditing: false,
                            format: {
                                type: "currency",
                                precision: 2
                            },
                        },
                        DxGridUtils.dateColumn({
                            dataField: "lastActivity",
                            allowEditing: false,
                            width: 120
                        }),
                        {
                            dataField: "regionID",
                            dataType: "number",
                            lookup: {
                                dataSource: self.regions,
                                valueExpr: "id",
                                displayExpr: "name"
                            },
                            rqFilter: {
                                disabled: true,
                                disabledTooltip: "Use fields at the top of the page to filter this column."
                            }
                        },
                        {
                            dataField: "branchID",
                            dataType: "number",
                            lookup: {
                                dataSource: self.allBranches,
                                valueExpr: "id",
                                displayExpr: "name"
                            },
                            rqFilter: {
                                disabled: true,
                                disabledTooltip: "Use fields at the top of the page to filter this column."
                            }
                        },
                    ],
                };

                self.gridDataSource = {
                    key: self.itemKey,
                    load (loadOptions) {
                        return Promise.resolve(self.items);
                    },
                };
            },

            onEditItem(e) {
                if(!e || !e.data || this.restrictAccess) return;
                this.errorMessage = "";
                this.showInvestmentAccountMaintenanceDialog(e.data);
            },

            onClearSearch() {
                this.filtersExpanded = true;
                this.request = new OpenInvestmentSearchRequest();
                this.setRequestDefaults();
                this.activeRequest = new OpenInvestmentSearchRequest();
                this.items = [];
                this.refresh();
            },

            onSearch() {
                if(!this.hasFilter) {
                    this.onClearSearch();
                    return;
                }
                this.errorMessage = "";
                this.fetchData();
            },

            refresh() {
                if(!this.gridInstance) return;
                this.clear();
                this.gridInstance.refresh();
            },

            setRequestDefaults() {
                const self = this;
                if (_.getNumber(self, "user.regionID", 0) > 0) {
                    self.request.regionID = self.user.regionID;
                    self.branches = self.lookupHelpers.getBranches(self.request.regionID);
                }
                if (_.getNumber(self, "user.branchID", 0) > 0) {
                    self.request.branchID = self.user.branchID;
                }
            },

            showInvestmentAccountMaintenanceDialog (item) {
                const self = this;
                let onOk = (e) => {
                    self.fetchData();
                };
                let bankCompanyName = self.lookupHelpers.getLookupItemName(self.lookupItems.ESCROW_ACCOUNTS, item.bankCompanyID)
                self.$dialog.open({
                    title: `${item.gfNo}: Manage Investment Accounts`,
                    height: "95%",
                    width: "95%",
                    okTitle: "Close",
                    okOnly: true,
                    resizable: false,
                    component: InvestmentAccountMaintenance,
                    onOk: onOk,
                    props: {ordersID: item.ordersID, bankCompanyName}
                });
            },
        }
    }
</script>
<template>
    <div class="content-wrapper escrow-browser">
        <rq-banner
            variant="error"
            icon="fas fa-exclamation-triangle"
            :message="errorMessage"
            :visible="errorMessage.length > 0"
            @dismiss="errorMessage=''"
            dismissable
        />
        <rq-page-section title="Search Expected Wires" headerSize="lg" class="browser-filter-section" v-model:expanded="filtersExpanded" @keyup.enter="onSearch" collapsible>
            <template #header-actions>
                <transition name="simple-fade">
                    <ul v-if="!filtersExpanded" class="nav browser-filter-display">
                        <li class="nav-item" v-if="dateFilterDisplay.length > 0">
                            <div class="filter-name">Date:</div>
                            <div class="filter-value">{{dateFilterDisplay}}</div>
                        </li>
                        <li class="nav-item" v-if="hasActiveFilter">
                            <b-btn
                                variant="link"
                                class="btn-theme"
                                @click="onClearSearch">Clear All Filters
                            </b-btn>
                        </li>
                    </ul>
                </transition>
                <transition name="simple-fade">
                    <ul v-if="filtersExpanded" class="nav ms-auto">
                        <li class="nav-item">
                            <b-btn
                                automation_id="btn_clear_search"
                                variant="theme"
                                @click="onClearSearch">Clear</b-btn>
                        </li>
                        <li class="nav-item">
                            <b-btn
                                automation_id="btn_search"
                                variant="theme"
                                :disabled="errorMessage.length > 0"
                                @click="onSearch">Search</b-btn>
                        </li>
                    </ul>
                </transition>
            </template>
            <div class="row">
                <div class="col col-12 col-lg-8 col-xl-6 col-pc-4 form-group">
                    <label for="txt_check_date_range">Date Range</label>
                    <rq-date-range
                        id="txt_check_date_range"
                        v-model:start-date="request.expectedWireDateFrom"
                        v-model:end-date="request.expectedWireDateTo"
                        match-value="before-clear"
                        match-field="end"
                        format="MM/dd/yyyy"
                        type="date"
                        show-calendar-drop-downs
                        show-clear-buttons
                        no-calendars
                        borderless
                    />
                </div>
            </div>
        </rq-page-section>
        <rqdx-action-data-grid
            ref="dataGrid"
            automation_id="tbl_expected_wires"
            :actions="selectionActions"
            :config="gridConfig"
            class="grid-container"
            title="Expected Wires"
            title-size="sm"
            :data-source="gridDataSource"
            @accept="onAcceptItem"
            @edit="onEditItem"
            @delete="onDeleteItem"
            @rowDoubleClick="onEditItem"
            export-file-name="expected_wires_data"
            :persist-state="false"
            hide-show-column-chooser
            integrated-search
            rq-filters>
            <template #toolbar>
                <ul class="nav">
                    <li class="nav-item">
                        <rq-report-button
                            text="View Report"
                            :disabled="readOnly || items.length == 0 || hasFilterChanged"
                            :path="reportOptions.path"
                            :name="reportOptions.title"
                            :report-options="reportOptions"
                        />
                    </li>
                </ul>
            </template>
        </rqdx-action-data-grid>
    </div>
</template>

<script>
    import { mapState, mapGetters } from "vuex";
    import { EscrowDepositDto, ExpectedWireSearchRequest }  from "../models";
    import { DateTime } from "luxon";
    import DxGridUtils from "@/shared/utilities/DxGridUtils";
    import AcceptDepositForm from "../components/AcceptDepositForm";
    import QuickDepositForm from "../components/QuickDepositForm";
    import { ReportOptionsDto } from "@reporting/exago-reports/report-models";

    export default {
        name:"ExpectedWiresList",
        data () {
            return {
                items: [],
                request: new ExpectedWireSearchRequest(),
                activeRequest: new ExpectedWireSearchRequest(),
                errorMessage: "",
                filtersExpanded: true,
                selectionActions: [],
                escrowUnits: []
            };
        },

        created() {
            this.initNonReactiveVariables();
            this.initGridConfig();
        },

        watch: {
            "request.expectedWireDateFrom": {
                handler: function(newValue, oldValue) {
                if(newValue === oldValue) return;
                this.activeRequest.expectedWireDateFrom = newValue;
                }
            }
        },

        computed: {
            ...mapGetters([
                "lookupHelpers",
                "lookupItems"
            ]),
            gridInstance() { return _.get(this, "$refs.dataGrid.gridInstance", null); },
            localSecurity(){
                return this.securitySettings.findValues([
                    "AllowDeleteCheckFromEscrowAccounting",
                    "AllowEscrowAdminBrowserChange",
                    "IsAdmin",
                    "IsEscrowAdmin",
                    ]);
            },
            hasFilter(){
                return !_.isEqual(new ExpectedWireSearchRequest(this.request), new ExpectedWireSearchRequest());
            },
            hasActiveFilter(){
                return !_.isEqual(new ExpectedWireSearchRequest(this.activeRequest), new ExpectedWireSearchRequest());
            },
            dateFilterDisplay(){
                if (_.isNil(_.get(this, "activeRequest.expectedWireDateFrom")) && _.isNil(_.get(this, "activeRequest.expectedWireDateTo"))) {
                    return "All";
                }
                return `${this.getDate(this.activeRequest.expectedWireDateFrom, "Anything")} to ${this.getDate(this.activeRequest.expectedWireDateTo, "Anything")}`;
            },
            readOnly() { return !this.localSecurity.IsAdmin && !this.localSecurity.IsEscrowAdmin; },
            hasFilterChanged(){
                return !_.isEqual(new ExpectedWireSearchRequest(this.activeRequest), new ExpectedWireSearchRequest(this.request));
            },
            reportOptions() {
                let reportOptions =
                    new ReportOptionsDto({
                    text: "Print",
                    reportPath: "System Reports\\Accounting\\Expected Wires In",
                    title: "Expected Wires In",
                    disabled: true,
                    immediate: true,
                    parameters: {
                        p_StartDate: this.request.expectedWireDateFrom,
                        p_EndDate: this.request.expectedWireDateTo,
                    },
                });

                return reportOptions;
            }
        },

        methods: {
            clear() {
                if(!this.gridInstance) return;
                this.gridInstance.option("focusedRowIndex", -1);
                this.gridInstance.clearSelection();
            },

            fetchData() {
                const self = this;
                let fromDate = _.isNil(self.request.expectedWireDateFrom) ? "minDate" : DateTime.fromFormat(self.request.expectedWireDateFrom, "MM/dd/yyyy").toFormat('MMddyyyy');
                let toDate = _.isNil(self.request.expectedWireDateTo) ? "maxDate" : DateTime.fromFormat(self.request.expectedWireDateTo, "MM/dd/yyyy").toFormat('MMddyyyy');
                let apiPromise = self.$api.EscrowAccountingApi.getExpectedWires(fromDate, toDate);
                self.$rqBusy.wait(apiPromise)
                    .then(result => {
                        self.items = _.map(result, i => new EscrowDepositDto(i));
                        self.activeRequest = new ExpectedWireSearchRequest(self.request, true);
                    })
                    .catch(error => {
                        console.error(error);
                        self.$toast.error({ message: `Error loading Expected Wires.` });
                    })
                    .finally(() => {
                        self.refresh();
                    });
            },

            getDate(dateStr, nullValue = null) {
                return _.isNullOrEmpty(dateStr) ? nullValue : dateStr;
            },

            initNonReactiveVariables() {
                const self = this;
                self.itemTypeName = "Expected Wire";
                self.itemTypeNamePlural = "Expected Wires";
                self.itemKey = "depositID";
                self.escrowBanks = self.lookupHelpers.getLookupItems(self.lookupItems.USER_ESCROW_ACCOUNTS);
                self.escrowUnits = self.lookupHelpers.getLookupItems(self.lookupItems.ESCROW_UNITS);
                self.selectionActions = [
                    {
                        name: "accept",
                        text: "Accept",
                        eventName: "accept",
                        requireSelection: true,
                        allowMultiSelection: true,
                        disabled: function(e) {
                            if (_.some(e.data, ['isLocked', true]) || _.some(e.data, ['isEscrowLocked', true])) return "One or more Order is locked or Escrow is locked.";
                            if (self.readOnly) return "Access Restricted";
                            if (!_.some(e.data, ['reconciliationID', null])) return "One or more deposit has been reconciled.";
                            return false;
                        }
                    },
                    {
                        name: "edit",
                        text: "Edit",
                        eventName: "edit",
                        requireSelection: true,
                        disabled: function(e) {
                            if (e.data.isLocked || e.data.isEscrowLocked) return "Order is locked or Escrow is locked.";
                            if (!self.localSecurity.AllowEscrowAdminBrowserChange) return "Access Restricted";
                            return self.readOnly;
                        }
                    },
                    {
                        name: "delete",
                        text: "Delete",
                        eventName: "delete",
                        requireSelection: true,
                        allowMultiSelection: true,
                        tooltip: `Delete ${self.itemTypeName}`,
                        disabled: function(e) {
                            if (e.data.isLocked || e.data.isEscrowLocked) return "Order is locked or Escrow is locked.";
                            if (!self.localSecurity.AllowDeleteCheckFromEscrowAccounting) return "Access Restricted";
                            return false;
                        }
                    },
                ];
            },

            initGridConfig(){
                const self = this;
                self.gridConfig = {
                    columnHidingEnabled: false,
                    focusedRowEnabled: false,
                    paging: { enabled: true },
                    pager: { showPageSizeSelector: true, allowedPageSizes: [50,100,500], showInfo: true},
                    remoteOperations: { sorting: false, paging: false },
                    height: "100%",
                    columns: [
                        {
                            dataField: "gfNo",
                            dataType: "string",
                            caption: "File #",
                        },
                        {
                            dataField: "payor",
                            dataType: "string",
                            caption: "Payor",
                        },
                        {
                            dataField: "amount",
                            dataType: "number",
                            format: {
                                type: "currency",
                                precision: 2
                            },
                        },
                        {
                            dataField: "description",
                            dataType: "string",
                            caption: "Description",
                        },
                        DxGridUtils.dateColumn({
                            dataField: "expectedWireDate",
                            sortIndex: 0,
                            sortOrder: "asc",
                        }),
                        {
                            dataField: "wireNumber",
                            dataType: "string",
                            caption: "Wire #",
                        },
                        {
                            dataField: "bankCompanyID",
                            dataType: "number",
                            caption: "Escrow Account",
                            lookup: {
                                dataSource: self.escrowBanks,
                                valueExpr: "id",
                                displayExpr: "name"
                            },
                            editorOptions: { readOnly: true },
                        },
                        {
                            dataField: "isLocked",
                            dataType: "boolean",
                            cellTemplate: DxGridUtils.boolCellTemplate,
                        },
                        {
                            dataField: "isEscrowLocked",
                            dataType: "boolean",
                            cellTemplate: DxGridUtils.boolCellTemplate,
                        },
                        {
                            dataField: "escrowUnitID",
                            dataType: "string",
                            caption: "Escrow Unit #",
                            lookup:
                            {  dataSource: self.escrowUnits,
                                valueExpr: "id",
                                displayExpr: "name"
                            },
                        },
                        {
                            dataField: "depositID",
                            dataType: "string",
                            caption: "Deposit ID",
                        },
                    ],
                };

                self.gridDataSource = {
                    key: self.itemKey,
                    load (loadOptions) {
                        return Promise.resolve(self.items);
                    },
                };
            },

            onAcceptItem(e) {
                if(!e || !e.data) return;
                this.errorMessage = "";
                this.showAcceptDepositsDialog(e.data);
            },

            onDeleteItem(e) {
                if(!e || !e.data) return;
                const self = this;
                if (!self.localSecurity.AllowDeleteCheckFromEscrowAccounting) return;
                let items = e.data;
                let onCancel = function (args) {
                    self.refresh();
                }
                let onOk = function (args) {
                    let ids = _.map(items, self.itemKey);

                    let apiPromise = self.$api.EscrowAccountingApi.deleteDeposits(ids);
                    return self.$rqBusy.wait(apiPromise)
                        .then(data => {
                            _.pullAllBy(self.items, items, self.itemKey);
                            return true;
                        })
                        .catch(error => {
                            self.$toast.error({ message: `Error deleting ${items.length > 1 ? self.itemTypeNamePlural : self.itemTypeName}.` });
                            return true;
                        })
                        .finally(() => {
                            self.refresh();
                        });
                }

                self.$dialog.confirm("Confirm Delete", `Are you sure you want to delete the selected ${items.length > 1 ? self.itemTypeNamePlural : self.itemTypeName}?`, onOk, onCancel, { cancelTitle: 'No', okTitle: 'Yes'});
            },

            onEditItem(e) {
                if(!e || !e.data || this.readOnly) return;
                this.errorMessage = "";
                this.showQuickDepositDialog(e.data);
            },

            onSearch() {
                this.errorMessage = "";
                this.fetchData();
            },

            onClearSearch() {
                this.filtersExpanded = true;
                this.request = new ExpectedWireSearchRequest();
                this.activeRequest = new ExpectedWireSearchRequest();
                this.items = [];
                this.refresh();
            },

            refresh() {
                this.clear();
                this.gridInstance.refresh();
            },

            showAcceptDepositsDialog(items) {
                const self = this;
                if (self.readOnly) return;
                let ids = _.map(items, self.itemKey);
                let onOk = (e) => {
                    if (_.isNil(e.component.depositDate)) {
                        e.component.errorMessage = `Deposit Date is required.`;
                        return false;
                    }
                    e.component.save()
                        .then(result => {
                            self.fetchData();
                            return true;
                        })
                        .catch(error => {
                            self.$toast.error({ message: `Error accepting deposits.` });
                            return true;
                        });
                };
                let onCancel = (e) => {
                    self.refresh();
                };
                self.$dialog.open({
                    title: `Accept Desposit${ids.length == 1 ? '' : 's'}`,
                    height: "auto",
                    width: "550",
                    adaptive: true,
                    closeOnEsc: true,
                    component: AcceptDepositForm,
                    props: {
                        ids: ids
                    },
                    onOk: onOk,
                    onCancel: onCancel
                });
            },

            showQuickDepositDialog(item) {
                const self = this;
                if (!self.localSecurity.AllowEscrowAdminBrowserChange) return;
                let onOk = (e) => {
                    let form = e.component;
                    return form.save()
                        .then(result => {
                            self.fetchData();
                            return true;
                        }).catch(e => {
                            console.log(e.errorMessage);
                            form.errorMessage = e.errorMessage;
                            return false;
                        })
                        .finally(() => {
                            self.refresh();
                        });
                };
                let onCancel = e => {
                    self.refresh();
                };
                self.$dialog.open({
                    title: `Edit/Accept Deposit`,
                    height: "auto",
                    width: "800",
                    adaptive: true,
                    closeOnEsc: true,
                    component: QuickDepositForm,
                    onOk: onOk,
                    onCancel: onCancel,
                    props: {
                        deposit: item
                    },
                });
            },
        }
    }
</script>
<template>
    <rqdx-action-data-grid
        ref="dataGrid"
        automation_id="tbl_reconciliation_balance_sheet"
        :actions="selectionActions"
        :config="gridConfig"
        class="rq-tab-content-grid"
        :data-source="gridDataSource"
        :read-only="readOnly"
        @rowDoubleClick="onGotoFile"
        export-file-name="reconciliation_balance_sheet_data"
        integrated-search
        hide-settings
    />
</template>
<script>
    import DxGridUtils from "@/shared/utilities/DxGridUtils";
    export default {
        name:"ReconciliationBalanceSheetList",
        props: {
            reconciliation: { type: Object, required: true, default: () => ({}) },
            items: { type: Array, default: () => [] },
        },
        data () {
            return {
                errorMessage: "",
                selectionActions: [],
            };
        },

        watch: {
            items:{
                handler: function(newValue, oldValue) {
                    if(!_.isEqual(newValue, oldValue)) {
                        this.refresh();
                    }
                },
                deep: true,
                immediate: false
            },
            errorMessage(newValue, oldValue) {
                if(_.isEqual(newValue, oldValue)) return;
                this.$emit("update-error-message", this.errorMessage);
            },
        },

        created() {
            this.initGridConfig();
        },

        computed: {
            gridInstance() { return _.get(this, "$refs.dataGrid.gridInstance", null); },
            readOnly() { return _.parseBool(this.reconciliation.readOnly, false); },
            localSecurity(){
                return this.securitySettings.findValues([
                    "CheckWriting_ScreenAccess"
                    ]);
            },
        },

        methods: {
            clear() {
                if(!this.gridInstance) return;
                this.gridInstance.option("focusedRowIndex", -1);
                this.gridInstance.clearSelection();
            },

            initGridConfig(){
                const self = this;
                self.gridConfig = {
                    allowColumnReordering: false,
                    focusedRowEnabled: false,
                    paging: { enabled: true },
                    pager: { showPageSizeSelector: true, allowedPageSizes: [50,100,500], showInfo: true},
                    remoteOperations: { sorting: false, paging: false },
                    selection: { mode: "none", showCheckBoxesMode: "never" },
                    wordWrapEnabled: true,
                    height: "100%",
                    columns: [
                        {
                            dataField: "number",
                            dataType: "string",
                            caption: "Check/Transaction#",
                        },
                        {
                            dataField: "gfNo",
                            dataType: "string",
                            caption: "File Number",
                        },
                        DxGridUtils.dateColumn({
                            allowReordering: true,
                            dataField: "date",
                        }),
                        {
                            allowReordering: true,
                            dataField: "description",
                            dataType: "string",
                            caption: "Payee/Description",
                        },
                        {
                            caption: "Receipt Amount",
                            dataField: "depositAmount",
                            dataType: "number",
                            format: {
                                type: "currency",
                                precision: 2
                            },
                            width: 120,
                            minWidth: 120,
                        },
                        {
                            caption: "Disbursement Amount",
                            dataField: "checkAmount",
                            dataType: "number",
                            format: {
                                type: "currency",
                                precision: 2
                            },
                            width: 120,
                            minWidth: 120,
                        },
                    ],
                    summary: {
                        totalItems: [
                            {
                                name: "TotalChecksLabel",
                                column: "number",
                                alignment: "left",
                                displayFormat: "TOTAL",
                                cssClass: "rq-summary-label",
                                summaryType: "sum"
                            },
                            {
                                name: "TotalChecks",
                                column: "checkAmount",
                                alignment: "right",
                                valueFormat: {
                                    type: "currency",
                                    precision: 2
                                },
                                displayFormat: "{0}",
                                summaryType: "sum"
                            },
                            {
                                name: "TotalDeposit",
                                column: "depositAmount",
                                alignment: "right",
                                valueFormat: {
                                    type: "currency",
                                    precision: 2
                                },
                                displayFormat: "{0}",
                                summaryType: "sum"
                            },
                        ]
                    },
                };

                self.gridDataSource = {
                    key: "id",
                    load (loadOptions) {
                        return Promise.resolve(self.items);
                    },
                };
            },

            onGotoFile(e) {
                if(!e || !e.data) return;
                let orderId = _.parseNumber(e.data.ordersID, 0);
                let tab = _.parseNumber(e.data.isCheck, false) ? 2 : 1;
                if(this.localSecurity.CheckWriting_ScreenAccess) {
                    this.$router.push({ name: "check-writing", params: { orderId, tab } });
                } else {
                    this.$router.push({ name: "oe:main", params: { orderId } });
                }
            },

            refresh() {
                if(!this.gridInstance) return;
                this.clear();
                this.gridInstance.refresh();
                this.gridInstance.updateDimensions();
            },

            updateDimensions() {
                this.$nextTick(() => {
                    _.invoke(this, "gridInstance.updateDimensions");
                });
            },
        }
    }
</script>
<style lang="scss">
.rq-tab-content-grid-wrapper {
    padding-top: 5px
}
</style>
<template>
    <div class="content-wrapper">
        <rq-banner
            ref="errorBanner"
            :message="errorMessage"
            variant="error"
            icon="fas fa-exclamation-triangle"
            :visible="errorMessage.length > 0"
        />
        <fieldset class="pe-3 ps-3">
            <div class="row">
                <div class="col col-4 form-group">
                    <file-number-input
                        automation_id="txt_gfno"
                        ref="fileNumber"
                        label="File #"
                        v-model="item.gfNo"
                        v-model:order-id="item.ordersID"
                        v-model:has-error="fileNumberInvalid"
                        :disabled="readOnly || hasReconciliationID"
                        :show-clear-button="!hasReconciliationID"
                        required
                        show-search-button
                    />
                </div>
                <div :class="{ 'col col-4 form-group': true, 'form-required': isDateRequired, 'has-error': v$.item.depositDate.$error }">
                    <label for="dtp_deposit_date">Deposit Date</label>
                    <div v-rq-tooltip.bottom.hover :title="disableDateTooltip">
                        <rqdx-date-box
                            id="dtp_deposit_date"
                            :disabled="readOnly || hasReconciliationID || !hasOrdersID || disableDates"
                            :disabled-dates="getDisabledDepositDates"
                            @valueChanged="onDepositDateChanged"
                            v-model="v$.item.depositDate.$model"
                        />
                        <rq-validation-feedback
                            :container="tooltipContainer"
                            :boundary="tooltipContainer"
                            :messages="{
                                'Deposit Date is required': v$.item.depositDate.required.$invalid,
                                'Date is not within range': v$.item.depositDate.isInRange.$invalid,
                                }"
                        />
                    </div>
                </div>
                <div :class="{ 'col col-4 form-group':true, 'form-required': hasDepositDate, 'has-error': v$.item.typeFundID.$error }">
                    <label for="cmb_type_fund_id">Type of Funds</label>
                    <dx-select-box
                        :input-attr="{ automation_id: 'drp_typeFundID', id: 'drp_typeFundID' }"
                        :items="excludedFundTypeList"
                        :disabled="readOnly || hasReconciliationID || !hasOrdersID || isFundTypeExcluded"
                        value-expr="id"
                        display-expr="name"
                        v-model="v$.item.typeFundID.$model"
                        :search-enabled="true"
                    />
                    <rq-validation-feedback
                        :container="tooltipContainer"
                        :boundary="tooltipContainer"
                        :messages="{ 'Invalid Type of Funds': v$.item.typeFundID.isValid.$invalid,
                                     'Type of Funds selection is required': v$.item.typeFundID.required.$invalid
                                    }"
                    />
                </div>
            </div>
            <div class="row mt-n3">
                <div class="col col-8 form-group">
                    <label class="form-control-label" for="txt_payor">Payor</label>
                    <input automation_id="txt_payor"
                        class="form-control"
                        type="text"
                        v-model="item.payor"
                        :disabled="readOnly || hasReconciliationID || !hasOrdersID"
                        maxlength="200"
                        />
                </div>
                <div :class="{ 'col col-4 form-group':true, 'has-error':v$.item.amount.$error }">
                    <!-- <label for="txt_amount">Amount</label> -->
                    <rq-action-form-group
                        action-class="overridden"
                        label="Amount"
                        labelFor="txt_amount"
                        :show-action="useBankAmount"
                        @action-click="onUseBankAmount"
                        action-automation-id="btn_txt_amount_use_bank"
                        action-label="Use Bank Amount"
                    >
                        <template #default>
                            <rq-input-number
                                automation_id="txt_amount"
                                formatType="money"
                                defaultValue="0"
                                :minValue="0"
                                :disabled="readOnly || hasReconciliationID || !hasOrdersID"
                                decimals="2"
                                input-group
                                no-prefix
                                prependIcon="fas fa-dollar-sign"
                                v-model="v$.item.amount.$model"
                                />
                                <rq-validation-feedback
                                    :container="tooltipContainer"
                                    :boundary="tooltipContainer"
                                    :messages="{ 'Amount is required': v$.item.amount.required.$invalid,
                                                'Amount must be greater than Zero': !v$.item.amount.isGreaterThanZero,
                                                }"
                                />
                        </template>
                    </rq-action-form-group>
                </div>
            </div>
            <div class="row">
                <div class="col-12 form-group">
                    <label class="form-control-label" for="txt_description">Description</label>
                    <input automation_id="txt_description"
                        class="form-control"
                        type="text"
                        :disabled="readOnly || hasReconciliationID || !hasOrdersID"
                        v-model="item.description"
                        maxlength="60"
                        />
                </div>
            </div>
            <div class="row">
                <div class="col col-4 form-group">
                    <label for="dtp_verified_date">Verified Date</label>
                    <rqdx-date-box
                        id="dtp_verified_date"
                        v-model="item.depositVerified"
                        :disabled="readOnly || hasReconciliationID || !hasOrdersID"
                    />
                </div>
                <div class="col col-4 form-group">
                    <label for="cmb_reconciliation_id">Reconciliation</label>
                    <div v-rq-tooltip.bottom.hover :title="disableDateTooltip">
                        <dx-select-box
                            :input-attr="{ automation_id: 'drp_reconciliationID', id: 'drp_reconciliationID' }"
                            :items="reconciliations"
                            value-expr="id"
                            display-expr="name"
                            v-model="item.reconciliationID"
                            :wrap-item-text="true"
                            :show-clear-button="true"
                            :disabled="readOnly || disableDates || !hasOrdersID || isNew"
                            :search-enabled="true"
                            @valueChanged="onReconciliationChanged"
                        />
                    </div>
                </div>
                <div class="col col-4 form-group">
                    <label for="txt_wire_number">Wire #</label>
                    <input
                        automation_id="txt_wire_number"
                        v-model="item.wireNumber"
                        :disabled="readOnly || hasReconciliationID || !hasOrdersID || !isWire"
                        type="text"
                        class="form-control"
                        placeholder="Wire #"
                        maxlength="15">
                </div>
            </div>
            <div class="row">
                <div class="col col-6 form-group">
                    <label for="txt_fed_reference_number">Fed Reference No.</label>
                    <input
                        automation_id="txt_fed_reference_number"
                        v-model="item.fedRefNumber"
                        :disabled="readOnly"
                        type="text"
                        class="form-control">
                </div>
                <div class="col col-6 form-group">
                    <label for="cmb_deposit_slip_id">Deposit Slip</label>
                    <dx-select-box
                        :input-attr="{ automation_id: 'drp_deposit_slip_id', id: 'cmb_deposit_slip_id' }"
                        :items="depositSlips"
                        :disabled="readOnly || hasReconciliationID || !hasOrdersID || hasdepositSlipAccess || isNew"
                        :config="{wrapItemText: true, minWidth: 400}"
                        value-expr="id"
                        display-expr="name"
                        v-model="item.depositSlipID"
                        :search-enabled="true"
                    />
                </div>
            </div>
            <div class="row">
                <div class="col col-6 form-group">
                    <label for="cmb_transfer_slip_id">Transfer Slip</label>
                    <dx-select-box
                        :input-attr="{ automation_id: 'drp_transferSlip', id: 'cmb_transfer_slip_id' }"
                        :items="transferSlips"
                        :disabled="readOnly"
                        :config="{wrapItemText: true, minWidth: 400}"
                        value-expr="id"
                        display-expr="name"
                        v-model="item.transferSlipID"
                        :search-enabled="true"
                    />
                </div>
            </div>
        </fieldset>
    </div>
</template>

<script>
    import { mapGetters, mapState } from "vuex";
    import { DateTime } from "luxon";
    import { SystemLookupItem } from "@/shared/models/models";
    import { DepositStatus, FundOption } from '../../file/check-writing/enums';
    import FileNumberInput from "@/shared/components/rq/FileNumberInput";
    import { required, requiredIf } from '@vuelidate/validators';
    import { useVuelidate } from "@vuelidate/core";
    import { UserScreenAccessLevel } from "@/shared/models/enums";
    const FINALIZED_RECON_WARNING = "Item is dated within a finalized reconciliation time period and will recalculate affected finalized reconciliations.";

    export default {
        name: 'QuickDepositForm',
        props: {
            deposit: {type: Object, required: true},
            bankAmount: {type: Number, required: false}
        },
        components: { FileNumberInput },
        inject: ["dialogId"],
        setup: () => ({ v$: useVuelidate() }),
        data() {
            return {
                errorMessage: "",
                original: {},
                item: {},
                fundTypes: [],
                reconciliations: [],
                transferSlips: [],
                depositSlips: [],
                fileNumberInvalid: false,
                originalDisableDates: false
            }
        },
        watch: {
            "item.ordersID":{
                handler: function(newValue, oldValue) {
                    if (_.isEqual(newValue, oldValue) || _.isUndefined(oldValue)) return;
                    if (_.isNil(newValue)) {
                        this.clearOrderInfo();
                        if (this.warnFinalRecon) {
                            this.errorMessage = FINALIZED_RECON_WARNING;
                        }
                    } else {
                        if (_.parseNumber(newValue, 0) !== _.parseNumber(oldValue, 0)) {
                            this.fetchLookupData(newValue, "order");
                        }
                    }
                }
            },
            "item.amount":{
                handler: function(newValue, oldValue) {
                    if (_.isEqual(newValue, oldValue) || _.isUndefined(oldValue)) return;
                    if (!_.isEqual(newValue, this.original.amount) && this.warnFinalRecon) {
                        this.errorMessage = FINALIZED_RECON_WARNING;
                    } else {
                        this.errorMessage = "";
                    }
                }
            },
        },
        computed: {
            ...mapGetters([
                "lookupHelpers",
                "lookupItems"
            ]),
            warnFinalRecon() {
                return (!this.isNew && !_.isNil(this.original.depositDate) && this.localSecurity.AllowFinalizedReconModifications && DateTime.fromISO(this.original.depositDate).diff(DateTime.fromISO(this.original.lastFinalizedReconDate), "days").days <= 0)
                    || (this.isNew && !_.isNil(this.item.depositDate) && this.localSecurity.AllowFinalizedReconModifications && DateTime.fromISO(this.item.depositDate).diff(DateTime.fromISO(this.item.lastFinalizedReconDate), "days").days <= 0);
            },
            disableDates() { return !this.isNew && this.isCleared && !_.isNil(this.item.depositDate) && !this.localSecurity.AllowFinalizedReconModifications && DateTime.fromISO(this.item.depositDate).diff(DateTime.fromISO(this.item.lastFinalizedReconDate), "days").days <= 0; },
            disableDateTooltip() { return this.disableDates ? "Access Restricted" : ""; },
            hasReconciliationID() { return _.getNumber(this, 'item.reconciliationID', 0) > 0 || !_.isNil(this.item.reconciliationDate); },
            hadReconciliationID() { return _.getNumber(this, 'original.reconciliationID', 0) > 0 || !_.isNil(this.original.reconciliationDate); },
            hasChanges() { return _.size(this.getAuditChanges(this.original, this.item, ["gfNo"])) > 0; },
            hasDepositDate() { return !_.isNullOrEmpty(this.item.depositDate); },
            hasOrdersID() { return _.getNumber(this, 'item.ordersID', 0) > 0; },
            useBankAmount() { return _.getNumber(this, 'bankAmount', 0) > 0 && !_.isEqual(_.getNumber(this, 'bankAmount', 0), _.getNumber(this, 'item.amount', 0)); },
            isDateRequired() { return _.getNumber(this, 'item.typeFundID', 0) > FundOption.None; },
            isCleared() { return _.getNumber(this, 'item.depositStatus', 0) === DepositStatus.Reconciled; },
            isNew() { return _.getNumber(this, 'item.depositID', 0) == 0; },
            isFundTypeExcluded() { var excludedIDs = [8,9,10,11]; return !this.isNew && (_.includes(excludedIDs, (_.getNumber(this,'item.typeFundID',0))))},
            excludedFundTypeList() { if(this.isNew || !this.isFundTypeExcluded){return _.filter(this.fundTypes, ft => { return (ft.id < 8  || ft.id > 11); });} else {return this.fundTypes}},
            isWire() { return _.getNumber(this, 'item.typeFundID', 0) == FundOption.Wire; },
            restrictDepositDateToRecon() { return !this.isNew && !this.isCleared && this.localSecurity.AllowEscrowAdminBrowserChange && !this.localSecurity.AllowFinalizedReconModifications && this.localSecurity.DepositPreDateDays === -1 && this.localSecurity.DepositPostDateDays === -1; },
            localSecurity(){
                return this.securitySettings.findValues([
                    "IsAdmin",
                    "IsEscrowAdmin",
                    "AllowEscrowAdminBrowserChange",
                    "AllowFinalizedReconModifications",
                    "DepositPreDateDays",
                    "DepositPostDateDays",
                    "DepositSlip_ScreenAccess"
                    ]);
            },
            readOnly() { return !this.localSecurity.AllowEscrowAdminBrowserChange; },
            hasdepositSlipAccess(){ return this.localSecurity.DepositSlip_ScreenAccess === UserScreenAccessLevel.None || this.localSecurity.DepositSlip_ScreenAccess === UserScreenAccessLevel.Read;},
            tooltipContainer() { return `#${this.dialogId}`; },
        },
        created(){
            this.original = _.clone(this.deposit);
            this.item = _.clone(this.deposit);
            this.originalDisableDates = this.disableDates;
            this.fundTypes = this.lookupHelpers.getLookupItems(this.lookupItems.TYPE_FUNDS);
            if (!this.isNew) {
                this.fetchLookupData(this.item.depositID, "deposit");
            }
        },
        validations() {
            const self = this;

            var result = {
                item: {
                    gfNo: { required },
                    ordersID: { required },
                    typeFundID: {
                        required,
                        isValid(val) {
                            if (_.isNil(self.item.depositDate)) {
                                return _.isEqual(val, 0);
                            } else {
                                return !_.isEqual(val, 0);
                            }
                        }
                    },
                    bankCompanyID: { required },
                    amount: {
                        required,
                        isGreaterThanZero(val) {
                            return _.gt(val, 0);
                        }
                    },
                    depositDate: {
                        required:  requiredIf(function (vm) { return self.isDateRequired; }),
                        isInRange(val) {
                            return !(self.isDateRequired && self.isDepositDateInRange(val));
                        }
                    }
                }
            }
            return result;
        },
        methods: {
            clearOrderInfo() {
                this.item.ordersID = null;
                this.item.gfNo = null;
                this.v$.item.ordersID.$touch();
            },

            fetchLookupData(id, type) {
                const self = this;
                if (_.isNil(id) || id === 0) return;
                let apiPromise = self.$api.EscrowAccountingApi.getLookups(id, type);
                self.$rqBusy.wait(apiPromise)
                    .then(result => {
                        self.errorMessage = "";
                        self.transferSlips = _.map(result.transferSlips, i => new SystemLookupItem(i));
                        self.depositSlips = _.map(result.depositSlips, i => new SystemLookupItem(i));
                        if (self.restrictDepositDateToRecon) {
                            self.reconciliations = _.map(_.filter(result.reconciliations, ["inactive", false]), i => new SystemLookupItem(i));
                        } else {
                            self.reconciliations = _.map(result.reconciliations, i => new SystemLookupItem(i));
                        }
                        if (type === "order") {
                            self.banks = _.map(result.banks, i => new SystemLookupItem(i));
                            self.item.bankCompanyID = _.get(self, "banks[0].id") || null;
                            self.item.lastFinalizedReconDate = _.get(result, "lastFinalizedReconDate[0].name");
                            if (!self.isNew) {
                                if (!_.isEqual(self.item.bankCompanyID, self.original.bankCompanyID)) {
                                    self.errorMessage = `This File# does not use the same Escrow Account as ${self.original.gfNo}`;
                                }
                            }
                            if (_.isNil(self.item.bankCompanyID)) {
                                self.errorMessage = "No Escrow Account associated with this File#";
                            }
                        }
                        if (self.errorMessage.length > 0) self.$emit("disable-ok");
                    })
                    .catch(error => {
                        console.error(error);
                        self.$toast.error({ message: `Error loading Receipt Lookups.` });
                        self.$emit("disable-ok");
                    });
            },

            getDisabledDepositDates(e) {
                //note: returning true disables the date, false enables it.  Can be confusing.
                let currentDate = DateTime.fromJSDate(e.date).startOf("day");
                if (this.restrictDepositDateToRecon) {
                    //if they are allowed to make changes to a check, but not a finalized recon, then restrict the days to the dates within the range of said recon
                    let reconciliationStartDate = DateTime.fromISO(this.item.reconciliationStartDate).startOf("day");
                    let reconciliationEndDate = DateTime.fromISO(this.item.reconciliationEndDate).startOf("day");
                    return reconciliationStartDate.diff(currentDate, "days").days > 0 || reconciliationEndDate.diff(currentDate, "days").days < 0;
                }
                let now = DateTime.now().startOf("day");
                let lastFinalizedReconDate = DateTime.fromISO(this.item.lastFinalizedReconDate).plus({ days: 1}).startOf("day");
                let reconDaysDiff = lastFinalizedReconDate.diff(now, "days").days;
                let allowFinalizedReconModifications = _.parseBool(this.localSecurity.AllowFinalizedReconModifications, false);
                let depositPreDateDaysSetting = allowFinalizedReconModifications ? this.localSecurity.DepositPreDateDays : Math.abs(reconDaysDiff);
                let depositPostDateDaysSetting = this.localSecurity.DepositPostDateDays;

                //-1 === unlimited
                if(depositPreDateDaysSetting === -1 && depositPostDateDaysSetting === -1) return false;

                let daysDiff = currentDate.diff(now, "days").days;

                //if lastFinalizedReconDate is a future date and they are not allowed to modify finalized recons, use lastFinalizedRecon instead of currentDate
                if(reconDaysDiff > 0 && !this.localSecurity.AllowFinalizedReconModifications){
                    depositPreDateDaysSetting = 0 - reconDaysDiff;
                    daysDiff = currentDate.diff(lastFinalizedReconDate, "days").days;
                }

                if(this.isNew && daysDiff === 0) return false; //today is always valid, for new

                //predate is unlimited but not postdate
                if(depositPreDateDaysSetting === -1){
                    if(daysDiff <= 0) return false;
                    return daysDiff > depositPostDateDaysSetting;
                }
                //postdate is unlimited but not predate
                else if(depositPostDateDaysSetting === -1){
                    if(daysDiff >= 0) return false;
                    return Math.abs(daysDiff) > depositPreDateDaysSetting;
                }
                //neither is unlimited
                return (daysDiff < 0 && Math.abs(daysDiff) > depositPreDateDaysSetting) || (daysDiff > 0 && daysDiff > depositPostDateDaysSetting);
            },

            isDepositDateInRange(val){
                const self = this;
                if (_.isNil(val)) return false;
                return self.getDisabledDepositDates({date: val});
            },

            onReconciliationChanged(e) {
                const self = this;
                //if they are allowed to alter finalized recons, and happen to (re)assign, then warn
                if (self.localSecurity.AllowFinalizedReconModifications && !_.isEqual(e.value, self.original.reconciliationID) && !_.isNil(self.original.depositDate) && DateTime.fromISO(self.original.depositDate).diff(DateTime.fromISO(self.item.lastFinalizedReconDate), "days").days <= 0) {
                    self.errorMessage = FINALIZED_RECON_WARNING;
                }
                if (_.parseNumber(self.original.depositSlipID, 0) > 0) {
                    if (_.isNil(e.value) && !_.isNil(e.previousValue)) {
                        self.errorMessage = "Receipt is on a Deposit Slip cleared on a reconciliation. This action will remove the Deposit Slip and associated Receipts from the reconciliation and could cause the reconciliation to be out of balance.";
                    }
                }
            },

            onDepositDateChanged(e) {
                const self = this;
                let dateWarning = "";
                self.errorMessage = "";
                self.v$.item.depositDate.$touch();
                if(!_.isNil(self.original.depositDate) && _.isNil(e.value)) {
                    self.item.typeFundID = 0;
                    self.item.depositVerified = null;
                }
                //if they set to back to the original, just bail
                if (_.isEqual(e.value, self.original.depositDate)) return;
                //if they are allowed to alter finalized recons, and happen to back date an item that falls inside the thru date of a recon, then warn
                if (self.localSecurity.AllowFinalizedReconModifications && !_.isNil(e.value) && DateTime.fromISO(e.value).diff(DateTime.fromISO(self.item.lastFinalizedReconDate), "days").days <= 0) {
                    self.errorMessage = FINALIZED_RECON_WARNING;
                    dateWarning = `<br/>${FINALIZED_RECON_WARNING}`;
                }
                //if they are allowed to alter finalized recons, and happen to post date an item that falls inside the thru date of a recon, then warn
                if (self.localSecurity.AllowFinalizedReconModifications && !_.isNil(self.original.depositDate) && DateTime.fromISO(self.original.depositDate).diff(DateTime.fromISO(self.item.lastFinalizedReconDate), "days").days <= 0 && DateTime.fromISO(e.value).diff(DateTime.fromISO(self.item.lastFinalizedReconDate), "days").days > 0) {
                    self.errorMessage = FINALIZED_RECON_WARNING;
                    dateWarning = `<br/>${FINALIZED_RECON_WARNING}`;
                }
                if(!self.isNew && self.isWire && _.isNil(self.original.depositDate) && !_.isNil(e.value)) {
                    self.errorMessage = "";
                    let onOk = function (args) {
                        self.item.depositDate = e.value;
                    }
                    let onCancel = function (args) {
                        self.item.depositDate = null;
                    }

                    self.$dialog.confirm("Confirm Accept", `Are you sure you want to accept this Wire?${dateWarning}`, onOk, onCancel, { cancelTitle: 'No', okTitle: 'Yes'});
                }
            },

            onUseBankAmount(){
                this.item.amount = this.bankAmount;
            },

            validateOnCancel() {
                const self = this;
                self.v$.$touch();
                if (self.v$.$error) throw new Error('Please provide all required fields.');
                return true;
            },

            save(){
                const self = this;
                self.errorMessage = "";
                self.validateFileNumber();
                self.v$.$touch();
                if(!self.isWire) {self.item.wireNumber = ""}
                if (self.v$.$error) { return Promise.reject({errorMessage: 'Please provide all required fields.'}); }
                let changes = self.getAuditChanges(self.original, self.item, ["gfNo"]);

                let depositDateChanged = _.find(changes, c => c.name == "depositDate");
                if(depositDateChanged && (self.hadReconciliationID || !self.hasOrdersID || self.originalDisableDates)) return Promise.reject({errorMessage: 'Invalid Operation.'});

                let typeFundIDChanged = _.find(changes, c => c.name == "typeFundID");
                if(typeFundIDChanged && (self.readOnly || self.hadReconciliationID || !self.hasOrdersID || self.isFundTypeExcluded)) return Promise.reject({errorMessage: 'Invalid Operation.'});

                let validationlist = ["payor", "amount", "description", "depositVerified"];
                let validationFieldChanged = _.find(changes, c => _.find(validationlist, v => v == c.name));
                if(validationFieldChanged && (self.readOnly || !self.hasOrdersID)) return Promise.reject({errorMessage: 'Invalid Operation.'});

                let amountChanged = _.find(changes, c => c.name == "amount");
                if(validationFieldChanged && !amountChanged && self.hadReconciliationID) return Promise.reject({errorMessage: 'Invalid Operation.'});

                let reconciliationIDChanged = _.find(changes, c => c.name == "reconciliationID");
                if(reconciliationIDChanged && (self.readOnly || self.originalDisableDates || !self.hasOrdersID || self.isNew)) return Promise.reject({errorMessage: 'Invalid Operation.'});

                let wireNumberChanged = _.find(changes, c => c.name == "wireNumber");
                if(wireNumberChanged && (self.readOnly || self.hadReconciliationID || !self.hasOrdersID || !self.isWire)) return Promise.reject({errorMessage: 'Invalid Operation.'});

                let depositSlipIDChanged = _.find(changes, c => c.name == "depositSlipID");
                if(depositSlipIDChanged && (self.readOnly || self.hadReconciliationID || !self.hasOrdersID || self.hasdepositSlipAccess || self.isNew)) return Promise.reject({errorMessage: 'Invalid Operation.'});

                if (changes.length == 0) {
                    self.$toast.info({ message: `No Changes Detected` });
                    return Promise.resolve(true);
                }
                let isEarnestMoney = _.isEqual(self.item.description, 'Earnest Money');
                let hasTypeFundChanged = _.some(changes, ["name", "typeFundID"]);
                if (isEarnestMoney && hasTypeFundChanged) self.refreshOrder = true;
                let apiPromise = self.$api.EscrowAccountingApi.saveDeposit(self.item, changes);
                return self.$rqBusy.wait(apiPromise);
            },

            validateFileNumber(e) {
                const self = this;
                if (self.isNew && !_.isEmpty(self.$refs.fileNumber)) {
                    self.$refs.fileNumber.updateValidation();
                }
            },
        }
    }
</script>
